import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import LanguageIcon from '@mui/icons-material/Language';
import IconButton from '@mui/material/IconButton';
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import CallIcon from '@mui/icons-material/Call';

import LanguageButton from '../theme/LanguageButton';
import LanguageSelectionDialog from '../components/LanguageSelection';
import Header from '../components/Header';
import ButtonPhone from '../components/ButtonPhone';

import './HealthWellness.css';

function HealthWellness() {
    const [openDialog, setOpenDialog] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');

    const handlePhoneNumber = (digit) => {
        setPhoneNumber(`${phoneNumber}${digit}`);
    };

    const handleDeleteLastDigit = () => {
        setPhoneNumber(phoneNumber.slice(0, -1));
    };

    return (
        <div className="App">
            <Header />
            <main className="App-main">
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            width: 'calc(80vw - 6em)',
                            height: 'auto',
                        },
                    }}
                >
                    <Paper elevation={3} sx={{ padding: '2em 3em' }}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <LanguageIcon sx={{ fontSize: 80, color: '#E652C3' }} />
                        </Box>
                        <h1 className="App-title">
                            Free Nationwide
                            <br />
                            and International Calling
                        </h1>
                        <Box mt={5}>
                            <p style={{ fontSize: 14 }}>Phone Number</p>
                            <LanguageButton
                                variant="outlined"
                                startIcon={
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1024px-Flag_of_the_United_States.svg.png"
                                        width={20}
                                        alt="bandera"
                                        onClick={() => setOpenDialog(true)}
                                    />
                                }
                                endIcon={<BackspaceOutlinedIcon onClick={handleDeleteLastDigit} />}
                            >
                                +1 {phoneNumber}
                            </LanguageButton>
                        </Box>
                        <Box mt={4}>
                            <Box
                                mt={1}
                                display="flex"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <ButtonPhone onClick={() => handlePhoneNumber(1)}>1</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(2)}>2</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(3)}>3</ButtonPhone>
                            </Box>
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <ButtonPhone onClick={() => handlePhoneNumber(4)}>4</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(5)}>5</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(6)}>6</ButtonPhone>
                            </Box>
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <ButtonPhone onClick={() => handlePhoneNumber(7)}>7</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(8)}>8</ButtonPhone>
                                <ButtonPhone onClick={() => handlePhoneNumber(9)}>9</ButtonPhone>
                            </Box>
                            <Box
                                mt={2}
                                display="flex"
                                justifyContent="space-evenly"
                                alignItems="center"
                            >
                                <IconButton aria-label="delete" onClick={handleDeleteLastDigit}>
                                    <BackspaceOutlinedIcon />
                                </IconButton>
                                <ButtonPhone onClick={() => handlePhoneNumber(0)}>0</ButtonPhone>
                                <ButtonPhone sx={{ backgroundColor: '#2EB777', color: 'white' }}>
                                    <CallIcon />
                                </ButtonPhone>
                            </Box>
                        </Box>
                        <Box mt={4}>
                            <Button
                                variant="outlined"
                                sx={{ width: '100%' }}
                                component={RouterLink}
                                to="/"
                            >
                                Back to Main Menu
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </main>
            <LanguageSelectionDialog open={openDialog} handleOpen={setOpenDialog} />
        </div>
    );
}

export default HealthWellness;
