import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const LanguageButton = styled(Button)(({ iconbgcolor }) => ({
    width: '100%',
    height: 42,
    justifyContent: 'flex-start',
    padding: 0,
    backgroundColor: '#E7E7E7',
    color: '#505050',
    textTransform: 'none',
    borderRadius: '5px',
    border: 'none',
    textAlign: 'left',
    fontSize: 16,
    '&:hover': {
        backgroundColor: '#ccc',
        border: 'none',
    },
    '& .MuiButton-startIcon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: iconbgcolor,
        marginLeft: 0,
        height: '100%',
        minWidth: 50,
        color: 'white',
        borderBottomLeftRadius: '4px',
        borderTopLeftRadius: '4px',
        '&:hover': {
            border: 'none',
        },
    },
    '& .MuiButton-endIcon': {
        position: 'absolute',
        right: 8,
        fontSize: 40,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 36,
    },
}));

export default LanguageButton;
