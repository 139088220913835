const initialStateLanguages = {
    data: [],
    defaultLanguage: 'english-united-states',
    languageIntl: 'english',
};

function languageReducer(state, action) {
    switch (action.type) {
        case 'SET_LANGUAGE':
            return {
                ...state,
                defaultLanguage: action.payload.lng,
                languageIntl: action.payload.intl,
            };
        case 'UNSET_LANGUAGE':
            return {
                ...state,
                defaultLanguage: action.payload.lng,
                languageIntl: action.payload.intl,
            };
        default:
            return state;
    }
}

// Main reducer
const mainReducer = ({ languages }, action) => ({
    languages: languageReducer(languages, action),
    // anothers reducers
});

// Global initial state with multiple state reducers
// This allow start the state at the App.js
export const mainState = {
    languages: initialStateLanguages,
    // anothers initialState
};

export default mainReducer;
