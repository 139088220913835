import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#2FC1E6',
        },
        secondary: {
            main: '#372B65',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    textTransform: 'none',
                    ...(ownerState.variant === 'contained' &&
                        ownerState.color === 'primary' && {
                            color: '#fff',
                        }),
                    ...(ownerState.variant === 'outlined' && {
                        color: '#505050',
                        borderColor: theme.palette.secondary.main,
                    }),
                }),
            },
        },
    },
});

export default theme;
