import React, { createContext, useContext, useReducer, useEffect, useState } from 'react';
import reducer from './reducer';

export const StateContext = createContext();
export const StateProvider = ({ children, languagesData }) => {
    return (
        <StateContext.Provider
            value={useReducer(reducer, {
                languages: {
                    data: languagesData,
                    defaultLanguage: 'english-united-states',
                    languageIntl: 'english',
                },
            })}
        >
            {children}
        </StateContext.Provider>
    );
};
export const useStateValue = () => useContext(StateContext);
