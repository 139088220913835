import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import LanguageButton from '../theme/LanguageButton';
import LanguageSelectionDialog from '../components/LanguageSelection';
import Header from '../components/Header';

import './HealthWellness.css';

function HealthWellness() {
    const [openDialog, setOpenDialog] = useState(false);
    return (
        <div className="App">
            <Header />
            <main className="App-main">
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            width: 'calc(80vw - 6em)',
                            height: 'auto',
                        },
                    }}
                >
                    <Paper elevation={3} sx={{ padding: '2em 3em' }}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <MedicalServicesIcon sx={{ fontSize: 80, color: '#F67C1E' }} />
                        </Box>
                        <h1 className="App-title">
                            Health and
                            <br />
                            Wellness Check
                        </h1>
                        <Box mt={5}>
                            <p style={{ fontSize: 14 }}>Select your prefered language</p>
                            <LanguageButton
                                variant="outlined"
                                startIcon={
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1024px-Flag_of_the_United_States.svg.png"
                                        width={20}
                                        alt="bandera"
                                    />
                                }
                                endIcon={<ArrowDropDownIcon />}
                                onClick={() => setOpenDialog(true)}
                            >
                                EN - English - EEUU
                            </LanguageButton>
                        </Box>
                        <Box mt={4}>
                            <Button variant="contained" sx={{ width: '100%' }}>
                                Continue
                            </Button>
                        </Box>
                        <Box mt={2}>
                            <Button
                                variant="outlined"
                                sx={{ width: '100%' }}
                                component={RouterLink}
                                to="/"
                            >
                                Back to Main Menu
                            </Button>
                        </Box>
                    </Paper>
                </Box>
            </main>
            <LanguageSelectionDialog open={openDialog} handleOpen={setOpenDialog} />
        </div>
    );
}

export default HealthWellness;
