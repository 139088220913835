import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const KioskButton = styled(Button)(({ iconbgcolor }) => ({
    width: '100%',
    height: '90px',
    justifyContent: 'flex-start',
    padding: 0,
    backgroundColor: 'white',
    color: '#666',
    textTransform: 'none',
    borderRadius: '5px',
    border: '1px solid #707070',
    textAlign: 'left',
    fontSize: 'xx-large',
    '&:hover, &:disabled': {
        backgroundColor: '#ccc',
        border: 'none',
    },
    '&:disabled': {
        backgroundColor: '#ccc',
        color: '#888',
    },
    '& .MuiButton-startIcon': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: iconbgcolor,
        marginLeft: 0,
        height: '100%',
        minWidth: 80,
        marginRight: 20,
        color: 'white',
        borderBottomLeftRadius: '4px',
        borderTopLeftRadius: '4px',
        '&:hover': {
            border: 'none',
        },
        '&:img': {
            width: '100%',
            objectFit: 'contain',
        },
    },
    '& .MuiSvgIcon-root': {
        fontSize: 36,
    },
}));

export default KioskButton;
