const messages = {
    english: {
        selectLanguage: 'Please select your language',
        backMainMenu: 'Back to Main Menu',
        helpSubtitle: 'How can we help you today?',
        healthWellness: 'Health and Wellness Check',
        denSurvey: 'DEN Customer Service Survey',
        emergencyAccess: 'Emergency Access Services',
        multilingualCalling: 'Multilingual Calling',
        freeCalling: 'Free Calling',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Multilingual Chat Room',
        denMarketplace: 'DEN Airport Marketplace',
        qrCodeCreator: 'Global Marketplace',
        ttyTtd: 'TTY/TDD is for video relay services for the deaf and hearing impaired',
        airlineAgents: 'Airline Agents',
        personalCall: 'Personal Call',
        phoneNumber: 'Phone Number',
        selectAirline: 'Select your airline agent',
        airlineCalling: 'Airline Calling',
        connect: 'Connect',
        selectOption: 'Select an option',
    },
    spanish: {
        selectLanguage: 'Por favor seleccione su idioma',
        backMainMenu: 'Regresar al menú principal',
        helpSubtitle: '¿Cómo podemos ayudarle hoy?',
        healthWellness: 'Control de salud y bienestar',
        denSurvey: 'Encuesta de servicio al cliente de DEN',
        emergencyAccess: 'Servicios de acceso de emergencia',
        multilingualCalling: 'Llamadas multilingües',
        freeCalling: 'Llamadas gratis',
        denLiveVideo: 'Agente de vídeo en vivo de DEN',
        multilingualChatRoom: 'Sala de chat multilingüe',
        denMarketplace: 'Mercado del Aeropuerto DEN',
        qrCodeCreator: 'Mercado GLobal',
        ttyTtd: 'TTY/TDD es para servicios de retransmisión de video para personas sordas y con problemas de audición.',
        airlineAgents: 'Agentes de aerolíneas',
        personalCall: 'llamada personal',
        phoneNumber: 'Número de teléfono',
        selectAirline: 'Seleccione su agente aéreo',
        airlineCalling: 'Llamadas de aerolíneas',
        connect: 'Conectar',
    },
    arabic: {
        selectLanguage: 'الرجاء اختيار لغتك',
        backMainMenu: 'العودة إلى القائمة الرئيسية',
        helpSubtitle: 'كيف يمكننا مساعدتك اليوم؟',
        healthWellness: 'فحص الصحة والعافية',
        denSurvey: 'استبيان خدمة العملاء DEN',
        emergencyAccess: 'خدمات الوصول في حالات الطوارئ',
        multilingualCalling: 'مكالمات متعددة اللغات',
        freeCalling: 'اتصال مجاني',
        denLiveVideo: 'وكيل الفيديو المباشر DEN',
        multilingualChatRoom: 'غرفة دردشة متعددة اللغات',
        denMarketplace: 'سوق مطار DEN',
        qrCodeCreator: 'السوق العالمية',
        ttyTtd: 'TTY/TDD مخصص لخدمات ترحيل الفيديو للصم وضعاف السمع',
        airlineAgents: 'وكلاء شركات الطيران',
        personalCall: 'مكالمة شخصية',
        phoneNumber: 'رقم التليفون',
        selectAirline: 'اختر وكيل الطيران الخاص بك',
        airlineCalling: 'مكالمات طيران',
        connect: 'يتصل',
    },
    'swahili-kenya': {
        selectLanguage: 'Tafadhali chagua lugha yako',
        backMainMenu: 'Rudi kwenye Menyu Kuu',
        helpSubtitle: 'Tunaweza kukusaidia vipi leo?',
        healthWellness: 'Uchunguzi wa Afya na Ustawi',
        denSurvey: 'Utafiti wa Huduma kwa Wateja wa DEN',
        emergencyAccess: 'Huduma za Ufikiaji wa Dharura',
        multilingualCalling: 'Wito kwa lugha nyingi',
        freeCalling: 'Kupiga Simu Bila Malipo',
        denLiveVideo: 'Wakala wa Video wa DEN Live',
        multilingualChatRoom: 'Chumba cha Gumzo kwa Lugha nyingi',
        denMarketplace: 'Soko la Uwanja wa Ndege wa DEN',
        qrCodeCreator: 'Soko la Kimataifa',
        ttyTtd: 'TTY/TDD ni kwa ajili ya huduma za relay video kwa viziwi na walemavu wa kusikia',
        airlineAgents: 'Mawakala wa mashirika ya ndege',
        personalCall: 'Simu ya Kibinafsi',
        phoneNumber: 'Nambari ya Simu',
        selectAirline: 'Chagua wakala wako wa ndege',
        airlineCalling: 'Kupiga simu kwa ndege',
        connect: 'Unganisha',
    },
    no: {
        selectLanguage: 'Vennligst velg språket ditt',
        backMainMenu: 'Tilbake til hovedmenyen',
        helpSubtitle: 'Hvordan kan vi hjelpe deg i dag?',
        healthWellness: 'Helse- og velværesjekk',
        denSurvey: 'DEN kundeserviceundersøkelse',
        emergencyAccess: 'Nødtilgangstjenester',
        multilingualCalling: 'Flerspråklig samtale',
        freeCalling: 'Gratis samtale',
        denLiveVideo: 'DEN Norske Live Video Agent',
        multilingualChatRoom: 'Flerspråklig chatterom',
        denMarketplace: 'Flyplassens Markedsplass',
        qrCodeCreator: 'Global markedsplass',
        ttyTtd: 'TTY/TDD er for videorelétjenester for døve og hørselshemmede',
        airlineAgents: 'Flyselskapets agenter',
        personalCall: 'Personlig samtale',
        phoneNumber: 'Telefonnummer',
        selectAirline: 'Velg flyselskapets agent',
        airlineCalling: 'Flyselskap ringer',
        connect: 'Koble',
    },
    maltese: {
        selectLanguage: 'Jekk jogħġbok agħżel il-lingwa tiegħek',
        backMainMenu: 'Lura għall-Menu Prinċipali',
        helpSubtitle: 'Kif nistgħu ngħinuk illum?',
        healthWellness: 'Kontroll tas-Saħħa u l-Benessri',
        denSurvey: 'Stħarriġ dwar is-Servizz tal-Klijent tad-DEN',
        emergencyAccess: "Servizzi ta' Aċċess ta' Emerġenza",
        multilingualCalling: 'Sejħa multilingwi',
        freeCalling: "Sejħa b'xejn",
        denLiveVideo: 'Aġent tal-Vidjo DEN',
        multilingualChatRoom: 'Chat Room Multilingwi',
        denMarketplace: 'Suq tal-Ajruport DEN',
        qrCodeCreator: 'Suq Globali',
        ttyTtd: "TTY/TDD huwa għal servizzi ta' video relay għal persuni neqsin mis-smigħ u neqsin mis-smigħ",
        airlineAgents: 'Aġenti tal-linji tal-ajru',
        personalCall: 'Sejħa Personali',
        phoneNumber: 'Numru tat-telefon',
        selectAirline: 'Agħżel l-aġent tal-linja tal-ajru tiegħek',
        airlineCalling: 'Sejħa tal-Ajru',
        connect: 'Qabbad',
    },
    cebuano: {
        selectLanguage: 'Palihug pilia ang imong pinulongan',
        backMainMenu: 'Balik sa Panguna nga Menu',
        helpSubtitle: 'Unsaon namo pagtabang kanimo karon?',
        healthWellness: 'Pagsusi sa Panglawas ug Kaayohan',
        denSurvey: 'Surbi sa Serbisyo sa Kustomer sa DEN',
        emergencyAccess: 'Mga Serbisyo para sa Emerhensya nga Pag-access',
        multilingualCalling: 'Multilingual nga pagtawag',
        freeCalling: 'Libre nga Pagtawag',
        denLiveVideo: 'Ang Live Video Ahente',
        multilingualChatRoom: 'Multilingual Nga Chat Lawak',
        denMarketplace: 'Paliparan sa Tugpahanan sa DEN',
        qrCodeCreator: 'Global Merkado',
        ttyTtd: 'Ang TTY/TDD para sa mga serbisyo sa video relay para sa mga bungol ug may diperensya sa pandungog',
        airlineAgents: 'Mga ahente sa eroplano',
        personalCall: 'Personal nga Tawag',
        phoneNumber: 'Numero sa Telepono',
        selectAirline: 'Pilia ang imong ahente sa ayroplano',
        airlineCalling: 'Pagtawag sa eroplano',
        connect: 'sumpaysumpaya',
    },
    fijian: {
        selectLanguage: 'Yalovinaka digitaka na nomu vosa',
        backMainMenu: 'Lesu ki na Menu Levu',
        helpSubtitle: 'E rawa vakacava me keitou vukei iko nikua?',
        healthWellness: 'Jeke ni Bulabula kei na Bulabula',
        denSurvey: 'Vakadidike ni Veiqaravi ni Kasitama ni DEN',
        emergencyAccess: 'Veiqaravi ni Curu ki na Leqa Tubukoso',
        multilingualCalling: 'Veikacivi Vakayabaki',
        freeCalling: 'Veikacivi Galala',
        denLiveVideo: 'DEN Itini ni Vidio Bula',
        multilingualChatRoom: 'Rumu ni Veitalanoa vakayabaki',
        denMarketplace: 'DEN ni rara ni waqavuka Marketplace',
        qrCodeCreator: 'Makete e Vuravura Raraba',
        ttyTtd: 'TTY/TDD e baleta na veiqaravi ni qiqi ena vidio me baleti ira na didivara kei na didivara',
        airlineAgents: 'Mata ni waqavuka',
        personalCall: 'Veikacivi Yadua',
        phoneNumber: 'Naba ni talevoni',
        selectAirline: 'Digitaka na nomu mata ni waqavuka',
        airlineCalling: 'Veikacivi ni Kabani ni Waqavuka',
        connect: 'Sema',
    },
    lu: {
        selectLanguage: 'Wielt w.e.g. Är Sprooch',
        backMainMenu: 'Zréck op den Haaptmenü',
        helpSubtitle: 'Wéi kënne mir Iech haut hëllefen?',
        healthWellness: 'Gesondheets- a Wellnesscheck',
        denSurvey: 'De Client Ass Survey',
        emergencyAccess: 'Noutzougangsservicer',
        multilingualCalling: 'Méisproocheg Uruff',
        freeCalling: 'Gratis Uruff',
        denLiveVideo: 'In De Comments',
        multilingualChatRoom: 'Méisproocheg Chatraum',
        denMarketplace: 'DEN Airport Maartplaz',
        qrCodeCreator: 'Globale Maartplaz',
        ttyTtd: 'TTY/TDD ass fir Videorelaisservicer fir Daaf an Hörbehënnerten',
        airlineAgents: 'Agente Vu Fluchgesellschaften',
        personalCall: 'Perséinlech Uruff',
        phoneNumber: 'Telefonsnummer',
        selectAirline: 'Wielt Äre Airline Agent',
        airlineCalling: 'Fluchgesellschaft urufft',
        connect: 'Verbinden',
    },
    galician: {
        selectLanguage: 'Selecciona o teu idioma',
        backMainMenu: 'Volver ao menú principal',
        helpSubtitle: 'Como podemos axudarche hoxe?',
        healthWellness: 'Verificación de Saúde e Benestar',
        denSurvey: 'Enquisa de atención ao cliente DEN',
        emergencyAccess: 'Servizos de acceso de emerxencia',
        multilingualCalling: 'Chamada multilingüe',
        freeCalling: 'Chamada gratuíta',
        denLiveVideo: 'O Axente De Vídeo En Directo',
        multilingualChatRoom: 'Sala de chat multilingüe',
        denMarketplace: 'Mercado do aeroporto de DEN',
        qrCodeCreator: 'Mercado global',
        ttyTtd: 'TTY/TDD é para servizos de retransmisión de vídeo para xordos e con discapacidade auditiva',
        airlineAgents: 'Axentes de compañías aéreas',
        personalCall: 'Chamada persoal',
        phoneNumber: 'Número de teléfono',
        selectAirline: 'Seleccione o axente da súa compañía aérea',
        airlineCalling: 'Chamadas aéreas',
        connect: 'Conectar',
    },
    'basque-spain': {
        selectLanguage: 'Mesedez, hautatu zure hizkuntza',
        backMainMenu: 'Itzuli menu nagusira',
        helpSubtitle: 'Nola lagundu dezakegu gaur?',
        healthWellness: 'Osasun eta Ongizate Kontrola',
        denSurvey: 'DEN Bezeroarentzako Zerbitzuaren Inkesta',
        emergencyAccess: 'Larrialdietarako Sarbide Zerbitzuak',
        multilingualCalling: 'Dei eleanitza',
        freeCalling: 'Doako deia',
        denLiveVideo: 'DEN Zuzeneko Bideo Agentea',
        multilingualChatRoom: 'Elkarrizketa gela eleanitza',
        denMarketplace: 'DEN aireportuko merkatua',
        qrCodeCreator: 'Merkatu globala',
        ttyTtd: 'TTY/TDD gorrentzako eta entzumen-urritasuna dutenentzako bideo-erreprodukzio zerbitzuetarako da',
        airlineAgents: 'Aire konpainietako agenteak',
        personalCall: 'Dei pertsonala',
        phoneNumber: 'Telefono zenbakia',
        selectAirline: 'Hautatu zure aire konpainiako agentea',
        airlineCalling: 'Aire-konpainiaren deiak',
        connect: 'Konektatu',
    },
    rw: {
        selectLanguage: 'Nyamuneka hitamo ururimi rwawe',
        backMainMenu: 'Subira kuri Main Main',
        helpSubtitle: 'Nigute dushobora kugufasha uyu munsi?',
        healthWellness: "Kugenzura Ubuzima n'Ubwiza",
        denSurvey: 'Ubushakashatsi bwa DEN Serivisi',
        emergencyAccess: 'Serivisi zihutirwa',
        multilingualCalling: 'Guhamagara indimi nyinshi',
        freeCalling: 'Guhamagara kubuntu',
        denLiveVideo: 'DEN Live Umukozi wa VIdeo',
        multilingualChatRoom: 'Icyumba cyo kuganiriramo indimi nyinshi',
        denMarketplace: "Isoko ry'ikibuga cy'indege DEN",
        qrCodeCreator: 'Isoko ryisi yose',
        ttyTtd: 'TTY / TDD ni serivisi zo gutanga amashusho ku batumva no kutumva',
        airlineAgents: "Abakozi b'indege",
        personalCall: "Ihamagarwa ry'umuntu",
        phoneNumber: 'Numero ya Terefone',
        selectAirline: "Hitamo umukozi wawe w'indege",
        airlineCalling: "Guhamagara kw'indege",
        connect: 'Guhuza',
    },
    irish: {
        selectLanguage: 'Roghnaigh do theanga le do thoil',
        backMainMenu: 'Ar ais go dtí an Príomh-Roghchlár',
        helpSubtitle: 'Conas is féidir linn cabhrú leat inniu?',
        healthWellness: 'Seiceáil Sláinte agus Folláine',
        denSurvey: 'DEN Suirbhé ar Sheirbhís do Chustaiméirí',
        emergencyAccess: 'Seirbhísí Rochtana Éigeandála',
        multilingualCalling: 'Glaonna ilteangacha',
        freeCalling: 'Glaoch saor in aisce,',
        denLiveVideo: 'DEN Gníomhaire Físeáin Beo',
        multilingualChatRoom: 'Seomra Comhrá Ilteangach',
        denMarketplace: 'Margadh Aerfoirt DEN',
        qrCodeCreator: 'Margadh Domhanda',
        ttyTtd: 'Tá TTY/TDD le haghaidh seirbhísí sealaíochta físe do dhaoine bodhra agus lagéisteachta',
        airlineAgents: 'Gníomhairí aerlíne',
        personalCall: 'Glao Pearsanta',
        phoneNumber: 'Uimhir teileafón',
        selectAirline: 'Roghnaigh do ghníomhaire aerlíne',
        airlineCalling: 'Glaoch Aerlíne',
        connect: 'Ceangal',
    },
    bangla: {
        selectLanguage: 'অনুগ্রহ করে আপনার ভাষা নির্বাচন করুন',
        backMainMenu: 'প্রধান মেনুতে ফিরে যান',
        helpSubtitle: 'আজ আমরা কিভাবে আপনাকে সাহায্য করতে পারি?',
        healthWellness: 'স্বাস্থ্য এবং সুস্থতা পরীক্ষা',
        denSurvey: 'DEN কাস্টমার সার্ভিস সার্ভে',
        emergencyAccess: 'জরুরি অ্যাক্সেস পরিষেবা',
        multilingualCalling: 'বহুভাষিক কলিং',
        freeCalling: 'ফ্রি কলিং',
        denLiveVideo: 'ডেন লাইভ ভিডিও এজেন্ট',
        multilingualChatRoom: 'বহুভাষিক চ্যাট রুম',
        denMarketplace: 'ডেন এয়ারপোর্ট মার্কেটপ্লেস',
        qrCodeCreator: 'গ্লোবাল মার্কেটপ্লেস',
        ttyTtd: 'TTY/TDD হল বধির এবং শ্রবণ প্রতিবন্ধীদের জন্য ভিডিও রিলে পরিষেবার জন্য',
        airlineAgents: 'এয়ারলাইন এজেন্ট',
        personalCall: 'ব্যক্তিগত কল',
        phoneNumber: 'ফোন নম্বর',
        selectAirline: 'আপনার এয়ারলাইন এজেন্ট নির্বাচন করুন',
        airlineCalling: 'এয়ারলাইন কলিং',
        connect: 'সংযোগ',
    },
    zl: {
        selectLanguage: 'Sicela ukhethe ulimi lwakho',
        backMainMenu: 'Buyela ku-Main Menu',
        helpSubtitle: 'Singakusiza kanjani namuhla?',
        healthWellness: 'Ukuhlola Impilo Nokuphila',
        denSurvey: 'DEN Customer Service Survey',
        emergencyAccess: 'Izinkonzo Zokufinyelela Eziphuthumayo',
        multilingualCalling: 'Ukushaya ngezilimi eziningi',
        freeCalling: 'Ukushaya Kwamahhala',
        denLiveVideo: 'I-Agent Yevidiyo Ebukhoma',
        multilingualChatRoom: 'Igumbi Lengxoxo Yezilimi Eziningi',
        denMarketplace: 'Imakethe Yesikhumulo Sezindiza',
        qrCodeCreator: 'Imakethe Yomhlaba Wonke',
        ttyTtd: 'I-TTY/TDD eyesevisi yokudlulisa ividiyo kwabangezwa nabangezwa',
        airlineAgents: 'Abenzeli bezindiza',
        personalCall: 'Ucingo lomuntu siqu',
        phoneNumber: 'Inombolo Yocingo',
        selectAirline: 'Khetha i-ejenti yakho yendiza',
        airlineCalling: 'Ukushaya Ngendiza',
        connect: 'Xhuma',
    },
    punjabi: {
        selectLanguage: 'ਕਿਰਪਾ ਕਰਕੇ ਆਪਣੀ ਭਾਸ਼ਾ ਚੁਣੋ',
        backMainMenu: 'ਮੁੱਖ ਮੇਨੂ ਤੇ ਵਾਪਸ',
        helpSubtitle: 'ਅੱਜ ਅਸੀਂ ਤੁਹਾਡੀ ਕਿਵੇਂ ਮਦਦ ਕਰ ਸਕਦੇ ਹਾਂ?',
        healthWellness: 'ਸਿਹਤ ਅਤੇ ਤੰਦਰੁਸਤੀ ਜਾਂਚ',
        denSurvey: 'DEN ਗਾਹਕ ਸੇਵਾ ਸਰਵੇਖਣ',
        emergencyAccess: 'ਐਮਰਜੈਂਸੀ ਪਹੁੰਚ ਸੇਵਾਵਾਂ',
        multilingualCalling: 'ਬਹੁ-ਭਾਸ਼ਾਈ ਕਾਲਿੰਗ',
        freeCalling: 'ਮੁਫ਼ਤ ਕਾਲਿੰਗ',
        denLiveVideo: 'DEN ਲਾਈਵ ਵੀਡੀਓ ਏਜੰਟ',
        multilingualChatRoom: 'ਬਹੁ-ਭਾਸ਼ਾਈ ਚੈਟ ਰੂਮ',
        denMarketplace: 'DEN ਏਅਰਪੋਰਟ ਬਾਜ਼ਾਰ',
        qrCodeCreator: 'ਗਲੋਬਲ ਮਾਰਕੀਟਪਲੇਸ',
        ttyTtd: 'TTY/TDD ਬੋਲ਼ੇ ਅਤੇ ਘੱਟ ਸੁਣਨ ਵਾਲੇ ਲੋਕਾਂ ਲਈ ਵੀਡੀਓ ਰੀਲੇਅ ਸੇਵਾਵਾਂ ਲਈ ਹੈ',
        airlineAgents: 'ਏਅਰਲਾਈਨ ਏਜੰਟ',
        personalCall: 'ਨਿੱਜੀ ਕਾਲ',
        phoneNumber: 'ਫੋਨ ਨੰਬਰ',
        selectAirline: 'ਆਪਣਾ ਏਅਰਲਾਈਨ ਏਜੰਟ ਚੁਣੋ',
        airlineCalling: 'ਏਅਰਲਾਈਨ ਕਾਲਿੰਗ',
        connect: 'ਕਨੈਕਟ ਕਰੋ',
    },
    'swahili-tanzania': {
        selectLanguage: 'Tafadhali chagua lugha yako',
        backMainMenu: 'Rudi kwenye Menyu Kuu',
        helpSubtitle: 'Tunaweza kukusaidia vipi leo?',
        healthWellness: 'Uchunguzi wa Afya na Ustawi',
        denSurvey: 'Utafiti wa Huduma kwa Wateja wa DEN',
        emergencyAccess: 'Huduma za Ufikiaji wa Dharura',
        multilingualCalling: 'Wito kwa lugha nyingi',
        freeCalling: 'Kupiga Simu Bila Malipo',
        denLiveVideo: 'Wakala wa Video wa DEN Live',
        multilingualChatRoom: 'Chumba cha Gumzo kwa Lugha nyingi',
        denMarketplace: 'Soko la Uwanja wa Ndege wa DEN',
        qrCodeCreator: 'Soko la Ulimwenguni',
        ttyTtd: 'TTY/TDD ni kwa ajili ya huduma za relay video kwa viziwi na walemavu wa kusikia',
        airlineAgents: 'Mawakala wa mashirika ya ndege',
        personalCall: 'Simu ya Kibinafsi',
        phoneNumber: 'Nambari ya Simu',
        selectAirline: 'Chagua wakala wako wa ndege',
        airlineCalling: 'Kupiga simu kwa ndege',
        connect: 'Unganisha',
    },
    is: {
        selectLanguage: 'Vinsamlegast veldu tungumálið þitt',
        backMainMenu: 'Til baka í aðalvalmynd',
        helpSubtitle: 'Hvernig getum við hjálpað þér í dag?',
        healthWellness: 'Heilsu- og vellíðunarathugun',
        denSurvey: 'Þjónustukönnun DEN',
        emergencyAccess: 'Neyðaraðgangsþjónusta',
        multilingualCalling: 'Fjöltyngt símtal',
        freeCalling: 'Ókeypis símtöl',
        denLiveVideo: 'DEN Lifandi Umboðsmaður',
        multilingualChatRoom: 'Fjöltyngt spjallrás',
        denMarketplace: 'Flugvöllurinn',
        qrCodeCreator: 'Alþjóðlegur markaðstorg',
        ttyTtd: 'TTY/TDD er fyrir myndsendingarþjónustu fyrir heyrnarlausa og heyrnarskerta',
        airlineAgents: 'Umboðsmenn flugfélaga',
        personalCall: 'Persónulegt símtal',
        phoneNumber: 'Símanúmer',
        selectAirline: 'Veldu umboðsmann flugfélagsins',
        airlineCalling: 'Flugfélag að hringja',
        connect: 'Tengdu',
    },
    sinhala: {
        selectLanguage: 'කරුණාකර ඔබේ භාෂාව තෝරන්න',
        backMainMenu: 'ආපසු ප්‍රධාන මෙනුවට',
        helpSubtitle: 'අපි අද ඔබට උදව් කරන්නේ කෙසේද?',
        healthWellness: 'සෞඛ්‍ය සහ සුවතා පරීක්‍ෂාව',
        denSurvey: 'DEN පාරිභෝගික සේවා සමීක්ෂණය',
        emergencyAccess: 'හදිසි ප්‍රවේශ සේවා',
        multilingualCalling: 'බහුභාෂා ඇමතුම්',
        freeCalling: 'නොමිලේ ඇමතුම්',
        denLiveVideo: 'DEN සජීවී වීඩියෝ නියෝජිතයා',
        multilingualChatRoom: 'බහුභාෂා කතාබස් කාමරය',
        denMarketplace: 'DEN ගුවන්තොටුපල වෙළඳපොළ',
        qrCodeCreator: 'ගෝලීය වෙළඳපොළ',
        ttyTtd: 'TTY/TDD යනු බිහිරි සහ ශ්‍රවණාබාධිතයින් සඳහා වීඩියෝ රිලේ සේවා සඳහා වේ',
        airlineAgents: 'ගුවන් සේවා නියෝජිතයන්',
        personalCall: 'පුද්ගලික ඇමතුම',
        phoneNumber: 'දුරකථන අංකය',
        selectAirline: 'ඔබේ ගුවන් සේවා නියෝජිතයා තෝරන්න',
        airlineCalling: 'ගුවන් ඇමතුම්',
        connect: 'සම්බන්ධ කරන්න',
    },
    az: {
        selectLanguage: 'Lütfən, dilinizi seçin',
        backMainMenu: 'Əsas menyuya qayıt',
        helpSubtitle: 'Bu gün sizə necə kömək edə bilərik?',
        healthWellness: 'Sağlamlıq və rifah yoxlanışı',
        denSurvey: 'DEN Müştəri Xidmətləri Sorğusu',
        emergencyAccess: 'Fövqəladə Giriş Xidmətləri',
        multilingualCalling: 'Çoxdilli zəng',
        freeCalling: 'Pulsuz Zəng',
        denLiveVideo: 'Canlı video agenti',
        multilingualChatRoom: 'Çoxdilli Söhbət Otağı',
        denMarketplace: 'Hava limanında Marketplace',
        qrCodeCreator: 'Qlobal Bazar',
        ttyTtd: 'TTY/TDD kar və eşitmə qüsurlu insanlar üçün video relay xidmətləri üçündür',
        airlineAgents: 'Aviaşirkət agentləri',
        personalCall: 'Şəxsi Zəng',
        phoneNumber: 'Telefon nömrəsi',
        selectAirline: 'Aviagentinizi seçin',
        airlineCalling: 'Aviaşirkətdən zəng',
        connect: 'qoşulmaq',
    },
    mrj: {
        selectLanguage: 'Пожалуйста, тӓ айырендӓ йӹлмӹ',
        backMainMenu: 'тӹнг меню пӧртӹлеш',
        helpSubtitle: 'тагачы мӓ тӓ палшен кердӹдӓ?',
        healthWellness: 'здороваэш проверка состоянижӹ',
        denSurvey: 'Ядыштмаш клиентвлӓ DEN',
        emergencyAccess: 'корны Служба экстренный',
        multilingualCalling: 'ужаш многоязычный',
        freeCalling: 'Йӓрӓ звонок',
        denLiveVideo: 'DEN Видеоагент Live',
        multilingualChatRoom: 'чат Многоязычный',
        denMarketplace: 'den площадкышты торгейӹмӓш аэропорт',
        qrCodeCreator: 'площадкышты торговӹй Глобальный',
        ttyTtd: 'TTY/служат дӓ пӹц ӹштӓш сӧрен ретрансляция видео слабослышащий TDD',
        airlineAgents: 'Агентвлӓжӹ авиакомпаний',
        personalCall: 'Личный звонок',
        PhoneNumber: 'телефон номержӹ',
        selectAirline: 'тӓ айырендӓ агентвлӓжӹ авиакомпаний',
        airlineCalling: 'авиакомпаний Ӱжӹктӓш',
        connect: 'Подключаться',
    },
    bosnian: {
        selectLanguage: 'Molimo odaberite svoj jezik',
        backMainMenu: 'Nazad na glavni meni',
        helpSubtitle: 'Kako vam možemo pomoći danas?',
        healthWellness: 'Provjera zdravlja i dobrobiti',
        denSurvey: 'DEN anketa o korisničkoj službi',
        emergencyAccess: 'Usluge pristupa u hitnim slučajevima',
        multilingualCalling: 'Višejezično pozivanje',
        freeCalling: 'Besplatno pozivanje',
        denLiveVideo: 'Agent Uživo',
        multilingualChatRoom: 'Višejezična soba za ćaskanje',
        denMarketplace: 'pijaca aerodroma DEN',
        qrCodeCreator: 'Globalno tržište',
        ttyTtd: 'TTY/TDD je za video relejne usluge za gluhe i nagluhe',
        airlineAgents: 'Avionski agenti',
        personalCall: 'Lični poziv',
        phoneNumber: 'Broj telefona',
        selectAirline: 'Odaberite svog agenta aviokompanije',
        airlineCalling: 'Pozivanje avionom',
        connect: 'Poveži se',
    },
    'albanian-albania': {
        selectLanguage: 'Ju lutemi zgjidhni gjuhën tuaj',
        backMainMenu: 'Kthehu te menyja kryesore',
        helpSubtitle: "Si mund t'ju ndihmojmë sot?",
        healthWellness: 'Kontrolli i shëndetit dhe mirëqenies',
        denSurvey: 'Anketa e Shërbimit të Kli   entit DEN',
        emergencyAccess: 'Shërbimet e qasjes së urgjencës',
        multilingualCalling: 'Thirrje shumëgjuhëshe',
        freeCalling: 'Thirrje falas',
        denLiveVideo: 'Agjent I Gjallë',
        multilingualChatRoom: 'Dhomë Bisede Shumëgjuhëshe',
        denMarketplace: 'Tregu I Aeroportit DEN',
        qrCodeCreator: 'Tregu Global',
        ttyTtd: 'TTY/TDD është për shërbimet e transmetimit të videove për personat e shurdhër dhe me dëgjim të dëmtuar',
        airlineAgents: 'Agjentët e linjës ajrore',
        personalCall: 'Telefonatë personale',
        phoneNumber: 'Numri i telefonit',
        selectAirline: 'Zgjidh agjentin tënd të linjës ajrore',
        airlineCalling: 'Thirrje ajrore',
        connect: 'Lidhu',
    },
    tamil: {
        selectLanguage: 'உங்கள் மொழியைத் தேர்ந்தெடுக்கவும்',
        backMainMenu: 'முதன்மை மெனுவிற்குத் திரும்பு',
        helpSubtitle: 'இன்று நாங்கள் உங்களுக்கு எப்படி உதவ முடியும்?',
        healthWellness: 'உடல்நலம் மற்றும் ஆரோக்கிய சோதனை',
        denSurvey: 'DEN வாடிக்கையாளர் சேவை கணக்கெடுப்பு',
        emergencyAccess: 'அவசர அணுகல் சேவைகள்',
        multilingualCalling: 'பன்மொழி அழைப்பு',
        freeCalling: 'இலவச அழைப்பு',
        denLiveVideo: 'DEN நேரடி வீடியோ முகவர்',
        multilingualChatRoom: 'பன்மொழி அரட்டை அறை',
        denMarketplace: 'DEN விமான நிலைய சந்தை',
        qrCodeCreator: 'உலகளாவிய சந்தை',
        ttyTtd: 'TTY/TDD என்பது காது கேளாதோர் மற்றும் செவித்திறன் குறைபாடுடையவர்களுக்கான வீடியோ ரிலே சேவைகளுக்கானது',
        airlineAgents: 'விமான முகவர்கள்',
        personalCall: 'தனிப்பட்ட அழைப்பு',
        phoneNumber: 'தொலைபேசி எண்',
        selectAirline: 'உங்கள் விமான முகவரைத் தேர்ந்தெடு',
        airlineCalling: 'விமான அழைப்பு',
        connect: 'இணை',
    },
    th: {
        selectLanguage: 'โปรดเลือกภาษาของคุณ',
        backMainMenu: 'กลับสู่เมนูหลัก',
        helpSubtitle: 'วันนี้เราจะช่วยคุณได้อย่างไร',
        healthWellness: 'การตรวจสุขภาพและความเป็นอยู่ที่ดี',
        denSurvey: 'แบบสำรวจบริการลูกค้า DEN',
        emergencyAccess: 'บริการการเข้าถึงเหตุฉุกเฉิน',
        multilingualCalling: 'การโทรหลายภาษา',
        freeCalling: 'โทรฟรี',
        denLiveVideo: 'ตัวแทนวิดีโอสด DEN',
        multilingualChatRoom: 'ห้องสนทนาหลายภาษา',
        denMarketplace: 'ตลาดสนามบิน DEN',
        qrCodeCreator: 'ตลาดระดับโลก',
        ttyTtd: 'TTY/TDD ใช้สำหรับบริการถ่ายทอดวิดีโอสำหรับคนหูหนวกและผู้บกพร่องทางการได้ยิน',
        airlineAgents: 'ตัวแทนสายการบิน',
        personalCall: 'การโทรส่วนตัว',
        phoneNumber: 'หมายเลขโทรศัพท์',
        selectAirline: 'เลือกตัวแทนสายการบินของคุณ',
        airlineCalling: 'การโทรของสายการบิน',
        connect: 'เชื่อมต่อ',
    },
    mn: {
        selectLanguage: 'Хэлээ сонгоно уу',
        backMainMenu: 'Үндсэн цэс рүү буцах',
        helpSubtitle: 'Бид өнөөдөр танд яаж туслах вэ?',
        healthWellness: 'Эрүүл мэнд, эрүүл мэндийг шалгах',
        denSurvey: 'DEN хэрэглэгчийн үйлчилгээний судалгаа',
        emergencyAccess: 'Яаралтай тусламжийн үйлчилгээ',
        multilingualCalling: 'Олон хэлээр дуудлага хийх',
        freeCalling: 'Үнэгүй дуудлага',
        denLiveVideo: 'Амьд Агент',
        multilingualChatRoom: 'Олон хэлтэй чатын өрөө',
        denMarketplace: 'DEN нисэх онгоцны буудлын зах зээл',
        qrCodeCreator: 'Дэлхийн зах зээл',
        ttyTtd: 'TTY/TDD нь дүлий болон сонсголын бэрхшээлтэй хүмүүст зориулсан видео дамжуулах үйлчилгээнд зориулагдсан',
        airlineAgents: 'Агаарын агентууд',
        personalCall: 'Хувийн дуудлага',
        phoneNumber: 'Утасны дугаар',
        selectAirline: 'Агаарын компанийн төлөөлөгчөө сонгоно уу',
        airlineCalling: 'Агаарын дуудлага',
        connect: 'Холбох',
    },
    uk: {
        selectLanguage: 'Будь ласка, виберіть свою мову',
        backMainMenu: 'Назад до головного меню',
        helpSubtitle: 'Чим ми можемо допомогти вам сьогодні?',
        healthWellness: "Перевірка здоров'я та добробуту",
        denSurvey: 'Опитування служби обслуговування клієнтів DEN',
        emergencyAccess: 'Служби екстреного доступу',
        multilingualCalling: 'Багатомовний виклик',
        freeCalling: 'Безкоштовний дзвінок',
        denLiveVideo: 'Агент DEN Live Video',
        multilingualChatRoom: 'Кімната багатомовного чату',
        denMarketplace: 'Ринок аеропорту DEN',
        qrCodeCreator: 'Глобальний ринок',
        ttyTtd: 'TTY/TDD призначено для послуг відеотрансляції для глухих і людей із вадами слуху',
        airlineAgents: 'Агенти авіакомпанії',
        personalCall: 'Особистий дзвінок',
        phoneNumber: 'Номер телефону',
        selectAirline: 'Виберіть свого агента авіакомпанії',
        airlineCalling: 'Дзвінок авіакомпанії',
        connect: 'Підключити',
    },
    eo: {
        selectLanguage: 'Bonvolu elekti vian lingvon',
        backMainMenu: 'Reen al Ĉefa Menuo',
        helpSubtitle: 'Kiel ni povas helpi vin hodiaŭ?',
        healthWellness: 'Kontrolo pri Sano kaj Bonstato',
        denSurvey: 'Enketo pri Klienta Servo de DEN',
        emergencyAccess: 'Servoj de Urĝa Aliro',
        multilingualCalling: 'Multlingva voko',
        freeCalling: 'Senpaga Voko',
        denLiveVideo: 'DEN Viva Videagento',
        multilingualChatRoom: 'Multlingva Babilejo',
        denMarketplace: 'Flughaveno Den',
        qrCodeCreator: 'Tutmonda Foirejo',
        ttyTtd: 'TTY/TDD estas por videoretransferservoj por surduloj kaj aŭdaj difektitaj',
        airlineAgents: 'Aviadkompaniaj agentoj',
        personalCall: 'Persona Voko',
        phoneNumber: 'Telefonnumero',
        selectAirline: 'Elektu vian flugkompanian agenton',
        airlineCalling: 'Aviadkompanio voko',
        connect: 'Konekti',
    },
    amharic: {
        selectLanguage: 'እባክዎ ቋንቋዎን ይምረጡ',
        backMainMenu: 'ወደ ዋና ምናሌ ተመለስ',
        helpSubtitle: 'ዛሬ እንዴት ልንረዳዎ እንችላለን?',
        healthWellness: 'የጤና እና የጤንነት ማረጋገጫ',
        denSurvey: 'DEN የደንበኞች አገልግሎት ዳሰሳ',
        emergencyAccess: 'የአደጋ ጊዜ መዳረሻ አገልግሎቶች',
        multilingualCalling: 'የብዙ ቋንቋ ጥሪ',
        freeCalling: 'ነጻ ጥሪ',
        denLiveVideo: 'DEN የቀጥታ ቪዲዮ ወኪል',
        multilingualChatRoom: 'የብዙ ቋንቋ ውይይት ክፍል',
        denMarketplace: 'DEN የአየር ማረፊያ የገበያ ቦታ',
        qrCodeCreator: 'ዓለም አቀፍ የገበያ ቦታ',
        ttyTtd: 'TTY/TDD መስማት ለተሳናቸው እና መስማት ለተሳናቸው የቪዲዮ ማስተላለፊያ አገልግሎት ነው',
        airlineAgents: 'የአየር መንገድ ወኪሎች',
        personalCall: 'የግል ጥሪ',
        phoneNumber: 'ስልክ ቁጥር',
        selectAirline: 'የአየር መንገድ ወኪልዎን ይምረጡ',
        airlineCalling: 'የአየር መንገድ ጥሪ',
        connect: 'አገናኝ',
    },
    javanese: {
        selectLanguage: 'Mangga pilih basa panjenengan',
        backMainMenu: 'Mbalik menyang Menu Utama',
        helpSubtitle: 'Kadospundi kita saged nulungi panjenengan dinten punika?',
        healthWellness: 'Pemeriksaan Kesehatan lan Kesejahteraan',
        denSurvey: 'Survei Layanan Pelanggan DEN',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Panggilan multibasa',
        freeCalling: 'Nelpon Gratis',
        denLiveVideo: 'Den Urip Agen Video',
        multilingualChatRoom: 'Ruang Obrolan Multibahasa',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD kanggo layanan relay video kanggo wong budheg lan gangguan pangrungu',
        airlineAgents: 'Agen maskapai',
        personalCall: 'Panggilan Pribadi',
        phoneNumber: 'Nomer Telpon',
        selectAirline: 'Pilih agen maskapai sampeyan',
        airlineCalling: 'Panggilan Maskapai',
        connect: 'Sambungake',
    },
    persian: {
        selectLanguage: 'لطفا زبان خود را انتخاب کنید',
        backMainMenu: 'بازگشت به منوی اصلی',
        helpSubtitle: 'چگونه می توانیم امروز به شما کمک کنیم؟',
        healthWellness: 'بررسی سلامت و تندرستی',
        denSurvey: 'بررسی خدمات مشتری DEN',
        emergencyAccess: 'خدمات دسترسی اضطراری',
        multilingualCalling: 'تماس چند زبانه',
        freeCalling: 'تماس رایگان',
        denLiveVideo: 'عامل ویدیو زنده',
        multilingualChatRoom: 'چت روم چند زبانه',
        denMarketplace: 'بازار فرودگاه',
        qrCodeCreator: 'بازار جهانی',
        ttyTtd: 'TTY/TDD برای خدمات رله ویدئویی برای ناشنوایان و ناشنوایان است',
        airlineAgents: 'نمایندگان هواپیمایی',
        personalCall: 'تماس شخصی',
        phoneNumber: 'شماره تلفن',
        selectAirline: 'نماینده هواپیمایی خود را انتخاب کنید',
        airlineCalling: 'تماس با شرکت هواپیمایی',
        connect: 'اتصال',
    },
    mk: {
        selectLanguage: 'Ве молиме изберете го вашиот јазик',
        backMainMenu: 'Назад во главното мени',
        helpSubtitle: 'Како можеме да ви помогнеме денес?',
        healthWellness: 'Проверка на здравјето и благосостојбата',
        denSurvey: 'Анкета за услуги на клиентите DEN',
        emergencyAccess: 'Услуги за итен пристап',
        multilingualCalling: 'Повеќејазично повикување',
        freeCalling: 'Бесплатен повик',
        denLiveVideo: 'Ден Во Живо Видео Агент',
        multilingualChatRoom: 'Повеќејазична соба за разговор',
        denMarketplace: 'ДЕН Аеродром Пазар',
        qrCodeCreator: 'Глобален пазар',
        ttyTtd: 'TTY/TDD е за услуги за видео реле за глуви и лица со оштетен слух',
        airlineAgents: 'Агенти на авиокомпании',
        personalCall: 'Личен повик',
        phoneNumber: 'Телефонски број',
        selectAirline: 'Изберете го вашиот авио агент',
        airlineCalling: 'Авиокомпанија Повик',
        connect: 'Поврзи',
    },
    kyrgyz: {
        selectLanguage: 'Тилиңизди тандаңыз',
        backMainMenu: 'Башкы менюга кайтуу',
        helpSubtitle: 'Биз бүгүн сизге кантип жардам бере алабыз?',
        healthWellness: 'Ден соолук жана сергектик текшерүү',
        denSurvey: 'DEN кардарларды тейлөө сурамжылоо',
        emergencyAccess: 'Тез жардам кызматтары',
        multilingualCalling: 'Көп тилдүү чакыруу',
        freeCalling: 'Акысыз чалуу',
        denLiveVideo: 'Түз эфирдеги видео агент',
        multilingualChatRoom: 'Көп тилдүү чат бөлмөсү',
        denMarketplace: 'Ден аэропортунун базары',
        qrCodeCreator: 'Глобалдык базар',
        ttyTtd: 'TTY/TDD дүлөйлөр жана угуусу начар адамдар үчүн видеорелейлик кызматтар үчүн',
        airlineAgents: 'Авиакомпаниянын агенттери',
        personalCall: 'Жеке чалуу',
        phoneNumber: 'Телефон номери',
        selectAirline: 'Авиакомпанияңыздын агентин тандаңыз',
        airlineCalling: 'Авиакомпанияга чакыруу',
        connect: 'туташуу',
    },
    yiddish: {
        selectLanguage: 'ביטע אויסקלייַבן דיין שפּראַך',
        backMainMenu: 'צוריק צו הויפּט מעניו',
        helpSubtitle: 'ווי קענען מיר העלפֿן איר הייַנט?',
        healthWellness: 'געזונט און וועלנאַס טשעק',
        denSurvey: 'DEN קונה סערוויס יבערבליק',
        emergencyAccess: 'עמערגענסי אַקסעס סערוויסעס',
        multilingualCalling: 'מולטילינגואַל רופן',
        freeCalling: 'פריי רופן',
        denLiveVideo: 'DEN לייוו ווידעא אַגענט',
        multilingualChatRoom: 'מולטילינגוואַל שמועסן צימער',
        denMarketplace: 'DEN ערפּאָרט מאַרקעטפּלאַסע',
        qrCodeCreator: 'גלאבאלע מארקפלעצער',
        ttyTtd: 'TTY/TDD איז פֿאַר ווידעא רעלע סערוויסעס פֿאַר טויב און געהער ימפּערד',
        airlineAgents: 'ערליין אגענטן',
        personalCall: 'פערזענליכע רוף',
        phoneNumber: 'טעלעפֿאָן נומער',
        selectAirline: 'אויסקלײַבן דיין ערליין אַגענט',
        airlineCalling: 'ערליין רופן',
        connect: 'פאַרבינדן',
    },
    pap: {
        selectLanguage: 'Por fabor selecta bo idioma',
        backMainMenu: 'Bolbe na e menu prinsipal',
        helpSubtitle: 'Kon nos por yuda bo awe?',
        healthWellness: 'Check Di Salud y Bienestar',
        denSurvey: 'Den Client Service Survey',
        emergencyAccess: 'Servicio Di Acceso Di Emergencia',
        multilingualCalling: 'Llamada multilingüe',
        freeCalling: 'llamada gratis',
        denLiveVideo: 'Agente Di Video na BIDA den',
        multilingualChatRoom: 'Sala Di Chat Multilingüe',
        denMarketplace: 'Mercado di aeropuerto DEN',
        qrCodeCreator: 'Mercado global',
        ttyTtd: 'TTY / TDD ta pa servisionan di retransmishon di video pa hende surdu i hende ku un desabilidat di oido',
        airlineAgents: 'Agentenan di aerolinea',
        personalCall: 'Yamada personal',
        phoneNumber: 'Number di telefòn',
        selectAirline: 'Seleccioná bo agente di aerolinea',
        airlineCalling: 'Llamada di aerolinea',
        connect: 'Konektá',
    },
    be: {
        selectLanguage: 'Калі ласка, абярыце мову',
        backMainMenu: 'Назад у галоўнае меню',
        helpSubtitle: 'Чым мы можам дапамагчы вам сёння?',
        healthWellness: 'Праверка здароўя і дабрабыту',
        denSurvey: 'Апытанне абслугоўвання кліентаў DEN',
        emergencyAccess: 'Службы экстранага доступу',
        multilingualCalling: 'Шматмоўныя выклікі',
        freeCalling: 'Бясплатны званок',
        denLiveVideo: 'Відэаагент DEN Live',
        multilingualChatRoom: 'Шматмоўны чат',
        denMarketplace: 'Рынак аэрапорта DEN',
        qrCodeCreator: 'Глабальны рынак',
        ttyTtd: 'TTY/TDD прызначаны для паслуг відэатрансляцыі для глухіх і людзей са слабым слыхам',
        airlineAgents: 'Агенты авіякампаніі',
        personalCall: 'Асабісты званок',
        phoneNumber: 'Нумар тэлефона',
        selectAirline: 'Выберыце свайго агента авіякампаніі',
        airlineCalling: 'Выклік авіякампаніі',
        connect: 'Злучыцца',
    },
    'armenian-armenia': {
        selectLanguage: 'Խնդրում ենք ընտրել ձեր լեզուն',
        backMainMenu: 'Վերադառնալ հիմնական ընտրացանկ',
        helpSubtitle: 'Ինչպե՞ս կարող ենք օգնել քեզ այսօր',
        healthWellness: 'Առողջության և առողջության ստուգում',
        denSurvey: 'DEN Հաճախորդների սպասարկման հարցում',
        emergencyAccess: 'Արտակարգ իրավիճակների մուտքի ծառայություններ',
        multilingualCalling: 'Բազմալեզու զանգեր',
        freeCalling: 'Անվճար զանգ',
        denLiveVideo: 'Վիդեո գործակալ դեն Լիվե',
        multilingualChatRoom: 'Բազմալեզու Զրուցասենյակ',
        denMarketplace: 'Օդանավակայանի շուկա',
        qrCodeCreator: 'Գլոբալ շուկա',
        ttyTtd: 'TTY/TDD-ը նախատեսված է խուլերի և լսողության խնդիրներ ունեցողների տեսահաղորդման ծառայությունների համար',
        airlineAgents: 'Ավիաընկերության գործակալներ',
        personalCall: 'Անձնական զանգ',
        phoneNumber: 'Հեռախոսահամար',
        selectAirline: 'Ընտրեք ձեր ավիաընկերության գործակալը',
        airlineCalling: 'Զանգահարեք ավիաընկերությունից',
        connect: 'Միացնել',
    },
    malagasy: {
        selectLanguage: 'Mifidiana ny fiteninao azafady',
        backMainMenu: "Miverena any amin'ny Main Menu",
        helpSubtitle: 'Ahoana no azontsika anampiana anao anio?',
        healthWellness: 'Fandinihana ny fahasalamana sy ny fahasalamana',
        denSurvey: 'Fanadihadiana momba ny serivisy mpanjifa DEN',
        emergencyAccess: "Sampandraharahan'ny fidirana maika",
        multilingualCalling: "Fiantsoana amin'ny fiteny maro",
        freeCalling: 'Antso maimaim-poana',
        denLiveVideo: "Den Mpandraharaha Amin'ny Lahatsary Mivantana",
        multilingualChatRoom: "Efitrano firesahana amin'ny fiteny maro",
        denMarketplace: "Tsenan'ny seranam-piaramanidina DEN",
        qrCodeCreator: 'Tsena Maneran-tany',
        ttyTtd: "TTY/TDD dia natao ho an'ny serivisy fampitana horonan-tsary ho an'ny marenina sy sembana",
        airlineAgents: 'Mpanao zotram-piaramanidina',
        personalCall: 'Antso manokana',
        phoneNumber: 'Laharana finday',
        selectAirline: "Fidio ny mpiasan'ny zotram-piaramanidinao",
        airlineCalling: 'Fiantsoana fiaramanidina',
        connect: 'Mampifandray',
    },
    tajik: {
        selectLanguage: 'Лутфан забони худро интихоб кунед',
        backMainMenu: 'Бозгашт ба Менюи асосӣ',
        helpSubtitle: 'Чӣ гуна мо имрӯз ба шумо кӯмак карда метавонем?',
        healthWellness: 'Тафтиши саломатӣ ва некӯаҳволӣ',
        denSurvey: 'Тадқиқоти хидматрасонии муштариёни DEN',
        emergencyAccess: 'Хадамоти дастрасии изтирорӣ',
        multilingualCalling: 'Занги бисёрзабона',
        freeCalling: 'Занги ройгон',
        denLiveVideo: 'Агенти видеои DEN Live',
        multilingualChatRoom: 'Утоқи сӯҳбати бисёрзабона',
        denMarketplace: 'Бозори Фурудгоҳи DEN',
        qrCodeCreator: 'Бозори ҷаҳонӣ',
        ttyTtd: 'TTY/TDD барои хидматрасонии видеорелей барои кар ва шунавоӣ маҳдуд аст',
        airlineAgents: 'Агентҳои ҳавопаймоӣ',
        personalCall: 'Занги шахсӣ',
        phoneNumber: 'Рақами телефон',
        selectAirline: 'Агенти ширкати ҳавопаймоии худро интихоб кунед',
        airlineCalling: 'Занги ширкати ҳавопаймоӣ',
        connect: 'пайваст',
    },
    frisian: {
        selectLanguage: 'Selektearje asjebleaft jo taal',
        backMainMenu: 'Werom nei haadmenu',
        helpSubtitle: 'Hoe kinne wy jo hjoed helpe?',
        healthWellness: 'Sûnens- en wolwêzenskontrôle',
        denSurvey: 'DEN Customer Service Survey',
        emergencyAccess: 'Tsjinsten foar needtagong',
        multilingualCalling: 'Meartalige oprop',
        freeCalling: 'Fergees skilje',
        denLiveVideo: 'DEN Live persoan ',
        multilingualChatRoom: 'Meartalige petearkeamer',
        denMarketplace: 'DEN Airport Marketplace',
        qrCodeCreator: 'Wrâldmarktplaats',
        ttyTtd: 'TTY/TDD is foar fideorelaistsjinsten foar dôven en gehoar beheinden',
        airlineAgents: 'Airline aginten',
        personalCall: 'Persoanlike oprop',
        phoneNumber: 'Telefoonnûmer',
        selectAirline: 'Selektearje jo loftline-agint',
        airlineCalling: 'Airline ropt',
        connect: 'Ferbine',
    },
    af: {
        selectLanguage: 'Kies asseblief jou taal',
        backMainMenu: 'Terug na hoofkieslys',
        helpSubtitle: 'Hoe kan ons jou vandag help?',
        healthWellness: 'Gesondheid- en welstandkontrole',
        denSurvey: 'DEN Kliëntediensopname',
        emergencyAccess: 'Noodtoegangsdienste',
        multilingualCalling: 'Meertalige oproepe',
        freeCalling: 'Gratis oproepe',
        denLiveVideo: 'Lewende Agent',
        multilingualChatRoom: 'Meertalige kletskamer',
        denMarketplace: 'DEN Lughawe Mark',
        qrCodeCreator: 'Globale mark',
        ttyTtd: 'TTY/TDD is vir video-aflosdienste vir dowes en gehoorgestremdes',
        airlineAgents: 'Lugredery agente',
        personalCall: 'Persoonlike oproep',
        phoneNumber: 'Foonnommer',
        selectAirline: 'Kies jou lugredery agent',
        airlineCalling: 'Lugredery roep',
        connect: 'Verbind',
    },
    fr: {
        selectLanguage: 'Veuillez sélectionner votre langue',
        backMainMenu: 'Retour au menu principal',
        helpSubtitle: "Que peut-on faire pour vous aider aujourd'hui",
        healthWellness: 'Bilan de santé et de bien-être',
        denSurvey: 'Enquête sur le service client de DEN',
        emergencyAccess: "Services d'accès d'urgence",
        multilingualCalling: 'Appel multilingue',
        freeCalling: 'Appel gratuit',
        denLiveVideo: 'Agent vidéo en direct DEN',
        multilingualChatRoom: 'Salon de discussion multilingue',
        denMarketplace: "Marché de l'aéroport DEN",
        qrCodeCreator: 'Marché mondial',
        ttyTtd: 'TTY/TDD est destiné aux services de relais vidéo pour les sourds et les malentendants',
        airlineAgents: 'Agents aériens',
        personalCall: 'Appel personnel',
        phoneNumber: 'Numéro de téléphone',
        selectAirline: 'Sélectionnez votre agent aérien',
        airlineCalling: 'Appel de la compagnie aérienne',
        connect: 'Connecter',
    },
    tahitian: {
        selectLanguage: "A ma'iti i ta outou",
        backMainMenu: "Ho'i faahou i nia i te tabula rahi",
        helpSubtitle: "Nahea e ti'a ai ia matou ia tauturu ia outou i teie mahana ?",
        healthWellness: 'Te oraora - maitai - raa e te vai - maitai - raa',
        denSurvey: 'Te hoê titorotororaa no nia i te taviniraa i Denver',
        emergencyAccess: 'Te mau pû tauturu rû',
        multilingualCalling: 'Piiraa rau',
        freeCalling: 'Piiraa Tamoni ore',
        denLiveVideo: "Te mau hoho'a video ti'a no Denver",
        multilingualChatRoom: 'Piha o te reo e rave rahi',
        denMarketplace: 'Te matete o te tauraa manureva no Denver',
        qrCodeCreator: 'Matete na te ao nei',
        ttyTtd: "Te TTY e te TTD, no te mau hoho'a video ïa no te feia tari'a turi e te feia tari'a turi.",
        airlineAgents: 'Te mau manu reva',
        personalCall: 'Piiraa o te taata iho',
        phoneNumber: 'Numera niuniu',
        selectAirline: "A ma'iti i to outou ti'a faatere no te manureva",
        airlineCalling: 'Te piiraa o te manureva',
        connect: "Tu'ati",
    },
    finnish: {
        selectLanguage: 'Valitse kieli',
        backMainMenu: 'Takaisin päävalikkoon',
        helpSubtitle: 'kuinka voimme auttaa sinua tänään?',
        healthWellness: 'Terveys- ja hyvinvointitarkastus',
        denSurvey: 'DEN-asiakaspalvelukysely',
        emergencyAccess: 'Hätäpalvelut',
        multilingualCalling: 'Monikielinen soitto',
        freeCalling: 'Ilmainen puhelu',
        denLiveVideo: 'DEN Live-videoagentti',
        multilingualChatRoom: 'Monikielinen chat-huone',
        denMarketplace: 'Denverin lentoaseman tori',
        qrCodeCreator: 'Globaali markkinapaikka',
        ttyTtd: 'TTY/TDD on tarkoitettu kuurojen ja kuulovammaisten videovälityspalveluihin',
        airlineAgents: 'Lentoyhtiöiden edustajat',
        personalCall: 'Henkilökohtainen soitto',
        phoneNumber: 'Puhelinnumero',
        selectAirline: 'Valitse lentoyhtiösi edustaja',
        airlineCalling: 'Lentoyhtiön soitto',
        connect: 'Kytkeä',
    },
    corsican: {
        selectLanguage: 'Per piacè selezziunà a to lingua',
        backMainMenu: 'Torna à u menu principale',
        helpSubtitle: 'Cumu pudemu aiutà vi oghje?',
        healthWellness: 'Controllà a salute è u benessere',
        denSurvey: 'Indagine di serviziu à i clienti DEN',
        emergencyAccess: "Servizi d'accessu d'urgenza",
        multilingualCalling: 'Chiamata multilingua',
        freeCalling: 'Chiamata gratisi',
        denLiveVideo: 'Agente Video DEN Live',
        multilingualChatRoom: 'Stanza di chat multilingua',
        denMarketplace: "Mercattu di l'aeroportu DEN",
        qrCodeCreator: 'Mercattu glubale',
        ttyTtd: 'TTY/TDD hè per i servizii di video relay per i sordi è i sordi',
        airlineAgents: 'Agenti aerei',
        personalCall: 'Chiamata persunale',
        phoneNumber: 'Numeru telefuninu',
        selectAirline: 'Selezziunà u to agentu aereo',
        airlineCalling: 'Chiamata aerea',
        connect: 'Connectà',
    },
    ig: {
        selectLanguage: 'Biko họrọ asụsụ gị',
        backMainMenu: 'Laghachi na Isi NchNhr',
        helpSubtitle: 'Olee otu anyị ga-esi nyere gị aka taa?',
        healthWellness: 'Nlele ahụike na ahụike',
        denSurvey: 'Nnyocha ọrụ ndị ahịa DEN',
        emergencyAccess: 'Ọrụ nnweta mberede',
        multilingualCalling: 'Oku ọtụtụ asụsụ',
        freeCalling: 'Oku efu',
        denLiveVideo: 'DNa-ebi Ndụ Na Mmadụ',
        multilingualChatRoom: 'Ụlọ nkata ọtụtụ asụsụ',
        denMarketplace: 'Ọmaahịa ọdụ ụgbọ elu DEN',
        qrCodeCreator: 'Ebe Azụmahịa zuru ụwa ọnụ',
        ttyTtd: 'TTY/TDD bụ maka ọrụ nnyefe vidiyo maka ndị ntị chiri na ndị ntị chiri',
        airlineAgents: 'Ndị ọrụ ụgbọ elu',
        personalCall: 'Oku nkeonwe',
        phoneNumber: 'nọmba ekwentị',
        selectAirline: 'Họrọ onye nnọchi anya ụgbọ elu gị',
        airlineCalling: 'Ọkpọ ụgbọ elu',
        connect: 'Jikọọ',
    },
    latin: {
        selectLanguage: 'Placere linguam tuam eligere',
        backMainMenu: 'si torna A Menu',
        HelpSubtitle: 'Quomodo te hodie adiuvare possumus?',
        healthWellness: 'Salus et Welness Reprehendo',
        denSurvey: 'Den Mos Muneris Survey',
        emergencyAccess: 'Subitis Accessum Officia',
        multilingualCalling: 'Vocatio Multilingual',
        freeCalling: 'Liberum Vocatio',
        denLiveVideo: 'DEN Vive Agens Video',
        multilingualChatRoom: 'Multilingual Chat Locus',
        denMarketplace: 'DEN Aeroportus Marketplace',
        qrCodeCreator: 'Fori Global',
        ttyTtd: 'TTY/TDD est ut operas nullas video surdis et auditus imminutas',
        airlineAgents: 'Agentia porttitor',
        personalCall: 'personalis Call',
        phoneNumber: 'Numerus Telephonicus',
        selectAirline: 'Elige tuum porttitor agente',
        airlineCalling: 'porttitor vocatio',
        connect: 'coniungere',
    },
    swedish: {
        selectLanguage: 'Välj ditt språk',
        backMainMenu: 'Tillbaka till huvudmenyn',
        helpSubtitle: 'Hur kan vi hjälpa dig idag?',
        healthWellness: 'Hälso- och välbefinnandekontroll',
        denSurvey: 'DEN kundtjänstundersökning',
        emergencyAccess: 'Nödåtkomsttjänster',
        multilingualCalling: 'Flerspråkigt samtal',
        freeCalling: 'Gratis samtal',
        denLiveVideo: 'DBo personligen',
        multilingualChatRoom: 'Flerspråkigt chattrum',
        denMarketplace: 'Marknadsplats på flygplatsen',
        qrCodeCreator: 'Global marknadsplats',
        ttyTtd: 'TTY/TDD är för videorelätjänster för döva och hörselskadade',
        airlineAgents: 'Flygbolagsagenter',
        personalCall: 'Personligt samtal',
        phoneNumber: 'Telefonnummer',
        selectAirline: 'Välj ditt flygbolagsagent',
        airlineCalling: 'Flygbolaget ringer',
        connect: 'Ansluta',
    },
    romanian: {
        selectLanguage: 'Vă rugăm să selectați limba dvs.',
        backMainMenu: 'Înapoi la meniul principal',
        helpSubtitle: 'Cum vă putem ajuta astăzi?',
        healthWellness: 'Verificarea sănătății și a bunăstării',
        denSurvey: 'Sondaj DEN privind serviciile clienților',
        emergencyAccess: 'Servicii de acces de urgență',
        multilingualCalling: 'Apel multilingv',
        freeCalling: 'Apel gratuit',
        denLiveVideo: 'Trăiește în persoană',
        multilingualChatRoom: 'Cameră de chat multilingvă',
        denMarketplace: 'DEN Piața aeroportului',
        qrCodeCreator: 'Piața globală',
        ttyTtd: 'TTY/TDD este pentru servicii de releu video pentru surzi și cu deficiențe de auz',
        airlineAgents: 'Agenți ai companiilor aeriene',
        personalCall: 'Apel personal',
        phoneNumber: 'Număr de telefon',
        selectAirline: 'Selectați agentul companiei aeriene',
        airlineCalling: 'Apelul companiei aeriene',
        connect: 'Conectează-te',
    },
    kannada: {
        selectLanguage: 'ದಯವಿಟ್ಟು ನಿಮ್ಮ ಭಾಷೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ',
        backMainMenu: 'ಮುಖ್ಯ ಮೆನುಗೆ ಹಿಂತಿರುಗಿ',
        helpSubtitle: 'ನಾವು ಇಂದು ನಿಮಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡಬಹುದು?',
        healthWellness: 'ಆರೋಗ್ಯ ಮತ್ತು ಕ್ಷೇಮ ತಪಾಸಣೆ',
        denSurvey: 'DEN ಗ್ರಾಹಕ ಸೇವಾ ಸಮೀಕ್ಷೆ',
        emergencyAccess: 'ತುರ್ತು ಪ್ರವೇಶ ಸೇವೆಗಳು',
        multilingualCalling: 'ಬಹುಭಾಷಾ ಕರೆ',
        freeCalling: 'ಉಚಿತ ಕರೆ',
        denLiveVideo: 'DEN ಲೈವ್ ವೀಡಿಯೊ ಏಜೆಂಟ್',
        multilingualChatRoom: 'ಬಹುಭಾಷಾ ಚಾಟ್ ರೂಮ್',
        denMarketplace: 'DEN ವಿಮಾನ ನಿಲ್ದಾಣ ಮಾರುಕಟ್ಟೆ',
        qrCodeCreator: 'ಜಾಗತಿಕ ಮಾರುಕಟ್ಟೆ',
        ttyTtd: 'TTY/TDD ಕಿವುಡ ಮತ್ತು ಶ್ರವಣದೋಷವುಳ್ಳವರಿಗೆ ವೀಡಿಯೊ ರಿಲೇ ಸೇವೆಗಳಿಗಾಗಿ',
        airlineAgents: 'ಏರ್ಲೈನ್ ಏಜೆಂಟ್ಸ್',
        personalCall: 'ವೈಯಕ್ತಿಕ ಕರೆ',
        phoneNumber: 'ಫೋನ್ ಸಂಖ್ಯೆ',
        selectAirline: 'ನಿಮ್ಮ ಏರ್ಲೈನ್ ಏಜೆಂಟ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿ',
        airlineCalling: 'ಏರ್ಲೈನ್ ಕರೆ',
        connect: 'ಸಂಪರ್ಕ',
    },
    it: {
        selectLanguage: 'Seleziona la tua lingua',
        backMainMenu: 'Indietro al menù principale',
        helpSubtitle: 'Come possiamo aiutarvi oggi?',
        healthWellness: 'Controllo della salute e del benessere',
        denSurvey: 'Sondaggio sul servizio clienti DEN',
        emergencyAccess: 'Servizi di accesso di emergenza',
        multilingualCalling: 'Chiamate multilingue',
        freeCalling: 'Chiamate gratuite',
        denLiveVideo: 'DEN Agente video in diretta',
        multilingualChatRoom: 'Chat room multilingue',
        denMarketplace: "Mercato dell'aeroporto DEN",
        qrCodeCreator: 'Mercato globale',
        ttyTtd: 'TTY/TDD è per i servizi di ritrasmissione video per non udenti e ipoudenti',
        airlineAgents: 'Agenti aerei',
        personalCall: 'Chiamata personale',
        phoneNumber: 'Numero di telefono',
        selectAirline: 'Seleziona il tuo agente aereo',
        airlineCalling: 'Chiamate aeree',
        connect: 'Collegare',
    },
    uzbek: {
        selectLanguage: 'Tilingizni tanlang',
        backMainMenu: 'Asosiy menyuga qaytish',
        helpSubtitle: 'Bugun sizga qanday yordam bera olamiz?',
        healthWellness: 'Salomatlik va farovonlikni tekshirish',
        denSurvey: "DEN mijozlarga xizmat ko'rsatish so'rovi",
        emergencyAccess: 'Favqulodda kirish xizmatlari',
        multilingualCalling: "Ko'p tilli qo'ng'iroq",
        freeCalling: "Bepul qo'ng'iroq",
        denLiveVideo: 'DEN Shaxs agentida yashang',
        multilingualChatRoom: "Ko'p tilli suhbat xonasi",
        denMarketplace: 'DEN aeroporti bozori',
        qrCodeCreator: 'Global bozor',
        ttyTtd: 'TTY/TDD kar va eshitish qobiliyati zaiflar uchun video reley xizmatlari uchun',
        airlineAgents: 'Aviakompaniya agentlari',
        personalCall: "Shaxsiy qo'ng'iroq",
        phoneNumber: 'Telefon raqami',
        selectAirline: "O'z aviakompaniya agentingizni tanlang",
        airlineCalling: "Aviakompaniyaga qo'ng'iroq qilish",
        connect: 'Ulanish',
    },
    lao: {
        selectLanguage: 'ກະລຸນາເລືອກພາສາຂອງເຈົ້າ',
        backMainMenu: 'ກັບໄປທີ່ເມນູຫຼັກ',
        helpSubtitle: 'ມື້ນີ້ເຮົາຊ່ວຍເຈົ້າໄດ້ແນວໃດ?',
        healthWellness: 'ກວດສຸຂະພາບແລະສຸຂະພາບ',
        denSurvey: 'ການສຳຫຼວດບໍລິການລູກຄ້າ DEN',
        emergencyAccess: 'ບໍລິການເຂົ້າເຖິງສຸກເສີນ',
        multilingualCalling: 'ການໂທຫຼາຍພາສາ',
        freeCalling: 'ໂທຟຣີ',
        denLiveVideo: 'DEN ຕົວແທນສົດ',
        multilingualChatRoom: 'ຫ້ອງສົນທະນາຫຼາຍພາສາ',
        denMarketplace: 'ຕະຫຼາດສະໜາມບິນ DEN',
        qrCodeCreator: 'ຕະຫຼາດທົ່ວໂລກ',
        ttyTtd: 'TTY/TDD ແມ່ນສຳລັບການບໍລິການຖ່າຍທອດວິດີໂອສຳລັບຄົນຫູໜວກ ແລະພິການຫູ',
        airlineAgents: 'ຕົວແທນສາຍການບິນ',
        personalCall: 'ໂທສ່ວນຕົວ',
        phoneNumber: 'ເບີໂທລະສັບ',
        selectAirline: 'ເລືອກຕົວແທນສາຍການບິນຂອງເຈົ້າ',
        airlineCalling: 'ສາຍການບິນ',
        connect: 'ເຊື່ອມຕໍ່',
    },
    tatar: {
        selectLanguage: 'Зинһар, телегезне сайлагыз',
        backMainMenu: 'Төп менюга кире кайту',
        helpSubtitle: 'Бүген без сезгә ничек ярдәм итә алабыз?',
        healthWellness: 'Сәламәтлек һәм сәламәтлекне тикшерү',
        denSurvey: 'DEN клиентларга хезмәт күрсәтү',
        emergencyAccess: 'Ашыгыч ярдәм хезмәте',
        multilingualCalling: 'Күп телләрдә шалтырату',
        freeCalling: 'Ирекле шалтырату',
        denLiveVideo: 'DEN Тере агент',
        multilingualChatRoom: 'Күп телләрле чат бүлмәсе',
        denMarketplace: 'DEN аэропорты базары',
        qrCodeCreator: 'Глобаль базар',
        ttyTtd: 'TTY / TDD саңгырау һәм ишетү начар кешеләр өчен видео эстафета хезмәтләре өчен',
        airlineAgents: 'Авиакомпания агентлары',
        personalCall: 'Шәхси шалтырату',
        phoneNumber: 'Телефон номеры',
        selectAirline: 'Авиакомпания агентын сайлагыз',
        airlineCalling: 'Авиакомпания шалтыраты',
        connect: 'тоташу',
    },
    marathi: {
        selectLanguage: 'कृपया तुमची भाषा निवडा',
        backMainMenu: 'मुख्य मेनूवर परत',
        helpSubtitle: 'आज आम्ही तुम्हाला कशी मदत करू शकतो?',
        healthWellness: 'आरोग्य आणि कल्याण तपासणी',
        denSurvey: 'DEN ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपत्कालीन प्रवेश सेवा',
        multilingualCalling: 'बहुभाषिक कॉलिंग',
        freeCalling: 'विनामूल्य कॉलिंग',
        denLiveVideo: 'DEN लाइव्ह व्हिडिओ एजंट',
        multilingualChatRoom: 'बहुभाषिक चॅट रूम',
        denMarketplace: 'DEN विमानतळ मार्केटप्लेस',
        qrCodeCreator: 'जागतिक बाजारपेठ',
        ttyTtd: 'टीटीवाय/टीडीडी हे कर्णबधिर आणि श्रवणदोष असलेल्यांसाठी व्हिडिओ रिले सेवांसाठी आहे',
        airlineAgents: 'एअरलाइन एजंट',
        personalCall: 'वैयक्तिक कॉल',
        phoneNumber: 'फोन नंबर',
        selectAirline: 'तुमचा एअरलाइन एजंट निवडा',
        airlineCalling: 'एअरलाइन कॉलिंग',
        connect: 'कनेक्ट करा',
    },
    turkmen: {
        selectLanguage: 'Diliňizi saýlaň',
        backMainMenu: 'Esasy menýu yzyna dolan',
        helpSubtitle: 'Bu gün size nädip kömek edip bileris?',
        healthWellness: 'Saglyk we saglygy barlamak',
        denSurvey: 'DEN Müşderi Hyzmaty',
        emergencyAccess: 'Gyssagly kömek hyzmatlary',
        multilingualCalling: 'Köp dilli jaň',
        freeCalling: 'Mugt jaň',
        denLiveVideo: 'DEN göni wideo agenti',
        multilingualChatRoom: 'Köp dilli söhbetdeşlik otagy',
        denMarketplace: 'DEN howa menzili bazary',
        qrCodeCreator: 'Dünýä bazary',
        ttyTtd: 'TTY / TDD kerler we eşidýänler üçin wideo relay hyzmatlary üçin',
        airlineAgents: 'Howa ýollarynyň wekilleri',
        personalCall: 'Şahsy jaň',
        phoneNumber: 'awiakompaniýanyň wekili',
        selectAirline: 'Awiakompaniýanyň wekilini saýlaň',
        airlineCalling: 'awiakompaniýa jaň edýär',
        connect: 'Birikdir',
    },
    hausa: {
        selectLanguage: 'Da fatan za a zaɓi yaren ku',
        backMainMenu: 'Komawa ga Babban Menu',
        helpSubtitle: 'Ta yaya za mu iya taimaka muku a yau?',
        healthWellness: 'Binciken Lafiya da Lafiya',
        denSurvey: 'DEN Binciken Sabis na Abokin Ciniki',
        emergencyAccess: 'Sabis na Samun Gaggawa',
        multilingualCalling: 'Kira na harsuna da yawa',
        freeCalling: 'Kira Kyauta',
        denLiveVideo: 'Wakilin Bidiyo Live DEN',
        multilingualChatRoom: 'Dakin Tattaunawar Harsuna',
        denMarketplace: 'Kasuwar tashar jirgin sama DEN',
        qrCodeCreator: 'Kasuwancin Duniya',
        ttyTtd: 'TTY/TDD na sabis ne na watsa bidiyo ga kurame da nakasassu',
        airlineAgents: "Ma'aikatan jirgin sama",
        personalCall: 'Kira Na Kashi',
        phoneNumber: 'Lambar waya',
        selectAirline: 'Zaɓi wakilin jirgin ku',
        airlineCalling: 'Kiran Jirgin Sama',
        connect: 'Haɗa',
    },
    japanese: {
        selectLanguage: '言語を選択してください',
        backMainMenu: 'メインメニューに戻る',
        helpSubtitle: '今日はどのようにお手伝いできるでしょうか?',
        healthWellness: '健康診断',
        denSurvey: 'DEN カスタマーサービスアンケート',
        emergencyAccess: '緊急アクセスサービス',
        multilingualCalling: '多言語通話',
        freeCalling: '無料通話',
        denLiveVideo: 'DEN ライブビデオエージェント',
        multilingualChatRoom: '多言語チャットルーム',
        denMarketplace: 'DEN 空港マーケットプレイス',
        qrCodeCreator: 'グローバル マーケットプレイス',
        ttyTtd: 'TTY/TDD は聴覚障害者向けのビデオ中継サービスです。',
        airlineAgents: '航空会社の代理店',
        personalCall: '個人通話',
        phoneNumber: '電話番号',
        selectAirline: '航空会社の代理店を選択してください',
        airlineCalling: '航空会社への電話',
        connect: '接続する',
    },
    hu: {
        selectLanguage: 'Kérjük, válassza ki a nyelvet',
        backMainMenu: 'Vissza a főmenübe',
        helpSubtitle: 'Hogyan tudunk segíteni ma?',
        healthWellness: 'Egészség és Wellness ellenőrzés',
        denSurvey: 'DEN Ügyfélszolgálati Felmérés',
        emergencyAccess: 'Vészhelyzeti hozzáférési szolgáltatások',
        multilingualCalling: 'Többnyelvű hívás',
        freeCalling: 'Ingyenes hívás',
        denLiveVideo: 'DEN Élő videó ügynök',
        multilingualChatRoom: 'Többnyelvű csevegőszoba',
        denMarketplace: 'DEN repülőtéri piactér',
        qrCodeCreator: 'Globális piactér',
        ttyTtd: 'A TTY/TDD a siketek és hallássérültek számára nyújtott videoközvetítési szolgáltatásokra szolgál',
        airlineAgents: 'Légitársaság ügynökei',
        personalCall: 'Személyes hívás',
        phoneNumber: 'Telefonszám',
        selectAirline: 'Válassza ki légitársaság ügynökét',
        airlineCalling: 'Légitársaság hívása',
        connect: 'Csatlakozás',
    },
    polish: {
        selectLanguage: 'Wybierz swój język',
        backMainMenu: 'Powrót do menu głównego',
        helpSubtitle: 'Jak możemy ci dzisiaj pomóc?',
        healthWellness: 'Kontrola zdrowia i dobrego samopoczucia',
        denSurvey: 'Ankieta dotycząca obsługi klienta DEN',
        emergencyAccess: 'Usługi dostępu awaryjnego',
        multilingualCalling: 'Połączenia wielojęzyczne',
        freeCalling: 'Bezpłatne połączenia',
        denLiveVideo: 'DEN Agent wideo na żywo',
        multilingualChatRoom: 'Wielojęzyczny pokój rozmów',
        denMarketplace: 'Giełda lotniska DEN',
        qrCodeCreator: 'Globalny rynek',
        ttyTtd: 'TTY/TDD służy do świadczenia usług transmisji wideo dla osób niesłyszących i niedosłyszących',
        airlineAgents: 'Agenci linii lotniczych',
        personalCall: 'Rozmowa osobista',
        phoneNumber: 'Numer telefonu',
        selectAirline: 'Wybierz agenta linii lotniczych',
        airlineCalling: 'Rozmowa z linią lotniczą',
        connect: 'Łączyć',
    },
    hmong: {
        selectLanguage: 'Thov xaiv koj hom lus',
        backMainMenu: 'Rov qab mus rau Main Menu',
        helpSubtitle: 'Peb tuaj yeem pab koj li cas hnub no?',
        healthWellness: 'Kev Noj Qab Haus Huv thiab Kev Nyab Xeeb',
        denSurvey: 'DEN Kev Tshawb Fawb Cov Neeg Siv Khoom',
        emergencyAccess: 'Kev Pab Thaum Muaj Xwm Ceev',
        multilingualCalling: 'Multilingual hu',
        freeCalling: 'Kev Hu Dawb',
        denLiveVideo: 'DEN Yees duab tus neeg saib xyuas',
        multilingualChatRoom: 'Multilingual Chat Chav tsev',
        denMarketplace: 'DEN tshav dav hlau Marketplace',
        qrCodeCreator: 'Ntiaj teb no marketplace',
        ttyTtd: 'TTY/TDD yog rau kev pab cuam video relay rau cov lag ntseg thiab tsis hnov lus',
        airlineAgents: 'Menyuam noj cov menyuam noj',
        personalCall: 'Tus Kheej Hu',
        phoneNumber: 'Xov tooj',
        selectAirline: 'Xaiv koj lub dav hlau tus neeg sawv cev',
        airlineCalling: 'Airline Hu rau',
        connect: 'Txuas',
    },
    georgian: {
        selectLanguage: 'გთხოვთ აირჩიოთ თქვენი ენა',
        backMainMenu: 'უკან მთავარ მენიუში',
        helpSubtitle: 'როგორ შეგვიძლია დაგეხმაროთ დღეს?',
        healthWellness: 'ჯანმრთელობისა და კეთილდღეობის შემოწმება',
        denSurvey: 'DEN მომხმარებელთა მომსახურების კვლევა',
        emergencyAccess: 'გადაუდებელი დახმარების სერვისები',
        multilingualCalling: 'მრავალენოვანი ზარი',
        freeCalling: 'უფასო ზარი',
        denLiveVideo: 'DEN ცოცხალი ვიდეო აგენტი',
        multilingualChatRoom: 'მრავალენოვანი ჩატის ოთახი',
        denMarketplace: 'DEN აეროპორტის ბაზარი',
        qrCodeCreator: 'გლობალური ბაზარი',
        ttyTtd: 'TTY/TDD არის ყრუ და სმენადაქვეითებული ადამიანებისთვის ვიდეო სარელეო სერვისებისთვის',
        airlineAgents: 'ავიაკომპანიის აგენტები',
        personalCall: 'პერსონალური ზარი',
        phoneNumber: 'ტელეფონის ნომერი',
        selectAirline: 'აირჩიე შენი ავიაკომპანიის აგენტი',
        airlineCalling: 'ავიაკომპანიის დარეკვა',
        connect: 'დაკავშირება',
    },
    malay: {
        selectLanguage: 'Sila pilih bahasa anda',
        backMainMenu: 'Kembali ke Menu Utama',
        helpSubtitle: 'Bagaimana kami boleh membantu anda hari ini?',
        healthWellness: 'Pemeriksaan Kesihatan dan Kesejahteraan',
        denSurvey: 'Tinjauan Perkhidmatan Pelanggan DEN',
        emergencyAccess: 'Perkhidmatan Akses Kecemasan',
        multilingualCalling: 'Panggilan berbilang bahasa',
        freeCalling: 'Panggilan Percuma',
        denLiveVideo: 'Ejen Video Langsung DEN',
        multilingualChatRoom: 'Bilik Sembang Pelbagai bahasa',
        denMarketplace: 'DEN Marketplace Lapangan Terbang',
        qrCodeCreator: 'Pasaran Global',
        ttyTtd: 'TTY/TDD adalah untuk perkhidmatan penyampaian video untuk orang pekak dan bermasalah pendengaran',
        airlineAgents: 'Ejen syarikat penerbangan',
        personalCall: 'Panggilan Peribadi',
        phoneNumber: 'Nombor Telefon',
        selectAirline: 'Pilih ejen syarikat penerbangan anda',
        airlineCalling: 'Panggilan Syarikat Penerbangan',
        connect: 'Connect',
    },

    'haitian-creole': {
        selectLanguage: 'Tanpri chwazi lang ou',
        backMainMenu: 'Tounen nan meni prensipal',
        helpSubtitle: 'Kijan nou ka ede w jodi a?',
        healthWellness: 'Tcheke sante ak byennèt',
        denSurvey: 'Sondaj sèvis Kliyantèl DEN',
        emergencyAccess: 'Sèvis Aksè pou Ijans',
        multilingualCalling: 'Rele plizyè lang',
        freeCalling: 'Rele gratis',
        denLiveVideo: 'Ajan Videyo DEN Live',
        multilingualChatRoom: 'Sal chat plizyè lang',
        denMarketplace: 'DEN Mache Ayewopò',
        qrCodeCreator: 'Mache mondyal',
        ttyTtd: 'TTY/TDD se pou sèvis relè videyo pou moun ki soud ak moun ki pa tande byen',
        airlineAgents: 'Ajan avyon',
        personalCall: 'Apel pèsonèl',
        phoneNumber: 'Nimewo Telefòn',
        selectAirline: 'Chwazi ajan avyon w',
        airlineCalling: 'Airline Rele',
        connect: 'Konekte',
    },
    sw: {
        selectLanguage: 'Tafadhali chagua lugha yako',
        backMainMenu: 'Nyuma kwenye Menyu Kuu',
        helpSubtitle: 'Tunawezaje kukusaidia leo?',
        healthWellness: 'Ukaguzi wa Afya na Ustawi',
        denSurvey: 'Utafiti wa Huduma kwa Wateja',
        emergencyAccess: 'Huduma za Ufikiaji wa Dharura',
        multilingualCalling: 'Wito wa lugha nyingi',
        freeCalling: 'Kupiga simu kwa bure',
        denLiveVideo: 'Wakala wa Video wa Moja kwa Moja',
        multilingualChatRoom: 'Chumba cha Gumzo cha Lugha nyingi',
        denMarketplace: 'Soko la Uwanja wa Ndege wa DEN',
        qrCodeCreator: 'Soko la Ulimwenguni',
        ttyTtd: 'TTY/TDD ni kwa ajili ya huduma za kupeleka video kwa viziwi na viziwi',
        airlineAgents: 'Wakala wa Ndege',
        personalCall: 'Piga simu ya kibinafsi',
        phoneNumber: 'Namba ya simu',
        selectAirline: 'Chagua wakala wako wa ndege',
        airlineCalling: 'Piga simu',
        connect: 'Pamoja',
    },
    greek: {
        selectLanguage: 'Επιλέξτε τη γλώσσα σας',
        backMainMenu: 'Επιστροφή στο Κύριο Μενού',
        helpSubtitle: 'Πώς μπορούμε να σας βοηθήσουμε σήμερα;',
        healthWellness: 'Έλεγχος υγείας και ευεξίας',
        denSurvey: 'Έρευνα Εξυπηρέτησης Πελατών DEN',
        emergencyAccess: 'Υπηρεσίες πρόσβασης έκτακτης ανάγκης',
        multilingualCalling: 'Πολύγλωσση κλήση',
        freeCalling: 'Δωρεάν Κλήση',
        denLiveVideo: 'DEN Πράκτορας ζωντανού βίντεο',
        multilingualChatRoom: 'Πολύγλωσσο Chat Room',
        denMarketplace: 'DEN Αγορά αεροδρομίου',
        qrCodeCreator: 'Παγκόσμια Αγορά',
        ttyTtd: 'Το TTY/TDD είναι για υπηρεσίες αναμετάδοσης βίντεο για κωφούς και άτομα με προβλήματα ακοής',
        airlineAgents: 'Πράκτορες αεροπορικών εταιρειών',
        personalCall: 'Προσωπική κλήση',
        phoneNumber: 'Τηλεφωνικό νούμερο',
        selectAirline: 'Επιλέξτε τον πράκτορα της αεροπορικής σας εταιρείας',
        airlineCalling: 'Κλήση αεροπορικής εταιρείας',
        connect: 'Συνδέω-συωδεομαι',
    },
    khmer: {
        selectLanguage: 'សូមជ្រើសរើសភាសារបស់អ្នក',
        backMainMenu: 'ត្រលប់ទៅម៉ឺនុយមេ',
        helpSubtitle: 'តើយើងអាចជួយអ្នកថ្ងៃនេះដោយរបៀបណា?',
        healthWellness: 'ការពិនិត្យសុខភាព និងសុខភាព',
        denSurvey: 'ការស្ទង់មតិសេវាកម្មអតិថិជនរបស់ DEN',
        emergencyAccess: 'សេវាចូលសង្គ្រោះបន្ទាន់',
        multilingualCalling: 'ការហៅច្រើនភាសា',
        freeCalling: 'ការហៅទូរសព្ទដោយឥតគិតថ្លៃ',
        denLiveVideo: 'ភ្នាក់ងារ វីដេអូ ដេន',
        multilingualChatRoom: 'បន្ទប់ជជែកពហុភាសា',
        denMarketplace: 'ផ្សារព្រលានយន្តហោះ DEN',
        qrCodeCreator: 'ទីផ្សារសកល',
        ttyTtd: 'TTY/TDD គឺសម្រាប់សេវាបញ្ជូនតវីដេអូសម្រាប់អ្នកថ្លង់ និងពិការការស្តាប់',
        airlineAgents: 'ភ្នាក់ងារអាកាសចរណ៍',
        personalCall: 'ការហៅផ្ទាល់ខ្លួន',
        phoneNumber: 'លេខទូរស័ព្ទ',
        selectAirline: 'ជ្រើសរើសភ្នាក់ងារអាកាសចរណ៍របស់អ្នក',
        airlineCalling: 'ការហៅតាមយន្តហោះ',
        connect: 'ភ្ជាប់',
    },
    hindi: {
        selectLanguage: 'कृपया अपनी भाषा चुनें',
        backMainMenu: 'मुख्य मेनू पर वापस जाएँ',
        helpSubtitle: 'आज हम आपकी कैसे मदद कर सकते हैं?',
        healthWellness: 'स्वास्थ्य और कल्याण जाँच',
        denSurvey: 'DEN ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपातकालीन पहुँच सेवाएँ',
        multilingualCalling: 'बहुभाषी कॉलिंग',
        freeCalling: 'फ्री कॉलिंग',
        denLiveVideo: 'डेन लाइव वीडियो एजेंट',
        multilingualChatRoom: 'बहुभाषी चैट रूम',
        denMarketplace: 'डेन एयरपोर्ट मार्केटप्लेस',
        qrCodeCreator: 'वैश्विक बाज़ार',
        ttyTtd: 'TTY/TDD बधिरों और श्रवण बाधितों के लिए वीडियो रिले सेवाओं के लिए है',
        airlineAgents: 'एयरलाइन एजेंट्स',
        personalCall: 'व्यक्तिगत कॉल',
        phoneNumber: 'फ़ोन नंबर',
        selectAirline: 'अपना एयरलाइन एजेंट चुनें',
        airlineCalling: 'एयरलाइन कॉलिंग',
        connect: 'कनेक्ट करें',
    },
    sesotho: {
        selectLanguage: 'Ka kopo, kgetha puo ya hao',
        backMainMenu: 'Khutlela ho Main Menu',
        helpSubtitle: 'Re ka o thusa jwang kajeno?',
        healthWellness: 'Tlhahlobo ea Bophelo bo Botle le Bophelo bo Botle',
        denSurvey: "Tlhahlobo ea Ts'ebeletso ea Bareki ea DEN",
        emergencyAccess: 'Litšebeletso tsa phihlello ea Tšohanyetso',
        multilingualCalling: 'Pitso ka dipuo tse ngata',
        freeCalling: 'Ho Letsa Mahala',
        denLiveVideo: 'DEN Moemeli oa fumaneha ka ',
        multilingualChatRoom: 'Puisano ka Lipuo Tse Ngata ',
        denMarketplace: 'Den Boema-fofane ',
        qrCodeCreator: 'Lefatšeng ka bophara ',
        ttyTtd: 'TTY/TDD ke ea litšebeletso tsa phepelo ea lifitio bakeng sa batho ba sa utloeng litsebeng le ba sa utloeng litsebeng',
        airlineAgents: 'Baemeli ba lifofane',
        personalCall: 'Pitso ea hau',
        phoneNumber: 'Nomoro ea Mohala',
        selectAirline: 'Khetha moemeli oa hau oa sefofane',
        airlineCalling: 'Ho letsa ka sefofane',
        connect: 'Hokela',
    },
    lt: {
        selectLanguage: 'Pasirinkite savo kalbą',
        backMainMenu: 'Atgal į pagrindinį meniu',
        helpSubtitle: 'Kaip mes galime jums padėti šiandien?',
        healthWellness: 'Sveikatos ir gerovės patikrinimas',
        denSurvey: 'DEN klientų aptarnavimo apklausa',
        emergencyAccess: 'Avarinė prieiga ',
        multilingualCalling: 'Kelių kalbų skambinimas',
        freeCalling: 'Nemokamas skambutis',
        denLiveVideo: 'DEN Tiesioginis vaizdo įrašas ',
        multilingualChatRoom: 'Kelių kalbų pokalbių kambarys',
        denMarketplace: 'DEN oro uostas ',
        qrCodeCreator: 'globalus ',
        ttyTtd: 'TTY/TDD skirtas vaizdo perdavimo paslaugoms kurtiesiems ir klausos negalią turintiems žmonėms',
        airlineAgents: 'Oro linijų agentai',
        personalCall: 'Asmeninis skambutis',
        phoneNumber: 'Telefono numeris',
        selectAirline: 'Pasirinkite savo oro linijų agentą',
        airlineCalling: 'Oro linijų skambučiai',
        connect: 'Prisijungti',
    },
    russian: {
        selectLanguage: 'Пожалуйста, выберите ваш язык',
        backMainMenu: 'Вернуться в главное меню',
        helpSubtitle: 'Как мы можем помочь тебе сегодня?',
        healthWellness: 'Проверка здоровья и благополучия',
        denSurvey: 'Опрос службы поддержки клиентов DEN',
        emergencyAccess: 'Службы экстренного доступа',
        multilingualCalling: 'Многоязычные звонки',
        freeCalling: 'Бесплатные звонки',
        denLiveVideo: 'DEN Живое видео ',
        multilingualChatRoom: 'Многоязычный чат',
        denMarketplace: 'Торговая площадка аэропорта DEN',
        qrCodeCreator: 'Глобальная торговая площадка',
        ttyTtd: 'TTY/TDD предназначен для услуг видеоретрансляции для глухих и слабослышащих.',
        airlineAgents: 'Агенты авиакомпаний',
        personalCall: 'Персональный звонок',
        phoneNumber: 'Номер телефона',
        selectAirline: 'Выберите агента авиакомпании',
        airlineCalling: 'Вызов авиакомпании',
        connect: 'Соединять',
    },
    kurdish: {
        selectLanguage: 'Ji kerema xwe zimanê xwe hilbijêre',
        backMainMenu: 'Vegere pêşeka sereke',
        helpSubtitle: 'Em îro çawa dikarin alîkariya te bikin?',
        healthWellness: 'Kontrolkirina Tenduristî û Xweşiyê',
        denSurvey: 'Lêkolîna Xizmeta Mişterî ya DEN',
        emergencyAccess: 'Xizmetên Gihîştina Lezgîn',
        multilingualCalling: 'Banga Pirzimanî',
        freeCalling: 'Banga Bêla',
        denLiveVideo: 'DEN Kesê Vîdyoyê Zindî',
        multilingualChatRoom: 'Odeya Chat Pirzimanî',
        denMarketplace: 'Bazara Balafirgeha DEN',
        qrCodeCreator: 'Bazara Cîhanî',
        ttyTtd: 'TTY/TDD ji bo karûbarên veguheztina vîdyoyê ji bo kerr û astengdarên bihîstinê ye',
        airlineAgents: 'Ajanên balafirgehê',
        personalCall: 'Banga Kesane',
        phoneNumber: 'Hejmara têlefonê',
        selectAirline: 'Agentê balafirgeha xwe hilbijêre',
        airlineCalling: 'Balgiriya Hewayê',
        Connecter: 'Girêdan',
    },
    nl: {
        selectLanguage: 'Selecteer uw taal',
        backMainMenu: 'Terug naar het hoofdmenu',
        helpSubtitle: 'Hoe kunnen we u vandaag helpen?',
        healthWellness: 'Gezondheids- en welzijnscontrole',
        denSurvey: 'DEN Klantenserviceonderzoek',
        emergencyAccess: 'Toegangsdiensten voor noodgevallen',
        multilingualCalling: 'Meertalig bellen',
        freeCalling: 'Gratis bellen',
        denLiveVideo: 'DEN Live videoagent',
        multilingualChatRoom: 'Meertalige chatroom',
        denMarketplace: 'DEN Airport Marktplaats',
        qrCodeCreator: 'Wereldwijde marktplaats',
        ttyTtd: 'TTY/TDD is bedoeld voor videorelaydiensten voor doven en slechthorenden',
        airlineAgents: 'Luchtvaartagenten',
        personalCall: 'Persoonlijke oproep',
        phoneNumber: 'Telefoonnummer',
        selectAirline: 'Selecteer uw luchtvaartmaatschappij',
        airlineCalling: 'Luchtvaartmaatschappij bellen',
        connect: 'Aansluiten',
    },
    catalan: {
        selectLanguage: 'Seleccioneu el vostre idioma',
        backMainMenu: 'Tornar al menú principal',
        helpSubtitle: 'Com et podem ajudar avui?',
        healthWellness: 'Control de salut i benestar',
        denSurvey: "Enquesta d'atenció al client de DEN",
        emergencyAccess: "Serveis d'accés d'emergència",
        multilingualCalling: 'Trucada multilingüe',
        freeCalling: 'Trucades gratuïtes',
        denLiveVideo: 'Agent de vídeo en viu de DEN',
        multilingualChatRoom: 'Sala de xat multilingüe',
        denMarketplace: "Mercat de l'aeroport de DEN",
        qrCodeCreator: 'Mercat global',
        ttyTtd: 'TTY/TDD és per a serveis de retransmissió de vídeo per a sords i amb discapacitat auditiva',
        airlineAgents: 'Agents de línies aèries',
        personalCall: 'Trucada personal',
        phoneNumber: 'Número de telèfon',
        selectAirline: 'Seleccioneu el vostre agent aeri',
        airlineCalling: 'Trucades aèries',
        connect: "Connecta't",
    },
    or: {
        selectLanguage: 'ଦୟାକରି ଆପଣଙ୍କର ଭାଷା ଚୟନ କରନ୍ତୁ',
        backMainMenu: 'ମେନ୍ ମେନୁକୁ ଫେରନ୍ତୁ',
        helpSubtitle: 'ଆଜି ଆମେ ଆପଣଙ୍କୁ କିପରି ସାହାଯ୍ୟ କରିପାରିବା?',
        healthWellness: 'ସ୍ୱାସ୍ଥ୍ୟ ଏବଂ ୱେଲନେସ୍ ଯା Check ୍ଚ',
        denSurvey: 'DEN ଗ୍ରାହକ ସେବା ସର୍ବେକ୍ଷଣ',
        emergencyAccess: 'ଜରୁରୀକାଳୀନ ପ୍ରବେଶ ସେବା',
        multilingualCalling: 'ବହୁଭାଷୀ କଲିଂ',
        freeCalling: 'ମାଗଣା କଲିଂ',
        denLiveVideo: 'DEN ଲାଇଭ ଭିଡିଓ ଏଜେଣ୍ଟ',
        multilingualChatRoom: 'ବହୁଭାଷୀ ଚାଟ୍ ରୁମ୍',
        denMarketplace: 'DEN ଅନ୍ଲାଇନ୍ ମାର୍କେଟପ୍ଲେସ୍',
        qrCodeCreator: 'DEN ଅନ୍ଲାଇନ୍ ମାର୍କେଟପ୍ଲେସ୍',
        ttyTtd: 'TTY / TDD ବଧିର ଏବଂ ଶ୍ରବଣ ଦୁର୍ବଳଙ୍କ ପାଇଁ ଭିଡିଓ ରିଲେ ସେବା ପାଇଁ',
        airlineAgents: 'ଏୟାରଲାଇନ୍ସ ଏଜେଣ୍ଟ',
        personalCall: 'ବ୍ୟକ୍ତିଗତ କଲ୍',
        phoneNumber: 'ଫୋନ୍ ନମ୍ବର',
        selectAirline: 'ଆପଣଙ୍କର ବିମାନ ଏଜେଣ୍ଟ ଚୟନ କରନ୍ତୁ',
        airlineCalling: 'ଏୟାରଲାଇନ୍ କଲିଂ',
        connect: 'ସଂଯୋଗ',
    },
    filipino: {
        selectLanguage: 'Pakipili ang iyong wika',
        backMainMenu: 'Bumalik sa Pangunahing Menu',
        helpSubtitle: 'Paano ka namin matutulungan ngayon?',
        healthWellness: 'Health and Welness Check',
        denSurvey: 'Survey sa Customer Service ng DEN',
        emergencyAccess: 'Mga Serbisyong Pang-emergency na Access',
        multilingualCalling: 'Multilingual na pagtawag',
        freeCalling: 'Libreng Pagtawag',
        denLiveVideo: 'DEN Agent sa video',
        multilingualChatRoom: 'Maramihang mga wika chat',
        denMarketplace: 'DEN pamilihan ng paliparan',
        qrCodeCreator: 'pandaigdigang pamilihan',
        ttyTtd: 'Ang TTY/TDD ay para sa mga serbisyo ng video relay para sa mga bingi at may kapansanan sa pandinig',
        airlineAgents: 'Mga ahente ng airline',
        personalCall: 'Personal na Tawag',
        phoneNumber: 'Numero ng Telepono',
        selectAirline: 'Piliin ang iyong ahente ng airline',
        airlineCalling: 'Pagtawag sa Airline',
        connect: 'kumonekta',
    },
    'amharic-ethiopia': {
        selectLanguage: 'እባክዎ ቋንቋዎን ይምረጡ',
        backMainMenu: 'ወደ ዋና ምናሌ ተመለስ',
        helpSubtitle: 'ዛሬ እንዴት ልንረዳዎ እንችላለን?',
        healthWellness: 'የጤና እና የጤንነት ማረጋገጫ',
        denSurvey: 'DEN የደንበኞች አገልግሎት ዳሰሳ',
        emergencyAccess: 'የአደጋ ጊዜ መዳረሻ አገልግሎቶች',
        multilingualCalling: 'የብዙ ቋንቋ ጥሪ',
        freeCalling: 'ነጻ ጥሪ',
        denLiveVideo: 'DEN የቀጥታ ቪዲዮ ወኪል',
        multilingualChatRoom: 'የብዙ ቋንቋ ውይይት ክፍል',
        denMarketplace: 'DEN የአየር ማረፊያ የገበያ ቦታ',
        qrCodeCreator: 'ዓለም አቀፍ የገበያ ቦታ',
        ttyTtd: 'TTY/TDD መስማት ለተሳናቸው እና መስማት ለተሳናቸው የቪዲዮ ማስተላለፊያ አገልግሎት ነው',
        airlineAgents: 'የአየር መንገድ ወኪሎች',
        personalCall: 'የግል ጥሪ',
        phoneNumber: 'ስልክ ቁጥር',
        selectAirline: 'የአየር መንገድ ወኪልዎን ይምረጡ',
        airlineCalling: 'የአየር መንገድ ጥሪ',
        connect: 'አገናኝ',
    },
    somali: {
        selectLanguage: 'Fadlan door luqadaada',
        backMainMenu: 'Ku laabo Menu Main',
        helpSubtitle: 'Sideen ku caawin karnaa maanta?',
        healthWellness: 'Caafimaadka iyo fayoobaanta',
        denSurvey: 'DEN sahanka adeegga macaamiisha',
        emergencyAccess: 'Adeegyada Helitaanka Degdega ah',
        multilingualCalling: 'Wacitaanka luuqadaha badan',
        freeCalling: 'Wicitaan bilaash ah',
        denLiveVideo: 'Wakiilka Fiidyaha tooska ah ee DEN',
        multilingualChatRoom: 'Qolka Wadahadalka Luuqadaha Badan',
        denMarketplace: 'DEN Suuqyada Garoonka Diyaaradaha',
        qrCodeCreator: 'suuqa global',
        ttyTtd: 'TTY/TDD waxaa loogu talagalay adeegyada gudbinta fiidyaha ee dhagoolayaasha iyo maqalka naafada',
        airlineAgents: 'Wakiilada diyaaradaha',
        personalCall: 'Wacitaanka Shakhsi ahaaneed',
        phoneNumber: 'Telefoon Number',
        selectAirline: 'Dooro wakiilkaaga diyaarad',
        airlineCalling: 'Wicitaan Diyaaradeed',
        connect: 'isku xidhid',
    },
    gujarati: {
        selectLanguage: 'કૃપા કરીને તમારી ભાષા પસંદ કરો',
        backMainMenu: 'મુખ્ય મેનુ પર પાછા',
        helpSubtitle: 'આજે અમે તમને કેવી રીતે મદદ કરી શકીએ?',
        healthWellness: 'સ્વાસ્થ્ય અને સુખાકારી તપાસ',
        denSurvey: 'DEN ગ્રાહક સેવા સર્વેક્ષણ',
        emergencyAccess: 'ઇમરજન્સી એક્સેસ સેવાઓ',
        multilingualCalling: 'બહુભાષી કૉલિંગ',
        freeCalling: 'મફત કૉલિંગ',
        denLiveVideo: 'DEN લાઇવ વિડિયો એજન્ટ',
        multilingualChatRoom: 'બહુભાષી ચેટ રૂમ',
        denMarketplace: 'DEN એરપોર્ટ માર્કેટપ્લેસ',
        qrCodeCreator: 'ગ્લોબલ માર્કેટપ્લેસ',
        ttyTtd: 'TTY/TDD એ બહેરા અને સાંભળવાની ક્ષતિઓ માટે વિડિયો રિલે સેવાઓ માટે છે',
        airlineAgents: 'એરલાઇન એજન્ટ',
        personalCall: 'વ્યક્તિગત કૉલ',
        phoneNumber: 'ફોન નંબર',
        selectAirline: 'તમારા એરલાઇન એજન્ટને પસંદ કરો',
        airlineCalling: 'એરલાઇન કૉલિંગ',
        connect: 'જોડો',
    },
    pashto: {
        selectLanguage: 'مهرباني وکړئ خپله ژبه وټاکئ',
        backMainMenu: 'شاته اصلي مینو ته',
        helpSubtitle: 'نن موږ څنګه ستاسو سره مرسته کولی شو؟',
        healthWellness: 'روغتیا او هوساینه معاینه',
        denSurvey: 'د پیرودونکو خدماتو سروې',
        emergencyAccess: 'د بیړني لاسرسي خدمتونه',
        multilingualCalling: 'څو ژبو غږ کول',
        freeCalling: 'وړيا زنګ وهل',
        denLiveVideo: 'د ژوندی ویډیو ایجنټ',
        multilingualChatRoom: 'د څو ژبو د خبرو خونه',
        denMarketplace: 'د هوايي ډګر بازار ځای',
        qrCodeCreator: 'نړیوال بازار ځای',
        ttyTtd: 'TTY/TDD د کاڼو او اورېدلو معیوبینو لپاره د ویډیو ریلي خدماتو لپاره دی',
        airlineAgents: 'د هوايي چلند استازي',
        personalCall: 'شخصي زنګ وهل',
        phoneNumber: 'د تلیفون شمیره',
        selectAirline: 'د خپل هوايي شرکت استازی وټاکئ',
        airlineCalling: 'د هوايي کرښې زنګ وهل',
        connect: 'وصل',
    },
    kazakh: {
        selectLanguage: 'Тілді таңдаңыз',
        backMainMenu: 'Негізгі мәзірге оралу',
        helpSubtitle: 'Бүгін сізге қалай көмектесе аламыз?',
        healthWellness: 'Денсаулық пен денсаулықты тексеру',
        denSurvey: 'DEN тұтынушыларға қызмет көрсету сауалнамасы',
        emergencyAccess: 'Төтенше қол жеткізу қызметтері',
        multilingualCalling: 'Көп тілді шақыру',
        freeCalling: 'Тегін қоңырау шалу',
        denLiveVideo: 'DEN Тікелей бейне агенті',
        multilingualChatRoom: 'Көп тілді сөйлесу бөлмесі',
        denMarketplace: 'DEN әуежай базары',
        qrCodeCreator: 'Жаһандық нарық',
        ttyTtd: 'TTY/TDD саңыраулар мен есту қабілеті нашар адамдарға арналған бейнерелейлік қызметтерге арналған',
        airlineAgents: 'Авиакомпания агенттері',
        personalCall: 'Жеке қоңырау',
        phoneNumber: 'Телефон нөмірі',
        selectAirline: 'Авиакомпания агентін таңдаңыз',
        airlineCalling: 'Авиакомпанияға қоңырау шалу',
        connect: 'қосылу',
    },
    burmese: {
        selectLanguage: 'သင့်ဘာသာစကားကို ရွေးချယ်ပါ',
        backMainMenu: 'ပင်မမီနူးသို့ ပြန်သွားရန်',
        helpSubtitle: 'ဒီနေ့ မင်းကို ဘယ်လိုကူညီနိုင်မလဲ။',
        healthWellness: 'ကျန်းမာရေးနှင့် ကျန်းမာရေး စစ်ဆေးခြင်း',
        denSurvey: 'DEN ဖောက်သည်ဝန်ဆောင်မှုစစ်တမ်း',
        emergencyAccess: 'အရေးပေါ်ဝင်ရောက်မှုဝန်ဆောင်မှုများ',
        multilingualCalling: 'ဘာသာစကားမျိုးစုံခေါ်ဆိုခြင်း',
        freeCalling: 'အခမဲ့ခေါ်ဆိုခြင်း',
        denLiveVideo: 'DEN တိုက်ရိုက်ဗီဒီယို ကိုယ်စားလှယ်',
        multilingualChatRoom: 'ဘာသာစုံ စကားပြောခန်း',
        denMarketplace: 'DEN လေဆိပ်ဈေး',
        qrCodeCreator: 'ကမ္ဘာ့စျေးကွက်',
        ttyTtd: 'TTY/TDD သည် နားမကြားသူများနှင့် အကြားအာရုံချို့ယွင်းသူများအတွက် ဗီဒီယို ထပ်ဆင့်လွှင့်ခြင်းဝန်ဆောင်မှုများအတွက်ဖြစ်သည်',
        airlineAgents: 'လေကြောင်းလိုင်း အေးဂျင့်များ',
        personalCall: 'ကိုယ်ရေးကိုယ်တာခေါ်ဆိုမှု',
        phoneNumber: 'ဖုန်းနံပါတ်',
        selectAirline: 'သင့်လေကြောင်းလိုင်း ကိုယ်စားလှယ်ကို ရွေးချယ်ပါ',
        airlineCalling: 'လေကြောင်းလိုင်းခေါ်ဆိုမှု',
        connect: 'ချိတ်ဆက်',
    },
    turkish: {
        selectLanguage: 'Lütfen dilinizi seçin',
        backMainMenu: 'Ana Menüye Dön',
        helpSubtitle: 'Bugün size nasıl yardımcı olabiliriz?',
        healthWellness: 'Sağlık ve Refah Kontrolü',
        denSurvey: 'DEN Müşteri Hizmetleri Anketi',
        emergencyAccess: 'Acil Erişim Hizmetleri',
        multilingualCalling: 'Çok dilli arama',
        freeCalling: 'Ücretsiz Arama',
        denLiveVideo: 'DEN Canlı Video Aracısı',
        multilingualChatRoom: 'Çok Dilli Sohbet Odası',
        denMarketplace: 'DEN Havaalanı Pazaryeri',
        qrCodeCreator: 'Küresel Pazaryeri',
        ttyTtd: 'TTY/TDD, sağır ve işitme engellilere yönelik video aktarım hizmetleri içindir',
        airlineAgents: 'Havayolu acenteleri',
        personalCall: 'Kişisel Arama',
        phoneNumber: 'Telefon Numarası',
        selectAirline: 'Havayolu acentenizi seçin',
        airlineCalling: 'Havayolu Araması',
        Connectà: 'Bağlan',
    },
    'scots-gaelic': {
        selectLanguage: 'Tagh do chànan',
        backMainMenu: 'Air ais dhan phrìomh chlàr',
        helpSubtitle: 'Ciamar as urrainn dhuinn do chuideachadh an-diugh?',
        healthWellness: 'Sgrùdadh Slàinte is Sunnd',
        denSurvey: 'Suirbhidh Seirbheis Luchd-cleachdaidh DEN',
        emergencyAccess: 'Seirbheisean inntrigidh èiginneach',
        multilingualCalling: 'Gairm ioma-chànanach',
        freeCalling: 'Gairm an-asgaidh',
        denLiveVideo: 'Buidheann Bhideo Beò DEN',
        multilingualChatRoom: 'Seòmar còmhraidh ioma-chànanach',
        denMarketplace: 'Margaidh Port-adhair DEN',
        qrCodeCreator: 'Margadh na Cruinne',
        ttyTtd: 'Tha TTY/TDD airson seirbheisean sealaidheachd bhidio dha daoine bodhar is le duilgheadasan claisneachd',
        airlineAgents: 'Riochdairean itealain',
        personalCall: 'Call pearsanta',
        phoneNumber: 'Àireamh Fòn',
        selectAirline: 'Tagh àidseant na companaidh adhair agad',
        airlineCalling: 'Cuairt-adhair',
        connect: 'Ceangail',
    },
    bengali: {
        selectLanguage: 'দয়া করে আপনার ভাষা নির্বাচন করুন',
        backMainMenu: 'প্রধান মেনুতে ফিরে যান',
        helpSubtitle: 'আজ আমরা কীভাবে আপনাকে সাহায্য করতে পারি?',
        healthWellness: 'স্বাস্থ্য এবং সুস্থতা পরীক্ষা',
        denSurvey: 'ডেন গ্রাহক পরিষেবা জরিপ',
        emergencyAccess: 'জরুরী অ্যাক্সেস পরিষেবা',
        multilingualCalling: 'বহুভাষিক কলিং',
        freeCalling: 'ফ্রি কলিং',
        denLiveVideo: 'ডেন লাইভ ভিডিও এজেন্ট',
        multilingualChatRoom: 'বহুভাষিক চ্যাট রুম',
        denMarketplace: 'DEN বিমানবন্দর মার্কেটপ্লেস',
        qrCodeCreator: 'গ্লোবাল মার্কেটপ্লেস',
        ttyTtd: 'টিটিওয়াই/টিডিডি বধির এবং শ্রবণ প্রতিবন্ধীদের জন্য ভিডিও রিলে পরিষেবার জন্য',
        airlineAgents: 'এয়ারলাইন এজেন্ট',
        personalCall: 'ব্যক্তিগত কল',
        phoneNumber: 'ফোন নম্বর',
        selectAirline: 'আপনার এয়ারলাইন এজেন্ট নির্বাচন করুন',
        airlineCalling: 'এয়ারলাইন কলিং',
        connect: 'সংযোগ',
    },
    ml: {
        selectLanguage: 'ദയവായി നിങ്ങളുടെ ഭാഷ തിരഞ്ഞെടുക്കുക',
        backMainMenu: 'മെയിൻ മെനുവിലേക്ക് മടങ്ങുക',
        helpSubtitle: 'ഞങ്ങൾക്ക് ഇന്ന് നിങ്ങളെ എങ്ങനെ സഹായിക്കാനാകും?',
        healthWellness: 'ആരോഗ്യവും ആരോഗ്യ പരിശോധനയും',
        denSurvey: 'DEN കസ്റ്റമർ സർവീസ് സർവേ',
        emergencyAccess: 'അടിയന്തര പ്രവേശന സേവനങ്ങൾ',
        multilingualCalling: 'ബഹുഭാഷാ കോളിംഗ്',
        freeCalling: 'സൌജന്യ കോളിംഗ്',
        denLiveVideo: 'DEN ലൈവ് വീഡിയോ ഏജൻ്റ്',
        multilingualChatRoom: 'ബഹുഭാഷാ ചാറ്റ് റൂം',
        denMarketplace: 'DEN എയർപോർട്ട് മാർക്കറ്റ്പ്ലേസ്',
        qrCodeCreator: 'ആഗോള വിപണി',
        ttyTtd: 'TTY/TDD ബധിരർക്കും ശ്രവണ വൈകല്യമുള്ളവർക്കും വേണ്ടിയുള്ള വീഡിയോ റിലേ സേവനങ്ങൾക്കുള്ളതാണ്',
        airlineAgents: 'എയർലൈൻ ഏജൻ്റുകൾ',
        personalCall: 'വ്യക്തിഗത കോൾ',
        phoneNumber: 'ഫോൺ നമ്പർ',
        selectAirline: 'നിങ്ങളുടെ എയർലൈൻ ഏജൻ്റ് തിരഞ്ഞെടുക്കുക',
        airlineCalling: 'എയർലൈൻ കോളിംഗ്',
        connect: 'കണക്‌റ്റ്',
    },
    nepali: {
        selectLanguage: 'कृपया आफ्नो भाषा चयन गर्नुहोस्',
        backMainMenu: 'मुख्य मेनुमा फर्कनुहोस्',
        helpSubtitle: 'हामी आज तपाईलाई कसरी मद्दत गर्न सक्छौं?',
        healthWellness: 'स्वास्थ्य र कल्याण जाँच',
        denSurvey: 'DEN ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपत्कालीन पहुँच सेवा',
        multilingualCalling: 'बहुभाषी कल',
        freeCalling: 'फ्री कलिङ',
        denLiveVideo: 'DEN लाइभ भिडियो एजेन्ट',
        multilingualChatRoom: 'बहुभाषी च्याट कोठा',
        denMarketplace: 'डेन एयरपोर्ट मार्केटप्लेस',
        qrCodeCreator: 'ग्लोबल मार्केटप्लेस',
        ttyTtd: 'TTY/TDD बहिरा र सुन्न नसक्नेहरूका लागि भिडियो रिले सेवाहरूको लागि हो',
        airlineAgents: 'एयरलाइन एजेन्टहरू',
        personalCall: 'व्यक्तिगत कल',
        phoneNumber: 'फोन नम्बर',
        selectAirline: 'तपाईँको एयरलाइन एजेन्ट छान्नुहोस्',
        airlineCalling: 'एयरलाइन कलिङ',
        connect: 'जोड्नुहोस्',
    },
    croatian: {
        selectLanguage: 'Molimo odaberite svoj jezik',
        backMainMenu: 'Natrag na glavni izbornik',
        helpSubtitle: 'Kako vam možemo pomoći danas?',
        healthWellness: 'Provjera zdravlja i blagostanja',
        denSurvey: 'DEN anketa o korisničkoj službi',
        emergencyAccess: 'Usluge hitnog pristupa',
        multilingualCalling: 'Višejezično pozivanje',
        freeCalling: 'Besplatno pozivanje',
        denLiveVideo: 'DEN video agent uživo',
        multilingualChatRoom: 'Višejezična soba za razgovor',
        denMarketplace: 'DEN Airport Marketplace',
        qrCodeCreator: 'Globalno tržište',
        ttyTtd: 'TTY/TDD je za usluge video releja za gluhe i osobe oštećena sluha',
        airlineAgents: 'Agenti zrakoplovne kompanije',
        personalCall: 'Osobni poziv',
        phoneNumber: 'Telefonski broj',
        selectAirline: 'Odaberite svog agenta zrakoplovne kompanije',
        airlineCalling: 'Poziv iz zrakoplovne kompanije',
        connect: 'Poveži se',
    },
    korean: {
        selectLanguage: '언어를 선택하세요',
        backMainMenu: '메인 메뉴로 돌아가기',
        helpSubtitle: '오늘은 어떻게 도와드릴까요?',
        healthWellness: '건강 및 복지 점검',
        denSurvey: 'DEN 고객 서비스 설문조사',
        emergencyAccess: '긴급 접근 서비스',
        multilingualCalling: '다국어 통화',
        freeCalling: '무료 통화',
        denLiveVideo: 'DEN 라이브 비디오 에이전트',
        multilingualChatRoom: '다국어 채팅방',
        denMarketplace: 'DEN 공항 마켓플레이스',
        qrCodeCreator: '글로벌 마켓플레이스',
        ttyTtd: 'TTY/TDD는 청각 장애인을 위한 영상 중계 서비스입니다.',
        airlineAgents: '항공사 직원',
        personalCall: '개인 통화',
        phoneNumber: '전화 번호',
        selectAirline: '항공사 대리인을 선택하세요',
        airlineCalling: '항공사 전화',
        connect: '연결하다',
    },
    nyanja: {
        selectLanguage: 'Chonde sankhani chinenero chanu',
        backMainMenu: 'Bwerera ku Menyu Yaikulu',
        helpSubtitle: 'Kodi tingakuthandizeni bwanji lero?',
        healthWellness: 'thanzi ndi bwino cheke',
        denSurvey: 'Mafunso a makasitomala',
        emergencyAccess: 'Thandizo Lofulumira',
        multilingualCalling: 'Kuyitana zilankhulo zambiri',
        freeCalling: 'Kuyitana Kwaulere',
        denLiveVideo: 'DEN thandizo la kanema',
        multilingualChatRoom: 'Malo Ochezera a Zilankhulo Zambiri',
        denMarketplace: 'DEN Msika wa Ndege',
        qrCodeCreator: 'Msika wapadziko lonse',
        ttyTtd: 'TY / TDD ndi ya ntchito zoperekera mavidiyo kwa ogontha ndi ovutika kumva',
        airlineAgents: 'Thandizo la ndege',
        personalCall: 'Kuitana Kwaumwini',
        phoneNumber: 'Nambala ya Foni',
        selectAirline: 'Sankhani wothandizira ndege wanu',
        airlineCalling: 'kuitana kwa ndege',
        connect: 'Lumikizanani',
    },
    danish: {
        selectLanguage: 'Vælg venligst dit sprog',
        backMainMenu: 'Tilbage til hovedmenu',
        helpSubtitle: 'Hvordan kan vi hjælpe dig i dag?',
        healthWellness: 'Sundheds- og velværetjek',
        denSurvey: 'DEN Kundeserviceundersøgelse',
        emergencyAccess: 'Nødadgangstjenester',
        multilingualCalling: 'Flersproget opkald',
        freeCalling: 'Gratis opkald',
        denLiveVideo: 'DEN person på video',
        multilingualChatRoom: 'Flersproget chatrum',
        denMarketplace: 'DEN Lufthavn Marketplace',
        qrCodeCreator: 'Gverden markedsplads',
        ttyTtd: 'TTY/TDD er til videorelætjenester for døve og hørehæmmede',
        airlineAgents: 'Flyselskabsagenter',
        personalCall: 'Personligt opkald',
        phoneNumber: 'Telefonnummer',
        selectAirline: 'Vælg din flyselskabsagent',
        airlineCalling: 'Flyselskab ringer',
        connect: 'Forbind',
    },
    id: {
        selectLanguage: 'Silakan pilih bahasa Anda',
        backMainMenu: 'Kembali ke Menu Utama',
        helpSubtitle: 'Apa yang bisa kami bantu hari ini?',
        healthWellness: 'Pemeriksaan Kesehatan dan Kesejahteraan',
        denSurvey: 'Survei Layanan Pelanggan DEN',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Panggilan multibahasa',
        freeCalling: 'Panggilan Gratis',
        denLiveVideo: 'Agen Video Langsung DEN',
        multilingualChatRoom: 'Ruang Obrolan Multibahasa',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD untuk layanan relai video bagi tuna rungu dan gangguan pendengaran',
        airlineAgents: 'Agen maskapai penerbangan',
        personalCall: 'Panggilan Pribadi',
        phoneNumber: 'Nomor Telepon',
        selectAirline: 'Pilih agen maskapai penerbangan Anda',
        airlineCalling: 'Panggilan Maskapai',
        connect: 'Menghubungkan',
    },
    czech: {
        selectLanguage: 'Vyberte prosím svůj jazyk',
        backMainMenu: 'Zpět do hlavní nabídky',
        helpSubtitle: 'Jak vám dnes můžeme pomoci?',
        healthWellness: 'Kontrola zdraví a wellness',
        denSurvey: 'Průzkum zákaznických služeb DEN',
        emergencyAccess: 'Služby nouzového přístupu',
        multilingualCalling: 'Vícejazyčné volání',
        freeCalling: 'Volání zdarma',
        denLiveVideo: 'DEN Osoba na videu',
        multilingualChatRoom: 'Vícejazyčná chatovací místnost',
        denMarketplace: 'DEN Letištní tržiště',
        qrCodeCreator: 'Globální tržiště',
        ttyTtd: 'TTY/TDD je pro služby přenosu videa pro neslyšící a sluchově postižené',
        airlineAgents: 'Agenti leteckých společností',
        personalCall: 'Osobní hovor',
        phoneNumber: 'Telefonní číslo',
        selectAirline: 'Vyberte svého leteckého agenta',
        airlineCalling: 'Volání letecké společnosti',
        connect: 'Připojit',
    },
    uyghur: {
        selectLanguage: 'تىلىڭىزنى تاللاڭ',
        backMainMenu: 'باش تىزىملىككە قايتىش',
        helpSubtitle: 'بۈگۈن بىز سىزگە قانداق ياردەم قىلالايمىز؟',
        healthWellness: 'ساغلاملىق ۋە ساغلاملىق تەكشۈرۈشى',
        denSurvey: 'DEN خېرىدارلار مۇلازىمىتىنى تەكشۈرۈش',
        emergencyAccess: 'جىددى ئەھۋالغا تاقابىل تۇرۇش مۇلازىمىتى',
        multilingualCalling: 'كۆپ تىللىق چاقىرىش',
        freeCalling: 'ھەقسىز چاقىرىش',
        denLiveVideo: 'DEN نەق مەيدان سىن ۋاكالەتچىسى',
        multilingualChatRoom: 'كۆپ تىللىق پاراڭلىشىش ئۆيى',
        denMarketplace: 'DEN ئايرودروم بازىرى',
        qrCodeCreator: 'يەرشارى بازىرى',
        ttyTtd: 'TTY / TDD گاس ۋە ئاڭلاش ئىقتىدارى توسالغۇغا ئۇچرىغانلارغا سىن يەتكۈزۈش مۇلازىمىتى ئۈچۈن',
        airlineAgents: 'ئاۋىئاتسىيە ۋاكالەتچىسى',
        personalCall: 'شەخسىي تېلېفون',
        phoneNumber: 'تېلېفون نومۇرى',
        selectAirline: 'ئاۋىئاتسىيە ۋاكالەتچىڭىزنى تاللاڭ',
        airlineCalling: 'ئاۋىئاتسىيە شىركىتى چاقىرىش',
        connect: 'ئۇلىنىش',
    },
    zh: {
        selectLanguage: '请选择您的语言',
        backMainMenu: '返回主菜单',
        helpSubtitle: '今天我们能为您提供什么帮助？',
        healthWellness: '健康与保健检查',
        denSurvey: 'DEN 客户服务调查',
        emergencyAccess: '紧急通道服务',
        multilingualCalling: '多语言通话',
        freeCalling: '免费通话',
        denLiveVideo: 'DEN 现场视频代理',
        multilingualChatRoom: '多语言聊天室',
        denMarketplace: 'DEN 机场市场',
        qrCodeCreator: '全球市场',
        ttyTtd: 'TTY/TDD 适用于聋哑人和听力障碍人士的视频转播服务',
        airlineAgents: '航空公司代理',
        personalCall: '个人电话',
        phoneNumber: '电话号码',
        selectAirline: '选择您的航空公司代理',
        airlineCalling: '航空公司呼叫',
        connect: '连接',
    },
    hawaiian: {
        selectLanguage: 'E ʻoluʻolu e koho i kāu ʻōlelo',
        backMainMenu: 'Hoʻi i ka papa kuhikuhi nui',
        helpSubtitle: 'Pehea mākou e kōkua ai iā ʻoe i kēia lā?',
        healthWellness: 'Hōʻike Ola a maikaʻi',
        denSurvey: 'Nānā lawelawe mea kūʻai mai DEN',
        emergencyAccess: 'Nā lawelawe hoʻokele pilikia',
        multilingualCalling: 'Kahea ʻōlelo he nui',
        freeCalling: 'Kahea manuahi',
        denLiveVideo: 'DEN Wikiō ola ',
        multilingualChatRoom: 'Luna kamaʻilio lehulehu',
        denMarketplace: 'Make kahua mokulele DEN',
        qrCodeCreator: 'Make kūʻai honua',
        ttyTtd: 'TTY/TDD no nā lawelawe hoʻolele wikiō no ka poʻe kuli a me ka hoʻolohe ʻino',
        airlineAgents: 'Nā luna mokulele',
        personalCall: 'Kahea pilikino',
        phoneNumber: 'Helu Kelepona',
        selectAirline: 'E koho i kāu ʻelele mokulele',
        airlineCalling: 'Kahea ʻana i ka mokulele',
        connect: 'Hoʻohui',
    },
    telugu: {
        selectLanguage: 'దయచేసి మీ భాషను ఎంచుకోండి',
        backMainMenu: 'తిరిగి ప్రధాన మెనూకి',
        helpSubtitle: 'ఈరోజు మేము మీకు ఎలా సహాయం చేయగలము?',
        healthWellness: 'హెల్త్ అండ్ వెల్నెస్ చెక్',
        denSurvey: 'DEN కస్టమర్ సర్వీస్ సర్వే',
        emergencyAccess: 'అత్యవసర యాక్సెస్ సేవలు',
        multilingualCalling: 'బహుభాషా కాలింగ్',
        freeCalling: 'ఉచిత కాలింగ్',
        denLiveVideo: 'DEN లైవ్ వీడియో ఏజెంట్',
        multilingualChatRoom: 'బహుభాషా చాట్ రూమ్',
        denMarketplace: 'DEN ఎయిర్‌పోర్ట్ మార్కెట్‌ప్లేస్',
        qrCodeCreator: 'గ్లోబల్ మార్కెట్‌ప్లేస్',
        ttyTtd: 'TTY/TDD అనేది చెవిటి మరియు వినికిడి లోపం ఉన్నవారి కోసం వీడియో రిలే సేవల కోసం',
        airlineAgents: 'ఎయిర్‌లైన్ ఏజెంట్లు',
        personalCall: 'వ్యక్తిగత కాల్',
        phoneNumber: 'ఫోన్ నంబర్',
        selectAirline: 'మీ ఎయిర్‌లైన్ ఏజెంట్‌ని ఎంచుకోండి',
        airlineCalling: 'ఎయిర్‌లైన్ కాలింగ్',
        connect: 'కనెక్ట్',
    },
    hebrew: {
        selectLanguage: 'אנא בחר את השפה שלך',
        backMainMenu: 'חזרה לתפריט הראשי',
        helpSubtitle: 'איך נוכל לעזור לך היום?',
        healthWellness: 'בדיקת בריאות ואיכות חיים',
        denSurvey: 'סקר שירות לקוחות DEN',
        emergencyAccess: 'שירותי גישה לשעת חירום',
        multilingualCalling: 'שיחה רב לשונית',
        freeCalling: 'שיחות חינם',
        denLiveVideo: 'סוכן וידאו חי של DEN',
        multilingualChatRoom: "חדר צ'אט רב לשוני",
        denMarketplace: 'DEN שוק שדה התעופה',
        qrCodeCreator: 'שוק גלובלי',
        ttyTtd: 'TTY/TDD מיועד לשירותי ממסר וידאו לחירשים ולקויי שמיעה',
        airlineAgents: 'סוכני חברות תעופה',
        personalCall: 'שיחה אישית',
        phoneNumber: 'מספר טלפון',
        selectAirline: 'בחר את סוכן חברת התעופה שלך',
        airlineCalling: 'חברת תעופה מתקשרת',
        connect: 'לְחַבֵּר',
    },
    xhosa: {
        selectLanguage: 'Nceda ukhethe ulwimi lwakho',
        backMainMenu: 'Buyela kwiMenu engundoqo',
        helpSubtitle: 'Singakunceda njani namhlanje?',
        healthWellness: 'Ukuhlolwa kweMpilo kunye nokuba sempilweni',
        denSurvey: 'UDEN uPhando lweNkonzo yabaThengi',
        emergencyAccess: 'Iinkonzo zoFikelelo oluNgxamisekileyo',
        multilingualCalling: 'Ukufowuna ngeelwimi ezininzi',
        freeCalling: 'Ukufowuna simahla',
        denLiveVideo: 'DEN iarhente yevidiyo ephilayo',
        multilingualChatRoom: 'Igumbi lokuncokola ngeelwimi ezininzi',
        denMarketplace: 'DEN indawo yentengiso yesikhululo seenqwelomoya',
        qrCodeCreator: 'IMarike yeHlabathi',
        ttyTtd: 'I-TTY/TDD yeyeenkonzo zokudlulisa ividiyo kubantu abangevayo nabangevayo',
        airlineAgents: 'iiarhente zeenqwelomoya',
        personalCall: 'Umnxeba wobuqu',
        phoneNumber: 'Inombolo yefowuni',
        selectAirline: 'Khetha iarhente yenqwelo moya',
        airlineCalling: 'Ukufowuna ngenqwelomoya',
        connect: 'Qhagamshela',
    },
    estonian: {
        selectLanguage: 'Palun valige oma keel',
        backMainMenu: 'Tagasi peamenüüsse',
        helpSubtitle: 'Kuidas saame teid täna aidata?',
        healthWellness: 'Tervise ja heaolu kontroll',
        denSurvey: 'DEN klienditeeninduse uuring',
        emergencyAccess: 'Hädaabi juurdepääsuteenused',
        multilingualCalling: 'Mitmekeelne helistamine',
        freeCalling: 'Tasuta helistamine',
        denLiveVideo: 'DEN inimene videol',
        multilingualChatRoom: 'Mitmekeelne jututuba',
        denMarketplace: 'DEN lennujaama turg',
        qrCodeCreator: 'Globaalne turg',
        ttyTtd: 'TTY/TDD on mõeldud kurtidele ja vaegkuuljatele mõeldud videoedastusteenuste jaoks',
        airlineAgents: 'lennufirma agendid',
        personalCall: 'Isiklik kõne',
        phoneNumber: 'Telefoninumber',
        selectAirline: 'Valige oma lennufirma agent',
        airlineCalling: 'Lennufirma helistamine',
        connect: 'Ühenda',
    },
    welsh: {
        selectLanguage: 'Dewiswch eich iaith ',
        backMainMenu: "Yn ôl i'r Brif Ddewislen",
        helpSubtitle: 'Sut gallwn ni eich helpu chi heddiw?',
        healthWellness: 'Gwiriad Iechyd a Lles',
        denSurvey: 'Arolwg Gwasanaeth Cwsmeriaid DEN',
        emergencyAccess: 'Gwasanaethau Mynediad Brys ',
        multilingualCalling: 'Galwad amlieithog',
        FreeGaling: 'Galw am Ddim',
        denLiveVideo: 'Asiant Fideo Byw DEN',
        multilingualChatRoom: 'Ystafell Sgwrsio Amlieithog',
        denMarketplace: 'Marchnad Maes Awyr DEN',
        qrCodeCreator: 'Marchnad Fyd-eang',
        ttyTtd: "Mae TTY/TDD ar gyfer gwasanaethau cyfnewid fideo i'r byddar a'r rhai â nam ar eu clyw",
        airlineAgents: 'Asiantau cwmni hedfan',
        personalCall: 'Galwad Bersonol',
        phoneNumber: 'Rhif Ffôn',
        selectAirline: 'Dewiswch eich asiant cwmni hedfan',
        airlineCalling: 'Galw cwmni hedfan',
        connect: 'Cysylltu',
    },
    'urdu-india': {
        selectLanguage: 'براہ کرم اپنی زبان منتخب کریں',
        backMainMenu: 'واپس مین مینو پر',
        helpSubtitle: 'آج ہم آپ کی مدد کیسے کر سکتے ہیں؟',
        healthWellness: 'صحت اور فلاح و بہبود کی جانچ',
        denSurvey: 'DEN کسٹمر سروس سروے',
        emergencyAccess: 'ہنگامی رسائی کی خدمات',
        multilingualCalling: 'کثیر لسانی کالنگ',
        freeCalling: 'مفت کالنگ',
        denLiveVideo: 'DEN لائیو ویڈیو ایجنٹ',
        multilingualChatRoom: 'کثیر لسانی چیٹ روم',
        denMarketplace: 'DEN ائیرپورٹ مارکیٹ پلیس',
        qrCodeCreator: 'عالمی بازار',
        ttyTtd: 'TTY/TDD بہرے اور سماعت سے محروم افراد کے لیے ویڈیو ریلے خدمات کے لیے ہے',
        airlineAgents: 'ایئر لائن ایجنٹس',
        personalCall: 'ذاتی کال',
        phoneNumber: 'فون نمبر',
        selectAirline: 'اپنے ایئر لائن ایجنٹ کو منتخب کریں',
        airlineCalling: 'ایئر لائن کالنگ',
        connect: 'متصل',
    },
    su: {
        selectLanguage: 'Punten pilih basa anjeun',
        backMainMenu: 'Balik deui ka Menu Utama',
        helpSubtitle: 'Kumaha urang tiasa ngabantosan anjeun ayeuna?',
        healthWellness: 'Cék Kaséhatan sareng Kasalametan',
        denSurvey: 'Survey Layanan Pelanggan DEN',
        emergencyAccess: 'Layanan Aksés Darurat',
        multilingualCalling: 'Télépon multibasa',
        freeCalling: 'Telepon Gratis',
        denLiveVideo: 'DEN jalma dina video',
        multilingualChatRoom: 'Ruang Obrolan Multibasa',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD kanggo jasa relay vidéo pikeun tunarungu sareng tunarungu',
        airlineAgents: 'Agén maskapai',
        personalCall: 'Telepon Pribadi',
        phoneNumber: 'Nomer Telepon',
        selectAirline: 'Pilih agén maskapai anjeun',
        airlineCalling: 'Nelepon Maskapai Penerbangan',
        connect: 'Sambungkeun',
    },
    slovak: {
        selectLanguage: 'Vyberte svoj jazyk',
        backMainMenu: 'Späť do hlavnej ponuky',
        helpSubtitle: 'Ako vám dnes môžeme pomôcť?',
        healthWellness: 'Kontrola zdravia a blahobytu',
        denSurvey: 'Prieskum zákazníckych služieb DEN',
        emergencyAccess: 'Služby núdzového prístupu',
        multilingualCalling: 'Viacjazyčné volanie',
        freeCalling: 'Bezplatné volanie',
        denLiveVideo: 'DEN osoba na videu',
        multilingualChatRoom: 'Viacjazyčná chatová miestnosť',
        denMarketplace: 'DEN letiskové trhovisko',
        qrCodeCreator: 'Globálny trh',
        ttyTtd: 'TTY/TDD je pre služby prenosu videa pre nepočujúcich a sluchovo postihnutých',
        airlineAgents: 'Agenti leteckých spoločností',
        personalCall: 'Osobný hovor',
        phoneNumber: 'Telefónne číslo',
        selectAirline: 'Vyberte si agenta leteckej spoločnosti',
        airlineCalling: 'Volanie leteckej spoločnosti',
        connect: 'pripojiť',
    },
    udmurt: {
        selectLanguage: 'Пожалуйста, ачиз быръе кыл',
        backMainMenu: 'валтӥсь меню сьӧрам',
        helpSubtitle: 'Кызьы тон мыным туннэ юрттод быгатоз-а?',
        healthWellness: 'тазалык эскерон',
        denSurvey: 'юан-веран den клиент',
        emergencyAccess: 'экстренной Служба кариськисько',
        multilingualCalling: 'ӧте многоязычный',
        freeCalling: 'Дунтэк звонок',
        denLiveVideo: 'DEN Видеоагент Live',
        multilingualChatRoom: 'чат Многоязычный',
        denMarketplace: 'площадкаослэн вузкарон аэропортын DEN',
        qrCodeCreator: 'площадкаослэн вузкарон глобальный',
        ttyTtd: 'TTY/секытпель но тонгес служба понна кизёно ретрансляция tdd video',
        airlineAgents: 'Agenti leteckých spoločností',
        personalCall: 'Osobný hovor',
        phoneNumber: 'Telefónne číslo',
        selectAirline: 'Vyberte si agenta leteckej spoločnosti',
        airlineCalling: 'Volanie leteckej spoločnosti',
        connect: 'pripojiť',
    },
    latvian: {
        selectLanguage: 'Lūdzu, izvēlieties valodu',
        backMainMenu: 'Atpakaļ uz galveno izvēlni',
        helpSubtitle: 'Kā mēs varam jums palīdzēt šodien?',
        healthWellness: 'Veselības un labklājības pārbaude',
        denSurvey: 'DEN klientu apkalpošanas aptauja',
        emergencyAccess: 'Ārkārtas piekļuves pakalpojumi',
        multilingualCalling: 'Daudzvalodu zvanīšana',
        freeCalling: 'Bezmaksas zvani',
        denLiveVideo: 'DEN tiešraides video aģents',
        multilingualChatRoom: 'Daudzvalodu tērzēšanas telpa',
        denMarketplace: 'DEN lidostas tirgus',
        qrCodeCreator: 'Globālais tirgus',
        ttyTtd: 'TTY/TDD ir paredzēts video pārraides pakalpojumiem nedzirdīgajiem un vājdzirdīgajiem',
        airlineAgents: 'Aviokompānijas aģenti',
        personalCall: 'Personīgais zvans',
        phoneNumber: 'Tālruņa numurs',
        selectAirline: 'Izvēlieties savu aviokompānijas aģentu',
        airlineCalling: 'Aviokompānijas zvani',
        connect: 'Savienot',
    },
    yua: {
        selectLanguage: "Seleccione u t'aano",
        backMainMenu: "k'a' le noj bejo' menú",
        helpSubtitle: "wáaj bix je'el u páajtal k wáantik a bejla'e",
        healthWellness: 'Chequeo u toj óolal yéetel bienestar',
        denSurvey: 'Encuesta u Sergice u Clientes u DEN',
        emergencyAccess: "yéetel u muuk' láakal",
        multilingualCalling: 'Llamadas multilingües',
        freeCalling: 'Llamadas gratuitas',
        denLiveVideo: "aj nu'uktaj u vídeo en beetaj ich u TS'AIKo'OB",
        multilingualChatRoom: 'Sala u chat multilingüe',
        denMarketplace: "Mercado le aeropuerto TS'AIKO'OB",
        qrCodeCreator: 'Mercado global',
        ttyTtd: "TTY leti' TDD u ti'al yéetel retransmisión vídeo utia'al máako'ob sordas yéetel discapacidad auditiva",
        airlineAgents: 'Agentes aerolíneas',
        personalCall: "t'aan máaxo'ob",
        phoneNumber: "meyaj ku u nu'ukul t'aan",
        selectAirline: "Seleccione u aj nu'uktaj aerolínea",
        airlineCalling: 'Llamadas u aerolíneas',
        connect: 'Conectar',
    },
    serbian: {
        selectLanguage: 'Молимо изаберите свој језик',
        backMainMenu: 'Назад на главни мени',
        helpSubtitle: 'Како вам можемо помоћи данас?',
        healthWellness: 'Провера здравља и добробити',
        denSurvey: 'ДЕН Цустомер Сервице Анкета',
        emergencyAccess: 'Услуге приступа у хитним случајевима',
        multilingualCalling: 'Вишејезично позивање',
        freeCalling: 'Бесплатно позивање',
        denLiveVideo: 'ДЕН Ливе Видео Агент',
        multilingualChatRoom: 'Вишејезична соба за ћаскање',
        денМаркетплаце: 'ДЕН Аирпорт Маркетплаце',
        крЦодеЦреатор: 'Глобално тржиште',
        ttyTtd: 'ТТИ/ТДД је за услуге видео релеја за глуве и наглуве',
        airlineAgents: 'Агенти авио-компаније',
        personalCall: 'Лични позив',
        phoneNumber: 'Број телефона',
        selectAirline: 'Изаберите свог агента авиокомпаније',
        airlineCalling: 'Позивање авио компаније',
        connect: 'Повежи се',
    },
    shona: {
        selectLanguage: 'Ndapota sarudza mutauro wako',
        backMainMenu: 'Dzokera kuMain Menu',
        helpSubtitle: 'Tingakubatsira sei nhasi?',
        healthWellness: 'Ongororo yehutano uye hutano',
        denSurvey: 'DEN Mutengi Sevhisi ',
        emergencyAccess: 'Emergency Access Services',
        multilingualCalling: 'Kufona nemitauro yakawanda',
        freeCalling: 'Kufona Kwemahara',
        denLiveVideo: 'DEN munhu pavhidhiyo ',
        multilingualChatRoom: 'mitauro yakawanda ',
        denMarketplace: 'DEN airport pamusika ',
        qrCodeCreator: 'musika wepasi rose ',
        ttyTtd: 'TTY/TDD ndeyevhidhiyo relay masevhisi kune matsi nevasinganzwe',
        airlineAgents: 'Vamiririri vendege',
        personalCall: 'kufona kwako ',
        phoneNumber: 'Nhamba Yerunhare',
        selectAirline: 'Sarudza mumiriri wendege',
        airlineCalling: 'kufona kwendege ',
        connect: 'Batanidza',
    },
    samoan: {
        selectLanguage: "Fa'amolemole filifili lau gagana",
        backMainMenu: 'Toe i le Menu Autu',
        helpSubtitle: 'E faapefea ona matou fesoasoani atu ia te oe i le asō?',
        healthWellness: 'Siaki le Soifua Maloloina ma le Soifua Maloloina',
        denSurvey: "DEN Su'esu'ega Auaunaga Fa'atau",
        emergencyAccess: "Au'aunaga Avanoa Fa'afuase'i",
        multilingualCalling: 'Valaau e tele gagana',
        freeCalling: 'Valaau fua',
        denLiveVideo: 'DEN sui o vitio tuusao',
        multilingualChatRoom: "Potu Fetalafa'iga e tele gagana",
        denMarketplace: 'DEN Malae Vaalele Maketi',
        qrCodeCreator: "Fa'atauga Fa'alelalolagi",
        ttyTtd: "TTY/TDD e mo auaunaga fa'asalalau vitiō mo ē logonoa ma fa'alogo",
        airlineAgents: 'Soi o vaalele',
        personalCall: 'Valaau patino',
        phoneNumber: 'Numera Telefoni',
        selectAirline: 'Filifili lau sooupu vaalele',
        airlineCalling: 'Valaau i le Vaalele',
        connect: "Feso'ota'i",
    },
    de: {
        selectLanguage: 'Bitte wählen Sie Ihre Sprache',
        backMainMenu: 'Zurück zum Hauptmenü',
        helpSubtitle: 'Wie können wir Ihnen heute behilflich sein?',
        healthWellness: 'Gesundheits- und Wellness-Check',
        denSurvey: 'DEN-Kundendienstumfrage',
        emergencyAccess: 'Notfallzugangsdienste',
        multilingualCalling: 'Mehrsprachiger Anruf',
        freeCalling: 'Kostenloses Telefonieren',
        denLiveVideo: 'DEN Persönlicher Videoagent',
        multilingualChatRoom: 'Mehrsprachiger Chatraum',
        denMarketplace: 'Marktplatz für Flughäfen',
        qrCodeCreator: 'Globaler Marktplatz',
        ttyTtd: 'TTY/TDD ist für Videoübertragungsdienste für Gehörlose und Hörgeschädigte vorgesehen',
        airlineAgents: 'Airline-Agenten',
        personalCall: 'Persönlicher Anruf',
        phoneNumber: 'Telefonnummer',
        selectAirline: 'Wählen Sie Ihren Airline-Agenten aus',
        airlineCalling: 'Airline-Anrufe',
        connect: 'Verbinden',
    },
    'vi-VN': {
        selectLanguage: 'Hãy chọn ngôn ngữ của bạn',
        backMainMenu: 'Quay lại Menu Chính',
        helpSubtitle: 'Hôm nay chúng tôi có thể giúp gì cho bạn?',
        healthWellness: 'Kiểm tra sức khỏe và thể chất',
        denSurvey: 'Khảo sát dịch vụ khách hàng của DEN',
        emergencyAccess: 'Dịch vụ truy cập khẩn cấp',
        multilingualCalling: 'Gọi đa ngôn ngữ',
        freeCalling: 'Gọi miễn phí',
        denLiveVideo: 'Tác nhân video trực tiếp DEN',
        multilingualChatRoom: 'Phòng trò chuyện đa ngôn ngữ',
        denMarketplace: 'Thị trường sân bay DEN',
        qrCodeCreator: 'Thị trường toàn cầu',
        ttyTtd: 'TTY/TDD dành cho dịch vụ chuyển tiếp video dành cho người điếc và khiếm thính',
        airlineAgents: 'Đại lý hãng hàng không',
        personalCall: 'Cuộc gọi cá nhân',
        phoneNumber: 'Số điện thoại',
        selectAirline: 'Chọn đại lý hãng hàng không của bạn',
        airlineCalling: 'Gọi qua hãng hàng không',
        connect: 'Kết nối',
    },
    maori: {
        selectLanguage: 'Tīpakohia tō reo',
        backMainMenu: 'Hoki ki te Tahua Matua',
        helpSubtitle: 'Me pehea e taea ai e matou te awhina i a koe i tenei ra?',
        healthWellness: 'Tirohanga Hauora me te Ora',
        denSurvey: 'Tirohanga Ratonga Kaihoko DEN',
        emergencyAccess: 'Ratonga Uru ohorere',
        multilingualCalling: 'Waea reo maha',
        freeCalling: 'Waea Koreutu',
        denLiveVideo: 'DEN māngai ataata ora',
        multilingualChatRoom: 'Rūma Kōrerorero Rereha',
        denMarketplace: 'Maakete Tauranga rererangi DEN',
        qrCodeCreator: 'Maakete Ao',
        ttyTtd: 'Ko TTY/TDD mo nga ratonga kawe riipene whakaata mo te hunga turi me te hunga whakarongo',
        airlineAgents: 'Kaiwhakahaere rererangi',
        personalCall: 'Waea Whaiaro',
        phoneNumber: 'Tau Waea',
        selectAirline: 'Tīpakohia tō kaihoko waka rererangi',
        airlineCalling: 'Waea Rererangi',
        connect: 'Hono',
    },
    bashkir: {
        selectLanguage: 'зинһар, телегеҙҙе һайлағыҙ',
        backMainMenu: 'Vтөп менюға Ҡайтыу',
        helpSubtitle: 'бөгөн Һеҙгә Нисек ярҙам итә алабыҙ?',
        healthWellness: 'һаулыҡ торошон Тикшереү',
        denSurvey: 'den клиенттары Араһында Һорау алыу',
        emergencyAccess: 'ашығыс инеү Хеҙмәттәре',
        multilingualCalling: 'Күп Телле Саҡырыусы',
        freeCalling: 'Бушлай шылтыратыуҙар',
        denLiveVideo: 'Видеоагент DEN Live',
        multilingualChatRoom: 'Күп Телле чат',
        denMarketplace: 'DEN аэропортындағы Сауҙа майҙансығы',
        qrCodeCreator: 'Глобаль сауҙа майҙансығы',
        ttyTtd: 'TTY/TDD һаңғырауҙар һәм насар ишетеүселәр өсөн видео ретрансляция хеҙмәттәре өсөн тәғәйенләнгән',
        airlineAgents: 'авиакомпания Агенттары',
        personalCall: 'Шәхси шылтыратыу',
        phoneNumber: 'телефон Номеры',
        selectAirline: 'һеҙҙең авиакомпанияның агентын Һайлағыҙ',
        airlineCalling: 'авиакомпания Саҡырыуы',
        connect: 'Бәйләнешкә ',
    },
    pt: {
        selectLanguage: 'Por favor selecione seu idioma',
        backMainMenu: 'Voltar ao Menu Principal',
        helpSubtitle: 'Como podemos ajudá-lo hoje?',
        healthWellness: 'Verificação de saúde e bem-estar',
        denSurvey: 'Pesquisa de Atendimento ao Cliente DEN',
        emergencyAccess: 'Serviços de acesso de emergência',
        multilingualCalling: 'Chamadas multilíngues',
        freeCalling: 'Chamadas Gratuitas',
        denLiveVideo: 'Agente de vídeo ao vivo DEN',
        multilingualChatRoom: 'Sala de bate-papo multilíngue',
        denMarketplace: 'Mercado do Aeroporto DEN',
        qrCodeCreator: 'Mercado Global',
        ttyTtd: 'TTY/TDD é para serviços de retransmissão de vídeo para surdos e deficientes auditivos',
        airlineAgents: 'Agentes aéreos',
        personalCall: 'Chamada Pessoal',
        phoneNumber: 'Número de telefone',
        selectAirline: 'Selecione seu agente aéreo',
        airlineCalling: 'Chamada de companhia aérea',
        connect: 'Conectar',
    },
    bg: {
        selectLanguage: 'Моля, изберете вашия език',
        backMainMenu: 'Назад към главното меню',
        helpSubtitle: 'Как можем да ви помогнем днес?',
        healthWellness: 'Проверка на здравето и благосъстоянието',
        denSurvey: 'Проучване за обслужване на клиенти на DEN',
        emergencyAccess: 'Услуги за спешен достъп',
        multilingualCalling: 'Многоезично повикване',
        freeCalling: 'Безплатно обаждане',
        denLiveVideo: 'DEN видео агент на живо',
        multilingualChatRoom: 'Многоезична стая за чат',
        denMarketplace: 'Пазар на летище DEN',
        qrCodeCreator: 'Глобален пазар',
        ttyTtd: 'TTY/TDD е за услуги за видеопредаване за глухи и хора с увреден слух',
        airlineAgents: 'Агенти на авиокомпанията',
        personalCall: 'Лично обаждане',
        phoneNumber: 'Телефонен номер',
        selectAirline: 'Изберете вашия агент на авиокомпанията',
        airlineCalling: 'Обаждане от авиокомпания',
        connect: 'Свързване',
    },
    sindhi: {
        selectLanguage: 'مهرباني ڪري پنھنجي ٻولي چونڊيو',
        backMainMenu: 'مکين مينيو ڏانھن واپس',
        helpSubtitle: 'اڄ اسان توهان جي ڪيئن مدد ڪري سگهون ٿا؟',
        healthWellness: 'صحت ۽ تندرستي جي چڪاس',
        denSurvey: 'DEN ڪسٽمر سروس سروي',
        emergencyAccess: 'ايمرجنسي رسائي خدمتون',
        multilingualCalling: 'گهڻ لساني ڪالنگ',
        freeCalling: 'مفت ڪالنگ',
        denLiveVideo: 'DEN لائيو وڊيو ايجنٽ',
        multilingualChatRoom: 'گهڻ لساني چيٽ روم',
        denMarketplace: 'DEN ايئرپورٽ مارڪيٽ',
        qrCodeCreator: 'عالمي بازار',
        ttyTtd: 'TTY/TDD ٻوڙن ۽ ٻڌڻ کان معذور ماڻهن لاءِ وڊيو رلي سروسز لاءِ آهي',
        airlineAgents: 'ايئر لائن ايجنٽ',
        personalCall: 'ذاتي ڪال',
        phoneNumber: 'فون نمبر',
        selectAirline: 'پنهنجي ايئر لائن ايجنٽ کي چونڊيو',
        airlineCalling: 'ايئر لائن ڪالنگ',
        connect: 'ڳنڍيو',
    },
    yoruba: {
        selectLanguage: 'Jọwọ yan ede rẹ',
        backMainMenu: 'Pada si Akojọ aṣyn akọkọ',
        helpSubtitle: 'Bawo ni a ṣe le ran ọ lọwọ loni?',
        healthWellness: 'Ṣayẹwo Ilera ati Nini alafia',
        denSurvey: 'Iwadi Iṣẹ Onibara DEN',
        emergencyAccess: 'Awọn iṣẹ Wiwọle Pajawiri',
        multilingualCalling: 'Ìpè onímọ̀ èdè púpọ̀',
        freeCalling: 'Ipe Ọfẹ',
        denLiveVideo: 'Aṣojú Fidio Live DEN',
        multilingualChatRoom: 'Yàrá ìfọ̀rọ̀wérọ̀ oríṣiríṣi èdè',
        denMarketplace: 'Ibi ọjà Papa ọkọ ofurufu DEN',
        qrCodeCreator: 'Ibi Ọjà Agbaye',
        ttyTtd: 'TTY/TDD jẹ fun awọn iṣẹ isọdọtun fidio fun awọn aditi ati awọn alaiṣẹ igbọran',
        airlineAgents: 'Awọn aṣoju ofurufu',
        personalCall: 'Ipe Ti ara ẹni',
        phoneNumber: 'Nọ́ḿbà Fóònù',
        selectAirline: 'Yan aṣoju ọkọ ofurufu rẹ',
        airlineCalling: 'Pípe ọkọ̀ òfurufú',
        connect: 'Sopọ',
    },
    sl: {
        selectLanguage: 'Izberite svoj jezik',
        backMainMenu: 'Nazaj v glavni meni',
        helpSubtitle: 'Kako vam lahko danes pomagamo?',
        healthWellness: 'Pregled zdravja in dobrega počutja',
        denSurvey: 'Anketa o storitvah za stranke DEN',
        emergencyAccess: 'Storitve dostopa v sili',
        multilingualCalling: 'Večjezično klicanje',
        freeCalling: 'Brezplačno klicanje',
        denLiveVideo: 'DEN Video agent v živo',
        multilingualChatRoom: 'Večjezična klepetalnica',
        denMarketplace: 'DEN Tržnica na letališču',
        qrCodeCreator: 'Globalna tržnica',
        ttyTtd: 'TTY/TDD je za storitve video releja za gluhe in naglušne',
        airlineAgents: 'Letalski agenti',
        personalCall: 'Osebni klic',
        phoneNumber: 'Telefonska številka',
        selectAirline: 'Izberite svojega letalskega agenta',
        airlineCalling: 'Klicanje letalske družbe',
        connect: 'Povežite se',
    },
    tongan: {
        selectLanguage: 'Kataki ʻo fili hoʻo lea fakafonua',
        backMainMenu: 'foki ki he main menu',
        helpSubtitle: 'Te mau lava fēfē ʻo tokoniʻi koe he ʻahó ni?',
        healthWellness: 'moʻui lelei mo Wellness sieke',
        denSurvey: 'savea tokoni ʻa e kasitomaa',
        emergencyAccess: 'ngaahi tokoni ki he meʻa fakatuʻupakee',
        multilingualCalling: 'uiuiʻi fakavahaʻa-puleʻanga',
        freeCalling: 'uiuiʻi taʻetotongi',
        denLiveVideo: 'den fakafofonga vitio hangatonu',
        multilingualChatRoom: 'loki talanoa multilingual',
        denMarketplace: 'maketi malaʻevakapuna DEN',
        qrCodeCreator: 'maketi fakamamani lahi',
        ttyTtd: ' ko e TTY/TDD ko e ngaahi tokoni ki he vitioo maʻa e kau tuli mo e fanongo ',
        airlineAgents: 'kau fakafofonga vakapuna',
        personalCall: 'uiuiʻi fakatautaha',
        phoneNumber: 'fika telefoni ',
        selectAirline: 'fili hoʻo fakafofonga vakapuna',
        airlineCalling: 'uiuiʻi ʻo e vakapuna',
        connect: 'fehokotaki',
    },
    otomi: {
        selectLanguage: 'Jaki ar mäte, seleccione ár hñä',
        backMainMenu: 'Volver ar menú principal jar hmä',
        helpSubtitle: "¿Honja podemos gi fa̲xki ga nu'bya?",
        healthWellness: 'Chequeo ar nzaki ne ar hño',
        denSurvey: 'Encuesta ar hontho ja ar cliente ar DEN',
        emergencyAccess: "'befi ya nthogi emergencia",
        multilingualCalling: 'Llamadas multilingües',
        freeCalling: 'Llamadas gratuitas',
        denLiveVideo: "Agente vídeo ho ga ku̲t'i DEN",
        multilingualChatRoom: 'Sala de chat multilingüe',
        denMarketplace: 'jár ta̲i ar aeropuerto DEN',
        qrCodeCreator: 'Jár ta̲i jar nxoge ximha',
        ttyTtd: "TTY yá TDD ar pa 'befi retransmisión vídeo pa ya jä'i sordas ne ko discapacidad auditiva",
        airlineAgents: "Agentes ar líneas ot'e nsani",
        personalCall: "Llamada jä'i",
        phoneNumber: "'bede ya teléfono",
        selectAirline: 'Seleccione ár agente aerolínea',
        airlineCalling: 'Llamadas aerolíneas',
        connect: 'Conectar',
    },
    karo: {
        selectLanguage: 'Pilihlah bahasandu',
        backMainMenu: 'Mulih ku Menu Utama',
        helpSubtitle: 'Kuga banci kami nampati kam sendah?',
        healthWellness: 'Periksa Kesehaten ras Kesejahteraan',
        denSurvey: 'Survey Layanan Pelanggan DEN',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Pendilo si erbage-bage bahasana',
        freeCalling: 'Panggilen Gratis',
        denLiveVideo: 'Agen Video Langsung DEN',
        multilingualChatRoom: 'Ruang Obrolen si erbage-bage bahasana',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD eme guna pelayanan estafet video man kalak si pasek ras si kurang megi',
        airlineAgents: 'Agen MaskapaiPenerbangan',
        personalCall: 'Panggilen Pribadi',
        phoneNumber: 'NomorTelepon',
        selectAirline: 'Pilih agen maskapai penerbanganndu',
        airlineCalling: 'Penggilen Maskapai Penerbangan',
        connect: 'Nyambung',
    },
    asm: {
        selectLanguage: 'অনুগ্ৰহ কৰি আপোনাৰ ভাষা নিৰ্বাচন কৰক',
        backMainMenu: 'মূল মেনুলৈ উভতি যাওক',
        helpSubtitle: 'আজি আমি আপোনাক কেনেকৈ সহায় কৰিব পাৰো?',
        healthWellness: 'স্বাস্থ্য আৰু সুস্থতা পৰীক্ষা',
        denSurvey: 'DEN গ্ৰাহক সেৱা জৰীপ',
        emergencyAccess: 'জৰুৰীকালীন প্ৰৱেশ সেৱা',
        multilingualCalling: 'বহুভাষিক কলিং',
        freeCalling: 'বিনামূলীয়া কলিং',
        denLiveVideo: "DEN লাইভ ভিডিঅ' এজেন্ট",
        multilingualChatRoom: 'বহুভাষিক আড্ডা কক্ষ',
        denMarketplace: 'DEN বিমানবন্দৰ বজাৰ',
        qrCodeCreator: 'গ্লোবেল মাৰ্কেটপ্লেচ',
        ttyTtd: "TTY/TDD বধিৰ আৰু শ্ৰৱণ ক্ষমতাহীন লোকৰ বাবে ভিডিঅ' ৰিলে সেৱাৰ বাবে",
        airlineAgents: 'এয়াৰলাইন এজেন্ট',
        personalCall: 'ব্যক্তিগত কল',
        phoneNumber: 'ফোন নম্বৰ',
        selectAirline: 'আপোনাৰ এয়াৰলাইন এজেন্ট নিৰ্বাচন কৰক',
        airlineCalling: 'এয়াৰলাইন কলিং',
        connect: 'সংযোগ',
    },
    ava: {
        selectLanguage: 'Пожалуйста, выберите свой язык',
        backMainMenu: 'Назад в Главное меню',
        helpSubtitle: 'Кин нижеца дуе жакъа кумек гьабизе бегьулеб?',
        healthWellness: 'Сахлъиялъул ва лъикӀлъиялъул халгьаби',
        denSurvey: 'ДЕН Клиентазул хъулухъалъул цӀех-рех',
        emergencyAccess: 'Хехаб кумекалъул хъулухъал',
        multilingualCalling: 'ГӀемерал мацӀазул ахӀи',
        freeCalling: 'Эркенаб ахӀи',
        denLiveVideo: 'ДЕН Живго Видео Агент',
        multilingualChatRoom: 'Многоязычный ЧатРум',
        denMarketplace: 'ДЕН аэропорталъул базар',
        qrCodeCreator: 'Глобалияб базар',
        ttyTtd: 'TTY/TDD ккола гӀинтӀамуларезе ва гӀинтӀамуларезе видеорелеялъул хъулухъал гьаризе',
        airlineAgents: 'Авиагенты',
        personalCall: 'Личный Звон',
        phoneNumber: 'Телефоналъул номер',
        selectAirline: 'ТӀаса бище дурго авиакомпаниялъул агент',
        airlineCalling: 'Авиакомпаниялъул ахӀи',
        connect: 'Бухьен',
    },
    alz: {
        selectLanguage: 'Kwany iyer dhok peri',
        backMainMenu: 'Dwogo i Main Menu',
        helpSubtitle: 'Wacopo konyi nenedi tin?',
        healthWellness: 'Nen yot kum man bedo maber',
        denSurvey: 'DEN Penji pa tic pa juruwil',
        emergencyAccess: 'Tic mi donjo i kare mi peko',
        multilingualCalling: 'Lwongo ku dhok matung tung',
        freeCalling: 'Lwongo ma cul epe',
        denLiveVideo: 'DENJatel wi vidio ma kwo',
        multilingualChatRoom: 'Kabedo mi wecu mi dhok mapol',
        denMarketplace: 'DEN Kabedo mi soko mi bar ndege',
        qrCodeCreator: "Ka cato wil mi ng'om zoo",
        ttyTtd: 'TTY/TDD utiye pi tic mi cwalo filim ni ju ma ith gi winjo ngo man ju ma giwinjo ngo',
        airlineAgents: 'Jurutic mi ndege',
        personalCall: 'Lwonga pa ngati',
        phoneNumber: 'Namba Simu',
        selectAirline: 'Yer jatel wi ndege peri',
        airlineCalling: 'Lwong pa ndege',
        connect: 'Ribu',
    },
    dogri: {
        selectLanguage: 'कृपया अपनी भाशा चुनो',
        backMainMenu: 'मुख्य मेनू पर वापस जाओ',
        helpSubtitle: 'अज्ज अस तुंदी मदद किस चाल्ली करी सकने आं?',
        healthWellness: 'स्वास्थ्य ते कल्याण जांच',
        denSurvey: 'डीईएन ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपातकालीन पहुंच सेवाएं',
        multilingualCalling: 'बहुभाषी कॉलिंग',
        freeCalling: 'मुफ्त कॉलिंग',
        denLiveVideo: 'डेन लाइव वीडियो एजेंट',
        multilingualChatRoom: 'बहुभाषी चैट रूम',
        denMarketplace: 'डेन हवाई अड्डा बाजार',
        qrCodeCreator: 'वैश्विक बाजार',
        ttyTtd: 'टीटीवाई/टीडीडी बहिरे ते सुनने च दिक्कत आह्ले लोकें आस्तै वीडियो रिले सेवाएं आस्तै ऐ',
        airlineAgents: 'एयरलाइन एजेंट',
        personalCall: 'निजी कॉल',
        phoneNumber: 'फोन नंबर',
        selectAirline: 'अपनी एयरलाइन एजेंट चुनो',
        airlineCalling: 'एयरलाइन कॉलिंग',
        connect: 'कनेक्ट',
    },
    dyula: {
        selectLanguage: 'I ka kan sugandi',
        backMainMenu: 'Kɔsegi ka taga menuba la',
        helpSubtitle: "An be se k'i dɛmɛ cogo di bi?",
        healthWellness: 'Kɛnɛya ni kɛnɛyako sɛgɛsɛgɛli',
        denSurvey: 'DEN ka baarakɛlaw ka sɛgɛsɛgɛli',
        emergencyAccess: 'Kɔrɔbɔli sɔrɔyɔrɔ baarakɛyɔrɔw',
        multilingualCalling: 'kan caaman weleli',
        freeCalling: 'Weeleli gwansan',
        denLiveVideo: 'DEN videwo ɲɛnama baarakɛla',
        multilingualChatRoom: 'kan caaman barokɛyɔrɔ',
        denMarketplace: 'DEN awiyɔnso sugu',
        qrCodeCreator: 'Duniɲa sugu',
        ttyTtd: 'TTY/TDD ye videyo yɛlɛmani baara ye tulogwerenw ni tulogwerenw kama',
        airlineAgents: 'Aviyɔn ka baarakɛlaw',
        personalCall: 'Mɔgɔ yɛrɛ ka weele',
        phoneNumber: 'Telefɔni nimɔrɔ',
        selectAirline: 'I ka awiyɔn bolibaga sugandi',
        airlineCalling: 'Aviyɔnw weele',
        connect: 'ɲɔgɔn minɛ',
    },
    ikt: {
        selectLanguage: 'Pilugu uqauhit',
        BackMainMenu: 'Utiqlutit talvunga Main Menu-mut',
        helpSubtitle: 'Qanuqtut ikayurniaqqita ublumi?',
        healthWellness: 'Aanniaqtailinikkut Inuuhiringnaqtumiklu Naunaiyarniq',
        denSurvey: 'DEN Akiliqtuiyup Atuqtakhanik Naunaiyaidjut',
        emergencyAccess: 'Amigaqhuutiqaqqan Pigiaqaqtunik Ikayuutikharnik',
        multilingualCalling: 'Amihunik uqajuktuq',
        freeCalling: 'Tamaiqat Hivayaqtut',
        denLiveVideo: "DEN Qun'ngiararhamik Havakti",
        multilingualChatRoom: 'Amihunik Uqauhiqaqtunik Katimavik',
        denMarketplace: 'DEN Milviit Niuvrutikhanik',
        qrCodeCreator: 'Nunaryuami Niuvvaaviit',
        ttyTtd: 'TTY/TDD piksasuuliuqhimayuq tuhaqtitiyaangat ikayuutikharnik talvuuna tuhaayaangat tuhaayaangatlu akuktauvakhimayuq',
        airlineAgents: 'Tingmit Havaktit',
        personalCall: 'Nanminik Hivayaqtut',
        phoneNumber: 'Hivayautaa nampaa',
        selectAirline: 'Tingmilugit tingmitit',
        airlineCalling: 'Tingmit Hivayaqtut',
        connect: 'Katitirlugit',
    },
    faroese: {
        selectLanguage: 'Vinarliga vel títt mál',
        backMainMenu: 'Aftur til Høvuðsvalmynd',
        helpSubtitle: 'Hvussu kunnu vit hjálpa tær í dag?',
        healthWellness: 'Heilsu- og trivnaðarkanning',
        denSurvey: 'DEN kundatænastukanning',
        emergencyAccess: 'Neyðatgongdartænastur',
        multilingualCalling: 'Fleirmælt Kall',
        freeCalling: 'ÓkeypisRinging',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Fleirmælt Kjakrúm',
        denMarketplace: 'DEN flogvøllurin Marknaður',
        qrCodeCreator: 'Alheimsmarknaður',
        ttyTtd: 'TTY/TDD er til videostafetttænastur til deyv og hoyribrekað',
        airlineAgents: 'Flogfelagsumboð',
        personalCall: 'Persónligt Kall',
        phoneNumber: 'Telefonnummar',
        selectAirline: 'Vel tín flogfelagsumboðsmann',
        airlineCalling: 'Flogfelagskalling',
        connect: 'samband',
    },
    toba: {
        selectLanguage: 'Pillit ma bahasamuna',
        backMainMenu: 'Mulak tu MenuUtama',
        helpSubtitle: 'Boha do hami mangurupi hamu sadarion?',
        healthWellness: 'Pemeriksaan Kesehatan dan Kesejahteraan',
        denSurvey: 'Survei Layanan Pelanggan DEN',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Panggilan Marragam Bahasa',
        freeCalling: 'Panggilan Gratis',
        denLiveVideo: 'Agen Video Langsung DEN',
        multilingualChatRoom: 'Ruang Obrolan Marragam Bahasa',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Dunia',
        ttyTtd: 'TTY/TDD ima tu pelayanan estafet video tu angka na ngungu dohot na so marbinege',
        airlineAgents: 'AgenMaskapaiPenerbangan',
        personalCall: 'PanggilanPribadi',
        phoneNumber: 'NomorTelepon',
        selectAirline: 'Pillit ma agen maskapai penerbanganmu',
        airlineCalling: 'Panggilan Maskapai Penerbangan',
        connect: 'Mardomu',
    },
    kikongo: {
        selectLanguage: 'Pardo pona ndinga na nge',
        backMainMenu: 'Kuvutuka na MenuPrincipal',
        helpSubtitle: 'Inki mutindu beto lenda sadisa nge bubu yai?',
        healthWellness: 'Kutala santé na bien-être',
        denSurvey: 'Nsosa ya kisalu ya bakiliya ya DEN',
        emergencyAccess: 'Bisalu ya Kukota na Nswalu',
        multilingualCalling: 'Kubinga na bandinga mingi',
        freeCalling: 'Kubinga ya ofele',
        denLiveVideo: 'Nsadisi ya Video ya Kuzinga ya DEN',
        multilingualChatRoom: 'Salle ya Kusolula na Bandinga Mingi',
        denMarketplace: 'Zandu ya Aeroport ya DEN',
        qrCodeCreator: 'Zandu ya Ntoto ya Mvimba',
        ttyTtd: 'TTY/TDD kele sambu na bisalu ya relais ya video sambu na bafwa-makutu mpi bantu yina ke waka ve mbote',
        airlineAgents: 'Bantu ya kompani ya avio',
        personalCall: 'Kubinga ya muntu yandi mosi',
        phoneNumber: 'Nimero ya Telefone',
        selectAirline: 'Pona nsadi ya avio na nge',
        airlineCalling: 'Kubinga ya avio',
        connect: 'Kukangama',
    },
    oromo: {
        selectLanguage: 'Afaan kee filadhu',
        backMainMenu: "Gara Menuu Guddaatti Deebi'i",
        helpSubtitle: "Har'a akkamitti si gargaaruu dandeenya?",
        healthWellness: "Fayyaa fi Fayyaa Sakatta'iinsa",
        denSurvey: 'Qorannoo Tajaajila Maamiltootaa DEN',
        emergencyAccess: 'Tajaajila Dhaqqabummaa Hatattamaa',
        multilingualCalling: 'Waamicha afaanota hedduu',
        freeCalling: 'Bilbila Bilisaa',
        denLiveVideo: 'Ejensii Viidiyoo Kallattiin DEN',
        multilingualChatRoom: 'Kutaa Marii Afaanota Hedduu',
        denMarketplace: 'Iddoo Gabaa Buufata Xiyyaaraa DEN',
        qrCodeCreator: 'Iddoo Gabaa Addunyaa',
        ttyTtd: "TTY/TDD tajaajila viidiyoo dabarsuu namoota gurri isaanii hin dhageenyee fi dhaga'uu dadhabaniif",
        airlineAgents: 'Ejentoota Daandii Xiyyaaraa',
        personalCall: 'Waamicha Dhuunfaa',
        phoneNumber: 'Lakkoofsa Bilbilaa',
        selectAirline: 'Ejensii daandiin xiyyaaraa kee filadhu',
        airlineCalling: 'Bilbila Daandii Xiyyaaraa',
        connect: 'Walqabsiisuu',
    },
    hne: {
        selectLanguage: 'कृपया अपन भाषा के चयन करें',
        backMainMenu: 'मुख्य मेनू म वापस',
        helpSubtitle: 'आज हम तुँहर मदद कइसे कर सकत हावन?',
        healthWellness: 'हेल्थ एंड वेलनेस चेक',
        denSurvey: 'डेन ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपातकालीन पहुंच सेवाएं',
        multilingualCalling: 'बहुभाषी कॉलिंग',
        freeCalling: 'फ्री कॉलिंग',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'बहुभाषी चैट रूम',
        denMarketplace: 'डेन लाइव वीडियो एजेंट',
        qrCodeCreator: 'ग्लोबल मार्केटप्लेस',
        ttyTtd: 'TTY/TDD बधिर अउ श्रवण बाधित मन के खातिर वीडियो रिले सेवा के खातिर हावय',
        airlineAgents: 'एयरलाइन एजेंट',
        personalCall: 'पर्सनल कॉल',
        phoneNumber: 'फोन नंबर',
        selectAirline: 'अपन एयरलाइन एजेंट के चयन करें',
        airlineCalling: 'एयरलाइन कॉलिंग',
        connect: 'कनेक्ट',
    },
    buryat: {
        selectLanguage: 'Хэлээ шэлэжэ абагты',
        backMainMenu: 'Гол меню руу бусаха',
        helpSubtitle: 'Мүнөөдэр бидэ шамда яажа туһалхабибди?',
        healthWellness: 'Элүүрые ба элүүрые шалгаха',
        denSurvey: 'DEN Хэрэглэгшын албанай асуудалнууд',
        emergencyAccess: 'Яаралтай нэбтэрэлгын албанууд',
        multilingualCalling: 'Олон хэлэн дээрэ дуудалга',
        freeCalling: 'түлбэригүй хонходохо',
        denLiveVideo: 'ДЭН сэхэ видео агент',
        multilingualChatRoom: 'Олон хэлэтэй чат',
        denMarketplace: 'DEN Аэропортын Маркетплейс',
        qrCodeCreator: 'Дэлхэйн заха зээл',
        ttyTtd: 'TTY/TDD хадаа дүлии ба сонсохо бэрхэшээлтэй хүнүүдтэ видео дамжуулгын үйлэшэлгэ',
        airlineAgents: 'Авиакомпаниин агентнууд',
        personalCall: 'Хүнэй хонходолго',
        phoneNumber: 'Утаһанай дугаар',
        selectAirline: 'Авиакомпаниингаа агент шэлэхэ',
        airlineCalling: 'Авиакомпаниин хонходолго',
        connect: 'Холбоо',
    },
    venetian: {
        selectLanguage: 'Selezionate la vostra lingua',
        backMainMenu: 'Tornar al menù prinsipałe',
        helpSubtitle: 'Come podemo jutarte ancùo?',
        healthWellness: 'Controło de Sałute e Benessere',
        denSurvey: 'Sondajo del Servisio Cliente del DEN',
        emergencyAccess: 'Servizi de Aceso a Emergenza',
        multilingualCalling: 'Ciamada multiłenguałe',
        freeCalling: 'Ciamada gratuita',
        denLiveVideo: 'Agente de video dal vivo del DEN',
        multilingualChatRoom: 'Cat Room Multilingue',
        denMarketplace: 'Mercà de l’aeroporto DEN',
        qrCodeCreator: 'Marcà globałe',
        ttyTtd: 'TTY/TDD el xe pa i servisi de videorelay pa i sordi e co problemi de udito',
        airlineAgents: 'Agenti de łe compagnie aeree',
        personalCall: 'Chiamata Personale',
        phoneNumber: 'Nùmaro de telefono',
        selectAirline: 'Selesiona el vostro agente de ła conpagnia aerea',
        airlineCalling: 'conpagnia aereaCiama',
        connect: 'Colega',
    },
    pam: {
        selectLanguage: 'Pakipili me ing kekang amanu',
        backMainMenu: 'Balik keng Main Menu',
        helpSubtitle: 'Makananu dakang asaupan ngeni?',
        healthWellness: 'Pagsuri king Kalusugan at Kakayapan',
        denSurvey: 'SURBAYAN ning DEN para kareng Serbisyu ning Kustomer',
        emergencyAccess: 'Serbisyong Pang-emergency',
        multilingualCalling: 'Maranun a Pagsalita',
        freeCalling: 'Libreng Pamaus',
        denLiveVideo: 'DEN Nanuang Video Agente',
        multilingualChatRoom: 'Makapangyarihang Usapan a Manyaman',
        denMarketplace: 'den Pamilihan',
        qrCodeCreator: 'Pamaglalakwan ning Mundu',
        ttyTtd: 'Ing TTY/TDD para ya kareng serbisyung video relay para kareng pipi ampo kareng e makaramdam',
        airlineAgents: 'Mga Ahente ning Airline',
        personalCall: 'Personal a Tawag',
        phoneNumber: 'Numero ning Pamag-ugnayan',
        selectAirline: 'Pilinan me ing kekang ahenti ning eroplanu',
        airlineCalling: 'Mitlubu ning Airline',
        connect: 'Magsuglung',
    },
    'southern sotho': {
        selectLanguage: 'Ka kopo, kgetha puo ya hao',
        backMainMenu: 'Khutlela ho Main Menu',
        helpSubtitle: 'Re ka o thusa jwang kajeno?',
        healthWellness: 'Tlhahlobo ea Bophelo bo Botle le Bophelo bo Botle',
        denSurvey: 'Phuputso ea Tšebeletso ea Bareki ea Den',
        emergencyAccess: 'Litšebeletso tsa phihlello ea Tšohanyetso',
        multilingualCalling: 'Pitso ka dipuo tse ngata',
        freeCalling: 'Ho Letsa Mahala',
        denLiveVideo: 'Moemeli oa Video ea Phelang oa DEN',
        multilingualChatRoom: 'Mullingual Chat Room',
        denMarketplace: 'Mmaraka oa Boema-fofane ba Den',
        qrCodeCreator: 'Maraka oa Lefatše',
        ttyTtd: 'TTY/TDD ke ea litšebeletso tsa phepelo ea lifitio bakeng sa batho ba sa utloeng litsebeng le ba sa utloeng litsebeng',
        airlineAgents: 'Baemeli ba Lifofane',
        personalCall: 'Pitso ea hau',
        phoneNumber: 'Nomoro ea Mohala',
        selectAirline: 'Khetha moemeli oa hau oa sefofane',
        airlineCalling: 'Ho letsa ka sefofane',
        connect: 'hokela',
    },
    tumbuka: {
        selectLanguage: 'Chonde sankhani chiyowoyero chinu',
        backMainMenu: 'Kuwelera ku Menyu Yikuru',
        helpSubtitle: 'Kasi tingakovwira wuli muhanya uno?',
        healthWellness: 'Umoyo na Umoyo Umoyo Cheke',
        denSurvey: 'DEN Kafukufuku wa Uteŵeti wa Makasitomala',
        emergencyAccess: 'Kufika Kwamwaluŵiro kwa Ntchito',
        multilingualCalling: 'Kuchema kwa viyowoyero vinandi',
        freeCalling: 'Kuyimbira kwaulere',
        denLiveVideo: 'DEN Live Vidiyo Wogwira Ntchito',
        multilingualChatRoom: 'Chipinda chakuchezgerapo cha viyowoyero vinandi',
        denMarketplace: 'Msika wa ndege ya DEN',
        qrCodeCreator: 'Msika wa pa charu chose',
        ttyTtd: 'TTY/TDD ni vyakupelekera mavidiyo gha ŵanthu ŵakumangwa makutu na awo ŵakupulika makora yayi',
        airlineAgents: 'Ŵanthu ŵa pa ndege',
        personalCall: 'Kuchema kwa munthu payekha',
        phoneNumber: 'Nambala ya foni',
        selectAirline: 'Sankhani wovwiri winu wa ndege',
        airlineCalling: 'Kuyimbira Ndege',
        connect: 'Lumikiza',
    },
    chechen: {
        selectLanguage: 'Дехар ду хьайн мотт харжа',
        backMainMenu: 'Коьртачу меню чу юхаверза',
        helpSubtitle: 'Тахана оха муха гӀо дийр ду хьуна?',
        healthWellness: 'Могашалла а, дика хилар а толлуш',
        denSurvey: 'DEN клиентийн гӀуллакхдаран таллам',
        emergencyAccess: 'Сихха кхачояран сервисаш',
        multilingualCalling: 'Дуккха а меттанашкахь кхайкхам',
        freeCalling: 'Маьрша Кхайкхам',
        denLiveVideo: 'ДЕН Лайв Видео Агент',
        multilingualChatRoom: 'Дукха меттанаш долу чат',
        denMarketplace: 'ДЕН аэропортан базар',
        qrCodeCreator: 'Дуьненаюкъара базар',
        ttyTtd: 'TTY/TDD - лерса доцчу нахана а, хезаш боцучарна а видеорелейн сервисашна лерина ю',
        airlineAgents: 'Авиагенташ',
        personalCall: 'Личный Кхайкхам',
        phoneNumber: 'Телефонан номер',
        selectAirline: 'Хьайн авиакомпанин агент харжа',
        airlineCalling: 'Авиакомпанин зӀе',
        connect: 'ЗӀе',
    },
    fon: {
        selectLanguage: 'Kɛnklɛn bo sɔ́ gbè towe',
        BackMenuMain: 'Lɛkɔ yi Mɛnu Taji ɔ ji',
        helpSubtitle: 'Nɛ mi ka sixu do alɔ nu mi gbɔn egbe?',
        healthWellness: 'Lanmɛ ɖagbe kpodo lanmɛ na nɔ ganji kpo',
        denSurvey: 'DEN sín azɔ̌ e è nɔ wà nú axisinɔ lɛ é sín nǔkanbyɔ',
        emergencyAccess: 'Azɔwiwa Ajijimɛ Tɔn',
        multilingualCalling: 'Ylɔylɔ gbè gègě tɔn',
        freeCalling: 'Ylɔylɔ vɔ̌nu',
        denLiveVideo: 'DEN sín Azɔ̌watɔ́ Video Tɔn',
        multilingualChatRoom: 'Xóɖɔɖókpɔ́ Gbè Gègě Tɔn',
        denMarketplace: "DEN Jɔmɛhunj'ayitɛn Axi",
        qrCodeCreator: 'Axi Gbɛ̀ ɔ Bĭ Tɔn',
        ttyTtd: 'TTY/TDD ɔ, video jlaɖó sín azɔ̌ lɛ wɛ nú tókúnɔ lɛ kpo mɛ e ma nɔ sè nǔ ganji ǎ lɛ é kpo',
        airlineAgents: "Agents de l'avion",
        personalCall: 'Ylɔ mɛɖesunɔ tɔn',
        phoneNumber: 'Alokan Numɛro',
        selectAirline: 'Sɔ́ jɔmɛhunkuntɔ́ towe',
        airlineCalling: 'Avion Appel',
        connect: 'Kan',
    },
    lus: {
        selectLanguage: 'Khawngaihin i tawng thlang rawh',
        backMainMenu: 'Main Menu-ah kir leh rawh',
        helpSubtitle: 'Vawiin hian engtin nge kan puih theih ang che?',
        healthWellness: 'Hriselna leh hriselna endik',
        denSurvey: 'DEN Customer Service chungchanga zirchianna',
        emergencyAccess: 'Emergency-a hman theihna tur rawngbawlna',
        multilingualCalling: 'Ṭawng tam tak hmanga koh',
        freeCalling: 'A thlawna biak theihna',
        denLiveVideo: 'tih a ni a, a hlawhtling hle',
        multilingualChatRoom: 'Ṭawng hrang hrang hmanga inbiakna hmun',
        denMarketplace: 'DEN Airport-a sumdawnna hmunpui',
        qrCodeCreator: 'Khawvel pum huap Marketplace',
        ttyTtd: 'TTY/TDD hi bengngawng leh hriatna lama harsatna nei te tan video relay service atan a ni',
        airlineAgents: 'Thlawhna lama thawktute',
        personalCall: 'Mimal Call',
        phoneNumber: 'tih hi a ni',
        selectAirline: 'I airline agent thlang rawh',
        airlineCalling: 'Thlawhna lam atanga koh',
        connect: 'Inzawmna',
    },
    Chamorro: {
        selectLanguage: "På'go, ayek i fino'-mu",
        backMainMenu: 'Tåtte gi i Main Menu',
        helpSubtitle: "Taimanu na siña in ayuda hao på'go na ha'åni?",
        healthWellness: 'salutMinaolek',
        denSurvey: 'Survey put i Setbision Kustombren DEN',
        emergencyAccess: 'Setbisio Siha para i Inafekta gi i Emerensiha',
        multilingualCalling: 'Inagang gi meggai na lengguåhi',
        freeCalling: 'Libre na Inagang',
        denLiveVideo: "Ahenten Video Li'e' DEN",
        multilingualChatRoom: "Chat Room ni' meggai na lengguåhi",
        denMarketplace: 'Merkadu gi i Aeropuerto DEN',
        qrCodeCreator: 'Merkadu gi enteru mundo',
        ttyTtd: "TTY/TDD para i setbisio para i manma'å'ñao yan i manma'å'ñao",
        airlineAgents: 'ahenten aire',
        personalCall: "Inagang På'go",
        phoneNumber: 'Numeron Tilifon',
        selectAirline: 'Pili i ahenten-mu gi i airline',
        airlineCalling: 'Inagang gi i Airline',
        connect: 'na chettun',
    },
    rundi: {
        selectLanguage: 'Usabwe guhitamwo ururimi rwawe',
        backMainMenu: 'Subira ku menyu nyamukuru',
        helpSubtitle: 'Twogufasha gute uno musi?',
        healthWellness: "Isuzuma ry'ubuzima n'imibereho myiza",
        denSurvey: "Itohoza ry'abaguzi ba DEN",
        emergencyAccess: "Ibikorwa vy'Ivyihutirwa",
        multilingualCalling: 'Guhamagara mu ndimi nyinshi',
        freeCalling: 'Guhamagara ku buntu',
        denLiveVidewo: 'DEN Umukozi wa Videwo',
        multilingualChatRoom: "Icumba c'Ikiganiro c'indimi nyinshi",
        denMarketplace: "Isoko ry'Ikibuga c'Indege ca DEN",
        qrCodeCreator: "Isoko ry'isi yose",
        ttyTtd: "TTY/TDD ni ibikorwa vyo gutanga amasanamu ku bipfamatwi n'abafise ubumuga bwo kutumva",
        airlineAgents: "Abakozi b'indege",
        personalCall: "Ihamagara ry'umuntu ku giti ciwe",
        phoneNumber: 'Inomero ya Telefone',
        selectAirline: "Hitamwo umukozi wawe w'indege",
        airlineCalling: 'Indege Guhamagara',
        connect: 'Huza',
    },
    chuukese: {
        selectLanguage: 'Kose mochen kopwe finata om fos',
        backMainMenu: 'Niwin ngeni ewe Menu',
        helpSubtitle: 'Ifa usun am aupwe tongeni anisuk ikenai?',
        healthWellness: 'Chekin pekin safei me pekin safei',
        denSurvey: 'Sopai ren angangen chon kamo DEN',
        emergencyAccess: 'Ekkewe Angangen Esewesen Angang',
        multilingualCalling: 'Kokkot non chommong fos',
        freeCalling: 'Kokkot mi ngaw',
        denLiveVideo: 'Ewe chon angangen DEN mi Manau',
        multilingualChatRoom: 'Rumwen Chon Fos',
        denMarketplace: 'Ewe nenien amömö non ewe nenien amömö DEN',
        qrCodeCreator: 'Ewe nenien amömö won unusen fonufan',
        ttyTtd: 'TTY/TDD a fis ren angangen video relay ren chon selingepung me chon rongorong',
        airlineAgents: 'Ekkewe chon angangen sepenin',
        personalCall: 'Kokkotun pwisin',
        phoneNumber: 'Nampaan Fon',
        selectAirline: 'Fili noumw we chon angangen sepenin',
        airlineCalling: 'Kokkotun sepenin',
        connect: 'Konetin',
    },
    tuvan: {
        selectLanguage: 'Дылыңарны шилип алыңар',
        backMainMenu: 'Төп менюже эглип келир',
        helpSubtitle: 'Бөгүн силерге канчаар дузалап болур бис?',
        healthWellness: 'Кадыкшыл болгаш кадыкшыл хыналдазы',
        denSurvey: 'DEN-ниң клиент албан чериниң айтырыглары',
        emergencyAccess: 'Дүрген дуза албан черлери',
        multilingualCalling: 'Хөй дылдыг кыйгырыг',
        freeCalling: 'Халас кыйгырыг',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Хөй дылдыг чат',
        denMarketplace: 'ДЕН Аэропорт Маркетплейс',
        qrCodeCreator: 'Делегей чергелиг рынок',
        ttyTtd: 'TTY/TDD дээрге дүлей болгаш дыңнаары кошкак улуска видеореле албан черлери-дир',
        airlineAgents: 'Авиакомпанияның агенттери',
        personalCall: 'Хууда кыйгырыг',
        phoneNumber: 'Телефон дугаары',
        selectAirline: 'Авиакомпанияңарның агентизин шилип алыңар',
        airlineCalling: 'Авиакомпания долгап',
        connect: 'Кожуптар',
    },
    Kituba: {
        selectLanguage: 'Pardon pona ndinga na nge',
        backMainMenu: 'Kuvutuka na Menu ya Ntete',
        helpSubtitle: 'Inki mutindu beto lenda sadisa nge bubu yai?',
        healthWellness: 'Kutala santé na bien-être',
        denSurvey: 'Ankete ya kisalu ya bakiliya ya DEN',
        emergencyAccess: 'Bisalu ya Kukota na Nswalu',
        multilingualCalling: 'Kubinga na bandinga mingi',
        freeCalling: 'Kubinga ya ofele',
        denLiveVideo: 'Nsadisi ya Video ya Kuzinga ya DEN',
        multilingualChatRoom: 'Kisika ya Kusolula ya Bandinga Mingi',
        denMarketplace: 'Zandu ya Aeroport ya DEN',
        qrCodeCreator: 'Zandu ya Inza ya Mvimba',
        ttyTtd: 'TTY/TDD kele sambu na bisalu ya relais ya video sambu na bifwa-makutu mpi bantu yina ke waka ve mbote',
        airlineAgents: 'Bantu ya kompani ya avio',
        personalCall: 'Kubinga ya muntu yandi mosi',
        phoneNumber: 'Nimero ya Telefone',
        selectAirline: 'Pona nsadi ya kompani ya avio na nge',
        airlineCalling: 'Kubinga ya avio',
        connect: 'kukangama',
    },
    baluchi: {
        selectLanguage: 'وتی زبان ءَ گچین کن',
        backMainMenu: 'مین مینو ءِ نیمگ ءَ پدا',
        helpSubtitle: 'مرچی ما چوں تئی کمک ءَ کنیں؟',
        healthWellness: 'صحت ءُ وشحالی ءِ چیک',
        denSurvey: 'ڈن کسٹمر سروس سروے',
        emergencyAccess: 'ہنگامی رسائی ءِ خدمات',
        multilingualCalling: 'کثیر زبانی کالنگ',
        freeCalling: 'آزادیں کالنگ',
        denLiveVideo: 'ڈن لائیو ویڈیو ایجنٹ',
        multilingualChatRoom: 'کثیر زبانی گپ روم',
        denMarketplace: 'ڈین بالی پٹ ءِ بازار',
        qrCodeCreator: 'گلوبل مارکیٹ پلیس',
        ttyTtd: 'TTY/TDD پہ بہر ءُ بانگ ءِ نادراہانی واستہ ویڈیو ریلے سروسز اِنت',
        airlineAgents: 'ایئر لائن ایجنٹس',
        personalCall: 'ذاتی کال',
        phoneNumber: 'فون نمبر',
        selectAirline: 'وتی ایئر لائن ءِ ایجنٹ ءَ گچین کن',
        airlineCalling: 'ایئر لائن کالنگ',
        connect: 'جڑ',
    },
    zapotec: {
        selectLanguage: 'Biyubi diidxaʼ ni riníʼ íqueluʼ ',
        backMainMenu: 'biguetaʼ lu menú principal',
        helpSubtitle: 'Ximodo zanda gacanedu lii yanna yaʼ.',
        healthWellness: 'Cheque de salud ne bienestar',
        denSurvey: 'Encuesta de serviciu al cliente stiʼ DEN',
        emergencyAccess: 'Servicios de acceso a emergencia',
        multilingualCalling: 'Llamada multilingüe',
        freeCalling: 'Llamada gratuita',
        denLiveVideo: 'DEN Agente de vídeo en vivo',
        multilingualChatRoom: 'Sala de chat multilingüe',
        denMarketplace: 'DEN Mercado stiʼ aeropuerto',
        qrCodeCreator: 'Mercado mundial',
        ttyTtd: 'TTY/TDD nga para serviciu de relé de vídeo para ca binni ni qué runadiaga ne cani qué runadiaga ',
        airlineAgents: 'Ca agente stiʼ ca aerolínea',
        personalCall: 'Llamada personal',
        phoneNumber: 'Número de teléfono',
        selectAirline: 'Biyubi agente stiʼ compañía de avión stiluʼ ',
        airlineCalling: 'Llamada stiʼ compañía de avión',
        connect: 'runi conectar',
    },
    fulfulde: {
        selectLanguage: 'Wota suɓu ɗemngal maaɗa',
        backMainMenu: 'Wayligo to Main Menu',
        helpSubtitle: "Bana noy min mballinta'on hande?",
        healthWellness: ' Njaɓɓol Jam ɓaandu be Ɓamtaare',
        denSurvey: 'DEN Jaangirde',
        emergencyAccess: 'jaawal ',
        multilingualCalling: 'E Ɗemɗe Ɗuuɗɗe noddugo',
        freeCalling: 'noddugo bilaa ceede',
        denLiveVideo: 'wonde vidoo',
        multilingualChatRoom: 'Ɗemngal Ɗemɗe Ɗiɗi Chat Room',
        denMarketplace: 'Lappol Jaaynde',
        qrCodeCreator: 'Jaaynde Duniyaaru',
        ttyTtd: 'TTY/TDD don ngam kujeji jaɓɓol vidio ngam ɓe nduula be nanugo seɗɗa',
        airlineAgents: 'jaahangal agents',
        personalCall: 'je yimɓe noddugo',
        phoneNumber: 'namba telifoon',
        selectAirline: 'suɓa jaahangal',
        airlineCalling: 'jaahangal noddugo',
        connect: 'hawti',
    },
    konkani: {
        selectLanguage: 'कृपा करून तुमची भास निवडात',
        backMainMenu: 'मुखेल मेनूंत परत वचचें',
        helpSubtitle: 'आयज आमी तुमकां कशी मजत करूं येता?',
        healthWellness: 'आरोग्य आनी निरोगीपण तपासणी',
        denSurvey: 'DEN गिरायक सेवा सर्वेक्षण',
        emergencyAccess: 'आणीबाणी प्रवेश सेवा',
        multilingualCalling: 'बहुभाषी कॉलिंग',
        freeCalling: 'मुक्त कॉलिंग',
        denLiveVideo: 'डेन लायव्ह व्हिडियो एजंट',
        multilingualChatRoom: 'बहुभाषी गप्पा कक्ष',
        denMarketplace: 'DEN विमानतळ बाजारपेठ',
        qrCodeCreator: 'संवसारीक बाजारपेठ',
        ttyTtd: 'TTY/TDD बहिर्गो आनी आयकुपाक अपंगत्व आशिल्ल्यां खातीर व्हिडियो रिले सेवां खातीर आसा',
        airlineAgents: 'विमान एजंट',
        personalCall: 'वैयक्तीक कॉल',
        phoneNumber: 'फोन नंबर',
        selectAirline: 'तुमचो विमान एजंट निवडात',
        airlineCalling: 'विमान कॉलिंग',
        connect: 'जोडप',
    },
    makassar: {
        selectLanguage: 'Pilei bahasanu',
        backMainMenu: 'ammotere mange ri menu utama',
        helpSubtitle: 'Antekamma bateta akkulle ambantuko anne alloa?',
        healthWellness: "Pa'paressa kasehatang siagang kasehatang",
        denSurvey: 'DEN Survei Layanan Pelanggan',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: "Pa'bicaraang multibahasa",
        freeCalling: "Pa'bicaraang gratis",
        denLiveVideo: 'DEN Agen Video Langsung',
        multilingualChatRoom: 'Ruang Obrolan Multibahasa',
        denMarketplace: 'DEN Pasar Bandara',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD iami antu layanan relay video untuk tau tuli siagang cacat allangngere',
        airlineAgents: 'Agen Maskapai',
        personalCall: 'Panggilan pribadi',
        phoneNumber: 'Nomor Telepon',
        selectAirline: 'Pilei agen maskapai penerbangannu',
        airlineCalling: 'Panggilan maskapai penerbangan',
        connect: 'Sambungang',
    },
    bhojpuri: {
        selectLanguage: 'कृपया आपन भाषा चुनीं',
        backMainMenu: 'मुख्य मेनू पर वापस जाए खातिर',
        helpSubtitle: 'आज हमनी के रउआ के कइसे मदद कर सकेनी जा?',
        healthWellness: 'स्वास्थ्य आ कल्याण के जांच',
        denSurvey: 'डीईएन ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपातकालीन पहुँच सेवा',
        multilingualCalling: 'बहुभाषी कॉलिंग',
        freeCalling: 'मुफ्त कॉलिंग',
        denLiveVideo: 'डेन लाइव वीडियो एजेंट',
        multilingualChatRoom: 'बहुभाषी चैट रूम',
        denMarketplace: 'डेन हवाई अड्डा बाजार',
        qrCodeCreator: 'वैश्विक बाजार',
        ttyTtd: 'TTY/TDD बहरा आ सुनवाई में बाधा वाला लोग खातिर वीडियो रिले सेवा खातिर बा',
        airlineAgents: 'एयरलाइन एजेंट',
        personalCall: 'पर्सनल कॉल',
        phoneNumber: 'फोन नंबर',
        selectAirline: 'आपन एयरलाइन एजेंट सेलेक्ट करीं',
        airlineCalling: 'एयरलाइन कॉलिंग',
        connect: 'कनेक्ट कइल',
    },
    cnh: {
        selectLanguage: 'Na holh kha thim',
        backMainMenu: 'Main Menu ah kir tthan',
        helpSubtitle: 'Nihin ah zeitindah kan in bawmh khawh lai?',
        healthWellness: 'Ngandamnak le Ngandamnak Check',
        denSurvey: 'DEN Chawlettu Riantuannak Hlathlainak',
        emergencyAccess: 'Emergency Hmuh khawhnak Riantuannak',
        multilingualCalling: 'Multilingual Calling',
        freeCalling: 'Man loin Chawnh',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Holh phun tampi in biaruahnak khaan',
        denMarketplace: 'DEN Vanlawngbual Marketplace',
        qrCodeCreator: 'Vawleicung Chawlehnak',
        ttyTtd: 'TTY/TDD cu hnachet le hnachetmi hna caah video relay riantuannak caah a si',
        airlineAgents: 'Vanlawng riantuantu',
        personalCall: 'Pumpak auhnak',
        phoneNumber: 'Phone Nambar',
        selectAirline: 'Na vanlawng riantuantu kha thim',
        airlineCalling: 'Vanlawng Chawnhnak',
        connect: 'Pehtonh',
    },
    ossetian: {
        selectLanguage: 'Дæ хорзæхæй равзар дæ æвзаг',
        backMainMenu: 'Фæстæмæ сæйраг менюмæ',
        helpSubtitle: 'Куыд дын баххуыс кæндзыстæм абон?',
        healthWellness: 'Æнæниздзинад æмæ æнæниздзинады бæрæггæнæн',
        denSurvey: 'DEN-ы клиентты лæггæдты æрфарст',
        emergencyAccess: 'Тагъд æххуысы лæггæдтæ',
        multilingualCalling: 'Мультилингвальный звонок',
        freeCalling: 'Æнæаргъ æрбадзурын',
        denLiveVideo: 'DEN Комкоммæ видеоагент',
        multilingualChatRoom: 'Мультилингвальный чат',
        denMarketplace: 'ДЕН Аэропорт Маркетплейс',
        qrCodeCreator: 'Глобалон базарад',
        ttyTtd: 'TTY/TDD у хъусæй хъусæг æмæ æвзæр хъусæг адæмæн видеорелейон лæггæдтæн',
        airlineAgents: 'Авиакомпанийы агенттæ',
        personalCall: 'Персональный звонок',
        phoneNumber: 'Телефоны номыр',
        selectAirline: 'Равзарут уæ авиакомпанийы агент',
        airlineCalling: 'Авиакомпанийы æрбадзурын',
        connect: 'Баиу кæнын',
    },
    setswana: {
        selectLanguage: 'Tsweetswee tlhopha puo ya gago.',
        backMainMenu: 'Leboko Menu a Go Go ',
        helpSubtitle: 'Re ka go thusa jang gompieno?',
        healthWellness: 'Health Bonne bonne',
        denSurvey: 'Tlhatlhobo ea Bareki',
        emergencyAccess: 'Ditirelo tsa phitlhelelo ya tshoganyetso',
        multilingualCalling: 'Dipuo Pitso',
        freeCalling: 'Go Goa Gone Free Call',
        denLiveVideo: 'O O Jaane Jaana Video Agent',
        multilingualChatRoom: 'Rooms To Go Dining Room',
        denMarketplace: ' DEN Lefelo la mmaraka',
        qrCodeCreator: 'Global Marketplace',
        ttyTtd: 'TTY/TDD Ke kopa thuso ya di-audio tsa di-audio le di-audio tse di sa rerilweng.',
        airlineAgents: 'Mmabatho Airlines Agents',
        personalCall: 'Fone Bone',
        phoneNumber: 'Dinomoro tsa mogala',
        selectAirline: 'Tlhopha moemedi wa gago wa difofane',
        airlineCalling: 'Airlines Go Goa Gone',
        connect: 'Go GoA Gone Connect',
    },
    kanuri: {
        selectLanguage: 'Təlamnəm karne',
        backMainMenu: 'Waltə Menu Kurabero',
        helpSubtitle: 'Jilifin nyiga banayen kuro?',
        healthWellness: 'Nǝlefa-a kuru Nǝlefa-a Kulastǝ',
        denSurvey: 'Kulashi cida luwayamabe DENbe',
        emergencyAccess: 'Cidawa Fandoye Azaltəye',
        multilingualCalling: 'təlam kadalan bowotə',
        freeCalling: 'Bowotə kəske',
        denLiveVideo: 'Wakil Bidiyo Kǝnǝngatǝbe DEN',
        multilingualChatRoom: 'Njim Zandeye təlam kadaye',
        denMarketplace: 'Na Kasuwu Maara samibe DENbe',
        qrCodeCreator: 'Kasuwu Dunyabe',
        ttyTtd: 'TTY/TDD də cidawa bidiyoye kərmu-a səmo-a nankaro',
        airlineAgents: 'Agents mara samiye',
        personalCall: 'Bowotə kəlanzəbe',
        phoneNumber: 'Lamba wayabe',
        selectAirline: 'Wakil kamfani mara samiye karne',
        airlineCalling: 'Maara samiye bowotə',
        connect: 'Kəltə',
    },
    sus: {
        selectLanguage: 'I xa i xa xui sugandi',
        backMainMenu: 'Wo gbilen menu xungbe ma',
        helpSubtitle: 'Won nOma wo malide di to?',
        healthWellness: 'Santeya nun yalan gni matofe',
        denSurvey: 'DEN Kliyee xa wali xOn na fegni',
        emergencyAccess: 'Nakhan laman araba yakossi Marafoulounyi Walie',
        multilingualCalling: 'Xili wuyaxi',
        freeCalling: 'Xili sare mu na',
        denLiveVideo: 'DEN Live Video Mighi nakhan walifé lankhoundè dé',
        multilingualChatRoom: 'Xui wuyaxi wOyEn banxi',
        denMarketplace: 'DEN Aeroport makiti',
        qrCodeCreator: 'Dunia makiti',
        ttyTtd: 'TTY/TDD na video relay nan na tulixOrEe bE a nun tulixOrEe bE',
        airlineAgents: 'Airline Agents',
        personalCall: 'Mixi yEtE xili',
        phoneNumber: 'telefone kOde',
        selectAirline: 'Wo xa wo xa avion walikE sugandi',
        airlineCalling: 'Avion xilife',
        connect: 'Sa fé ra a boré ma',
    },
    manipuri: {
        selectLanguage: 'নিঃপেক্ষ ভাষা নিৰ্বাচন কৰক',
        backMainMenu: 'মূখ্য মেনুত ফিরে যাওক',
        helpSubtitle: 'আজিকালিৰে আপুনি কেনেকৈ সহায় কৰিব পাৰে?',
        healthWellness: 'স্বাস্থ্য আৰু সুস্থতা পৰীক্ষা',
        denSurvey: 'DEN গ্ৰাহক সেৱা সৰ্বেক্ষণ',
        emergencyAccess: 'জৰুৰী পৰিসেৱা',
        multilingualCalling: "বহুভাষিক ক'ল",
        freeCalling: "বিনামূলীয়া ক'ল",
        denLiveVideo: "DEN লাইভ ভিডিঅ' এজেণ্ট",
        multilingualChatRoom: 'বহুভাষিক চাট ৰুম',
        denMarketplace: 'DEN বিমানবন্দৰৰ বজাৰ',
        qrCodeCreator: "গ্ল'বেল বজাৰ",
        ttyTtd: 'TTY/TDD অন্ধ আৰু শুনা নোৱাৰা ৰিয়েলি সেৱাৰ বাবে',
        airlineAgents: 'বিমান সংস্থাৰ এজেণ্টস',
        personalCall: "ব্যক্তিগত ক'ল",
        phoneNumber: 'ফোন নম্বৰ',
        selectAirline: 'আপোনাৰ বিমান সংস্থাৰ এজেণ্ট নিৰ্বাচন কৰক',
        airlineCalling: "বিমান সংস্থাৰ ক'ল",
        connect: 'সংযোগ কৰক',
    },
    ndc: {
        selectLanguage: 'Ndapota sarudza mutauro wako',
        backMainMenu: 'Kudzokera kune Menyu huru',
        helpSubtitle: 'Tinokona kukudetsera sei nyamashi?',
        healthWellness: 'Utano uye Utano Check',
        denSurvey: 'DEN Vatengi Vebasa Ongororo',
        emergencyAccess: 'Kukurumidzira Kuwana Mabasa',
        multilingualCalling: 'Kudaidza kwemitauro yakawanda',
        freeCalling: 'Kudaidza kwemahara',
        denLiveVideo: 'DEN LiveVhidhiyo Mumiririri',
        multilingualChatRoom: 'Mitauro yakawanda ChatRoom',
        denMarketplace: 'DEN Ndege Musika',
        qrCodeCreator: 'Musika weNyika Yese',
        ttyTtd: 'TTY / TDD ndeyekuendesa mavhidhiyo masevhisi kune matsi uye vasina kuzwa',
        airlineAgents: 'Vamiririri vendege',
        personalCall: 'Kudaidza kwemunhu',
        phoneNumber: 'Nhamba yefoni',
        selectAirline: 'Sarudza yako ndege mumiririri',
        airlineCalling: 'Ndege Kudaidza',
        connect: 'Batanidza',
    },
    iloko: {
        selectLanguage: 'Pangngaasim ta piliem ti lengguahem',
        BackMainMenu: 'Agsubli iti Main Menu',
        helpSubtitle: 'Kasano ti panangtulongmi kenka ita?',
        healthWellness: 'Panangsukimat iti Salun-at ken Kinatalged',
        denSurvey: 'Surbey ti DEN Customer Service',
        emergencyAccess: 'Dagiti Emerhensia a Serbisio ti Panagserrek',
        multilingualCalling: 'Dagiti Karit iti Adu a Lengguahe',
        freeCalling: 'Nawaya a Panagawag',
        denLiveVideo: 'Agente ti DEN Live Video',
        multilingualChatRoom: 'Adu a Pagsasao a Chat Room',
        denMarketplace: 'Marketplace ti DEN Airport',
        qrCodeCreator: 'Sangalubongan a Paglakuan',
        ttyTtd: 'TTY/TDD ket para kadagiti serbisio ti relay ti video para kadagiti tuleng ken saan unay a makangngeg',
        airlineAgents: 'Dagiti Agente ti Aerolihia',
        personalCall: 'Personal nga Awag',
        phoneNumber: 'Numero ti telepono',
        selectAirline: 'Pilien ti ahente ti eroplano',
        airlineCalling: 'Panagipatulod ti Airline',
        connect: 'Konektar',
    },
    khasi: {
        selectLanguage: 'Sngewbha jied ia ka ktien jong phi',
        backMainMenu: 'phai biang sha ka Menu ba kongsan',
        helpSubtitle: 'Kumno ngi lah ban iarap ia phi mynta ka sngi?',
        healthWellness: 'Ka jingpeit bniah ia ka koit ka khiah bad ka jingkoit jingkhiah',
        denSurvey: 'Ka jingwad bniah ia ka jingshakri jong ki nongthied jong ka DEN',
        emergencyAccess: 'Ki jingshakri kyrkieh',
        multilingualCalling: 'Ka jingkhot ba bun jait',
        freeCalling: 'Ka jingkhot khlem jingsiew',
        denLiveVideo: 'DEN Live Vidio Agent',
        multilingualChatRoom: 'Ka Kamra kaba bun jait',
        denMarketplace: 'ka iew jong ka kad liengsuin jong ka DEN',
        qrCodeCreator: 'Ka Iew Pyrthei',
        ttyTd: 'TTY/TDD ka dei na ka bynta ki jingshakri video na ka bynta kiba sngap jar bad kiba la duh noh',
        airlineAgents: 'Ki nongtrei jong ka liengsuin',
        personalCall: 'Ka jingkhot shimet',
        phoneNumber: 'Nombar Phone',
        selectAirline: 'Jied ia u agent jong ka liengsuin jong phi',
        airlineCalling: 'Ka jingkhot liengsuin',
        connect: 'Pyniasoh',
    },
    new: {
        selectLanguage: 'छंगु भाय् ल्ययादिसँ',
        backMainMenu: 'मुख्य मेनुइ लिहां हुँ',
        helpSubtitle: 'थौं जिमिसं छन्त गथे यानाः ग्वाहालि याये फै ?',
        healthWellness: 'स्वास्थ्य व कल्याण जाँच',
        denSurvey: 'डेन गाहाकि सेवा सर्भे',
        emergencyAccess: 'आपतकालीन पहुँच सेवा',
        multilingualCalling: 'मल्टिलिङ्गुअल कलिङ्ग',
        freeCalling: 'सितिकं कलिङ्ग',
        denLiveVideo: 'डेन लाइभ भिडियो एजेन्ट',
        multilingualChatRoom: 'बहुभाषिक खँल्हाबल्हा क्वथा',
        denMarketplace: 'डेन एयरपोर्ट मार्केटप्लेस',
        qrCodeCreator: 'ग्लोबल मार्केटप्लेस',
        ttyTtd: 'टिटिवाइ/टिडिडि धयागु बहिरा व न्यने मफुपिनिगु निंतिं भिडियो रिले सेवाया निंतिं खः',
        airlineAgents: 'एयरलाइन एजेन्ट्स',
        personalCall: 'व्यक्तिगत कल',
        phoneNumber: 'फोन ल्याः',
        selectAirline: 'छंगु एयरलाइन एजेन्ट ल्य',
        airlineCalling: 'एयरलाइन कलिङ्ग',
        connect: 'स्वा',
    },
    iku: {
        selectLanguage: 'ᐅᖃᐅᓯᐃᑦ ᓂᕈᐊᕐᓗᒍ',
        backMainMenu: 'ᐅᑎᕐᓗᑎᑦ ᓂᕆᔭᒃᓴᓪᓗᐊᑕᕐᓄᑦ',
        helpSubtitle: 'ᖃᓄᖅ ᐅᓪᓗᒥ ᐃᑲᔪᕈᓐᓇᖅᐱᑕ?',
        healthWellness: 'ᐋᓐᓂᐊᖃᕐᓇᙱᑦᑐᓕᕆᓂᕐᒧᑦ ᖃᓄᐃᙱᑦᑎᐊᕐᓂᕐᒧᓪᓗ ᖃᐅᔨᓴᖅᑕᐅᓂᖅ',
        denSurvey: 'DEN-ᑯᑦ ᐱᔨᑦᑎᖅᑕᐅᓂᕐᒧᑦ ᖃᐅᔨᓴᕐᓂᖅ',
        emergencyAccess: 'ᑐᐊᕕᕐᓇᖅᑐᓄᑦ ᐱᔨᑦᑎᕋᐅᑎᑦ',
        multilingualCalling: 'ᐊᑕᐅᓯᐅᙱᑦᑐᒥᒃ ᐅᖃᐅᓯᖃᕐᓗᓂ ᐅᖄᓚᓂᖅ',
        freeCalling: 'ᐊᑭᖃᙱᑦᑐᒃᑯᑦ ᐅᖃᓗᖕᓂᖅ',
        denLiveVideo: 'DEN ᑕᕐᕆᔮᒃᓴᓕᕆᔨ',
        multilingualChatRoom: 'ᐊᑕᐅᓯᐅᙱᑦᑐᒥᒃ ᐅᖃᐅᓯᖃᕐᓂᖅ Chat Room',
        denMarketplace: 'DEN ᒥᑦᑕᕐᕕᖕᒥᑦ ᓂᐅᕕᕐᕕᒃ',
        qrCodeCreator: 'ᓄᓇᕐᔪᐊᒥ ᓂᐅᕕᐊᒃᓴᖃᕐᓂᖅ',
        ttyTtd: 'TTY/TDD ᑕᕐᕆᔮᒃᓴᓄᑦ ᐅᖃᐅᓯᐅᔪᓄᑦ ᑐᓵᙱᑦᑐᓄᑦ ᑐᓵᑦᑎᐊᙱᑦᑐᓄᓪᓗ',
        airlineAgents: 'ᖃᖓᑕᓲᓕᕆᔩᑦ',
        personalCall: 'ᓇᖕᒥᓂᖅ ᐅᖄᓚᔪᖅ',
        phoneNumber: 'ᐅᖃᓘᑎᐅᑉ ᓈᓴᐅᑖ',
        selectAirline: 'ᓂᕈᐊᕐᓗᒍ ᖃᖓᑕᓲᓕᕆᔨ',
        airlineCalling: 'ᐅᖄᓚᓂᖅ',
        connect: 'ᐊᑦᑕᑕᕐᕕᒃ',
    },
    sorbian: {
        selectLanguage: 'Prošu wuzwolće swoju rěč',
        backMainMenu: 'Wróćo k hłownemu menijej',
        helpSubtitle: 'Kak móžemy Wam dźensa pomhać?',
        healthWellness: 'Gesundheits- und Wellness-Check',
        denSurvey: 'DEN Kundenservice-Umfrage',
        emergencyAccess: 'Notrufdienste',
        multilingualCalling: 'Wjacerěčne zazwoninje',
        freeCalling: 'Swobodne zazwoninje',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Wjacerěčny chatraum',
        denMarketplace: 'DEN Airport Marktplatz',
        qrCodeCreator: 'Globaler Torplatz',
        ttyTtd: 'TTY/TDD je za widejo-relay-słužby za hłuchich a posłuchanych',
        airlineAgents: 'Agenća lětanskich towarstwow',
        personalCall: 'Wosobinski telefonat',
        phoneNumber: 'Telefonowe čisło',
        selectAirline: 'Wuzwolće swojim airline-agentam',
        airlineCalling: 'Telefonaty lětanskich towarstwow',
        connect: 'Verbinden',
    },
    shn: {
        selectLanguage: 'ၶႅၼ်းတေႃႈလိူၵ်ႈၽႃႇသႃႇၸဝ်ႈၵဝ်ႇ',
        backMainMenu: 'ၶိုၼ်းၵႂႃႇတီႈ မႅၼ်ႇၼူဝ်ႇလူင်',
        helpSubtitle: 'ဝၼ်းမိူဝ်ႈၼႆႉ ႁဝ်းတေၸွႆႈထႅမ်သူလႆႈၸိူင်ႉႁိုဝ်?',
        healthWellness: 'ပၢႆးယူႇလီလႄႈ လွင်ႈယူႇလီထတ်းတူၺ်း',
        denSurvey: 'ၵၢၼ်ထတ်းသၢင် ၽူႈၸႂ်ႉတိုဝ်း DEN',
        emergencyAccess: 'လွင်ႈၶဝ်ႈထိုင်ၶိုၵ်ႉၶၵ်ႉ',
        multilingualCalling: 'လွင်ႈႁွင်ႉၵႂၢမ်းလၢႆၽႃႇသႃႇ',
        freeCalling: 'ႁွင်ႉလွတ်ႈလႅဝ်း',
        denLiveVideo: 'ၽူႈတၢင်တူဝ် ဝီးတီးဢူဝ်း DEN',
        multilingualChatRoom: 'ႁွင်ႈဢုပ်ႇဢူဝ်းလၢႆၽႃႇသႃႇ',
        denMarketplace: 'ၵၢတ်ႇၶူင်ႇႁိူဝ်းမိၼ် DEN',
        qrCodeCreator: 'လွၵ်းၵၢတ်ႇလုမ်ႈၾႃႉ',
        ttyTtd: 'TTY/TDD ၼႆႉ ပဵၼ်တႃႇ ၵၢၼ်သူင်ႇၶၢဝ်ႇငၢဝ်း ဝီးတီးဢူဝ်း တွၼ်ႈတႃႇ ၵူၼ်းတႃမွတ်ႇ လႄႈ ၵူၼ်းဢမ်ႇလႆႈငိၼ်း',
        airlineAgents: 'ၽူႈတၢင်တူဝ်ၶိူင်ႈမိၼ်',
        personalCall: 'ႁွင်ႉသုၼ်ႇတူဝ်',
        phoneNumber: 'မၢႆၾူၼ်း',
        selectAirline: 'လိူၵ်ႈၽူႈတၢင်တူဝ်ၶွမ်ႇပၼီႇႁိူဝ်းမိၼ်ၸဝ်ႈၵဝ်ႇ',
        airlineCalling: 'လွင်ႈႁွင်ႉႁိူဝ်းမိၼ်',
        connect: 'ၵွင်ႉသၢၼ်',
    },
    abkhaz: {
        selectLanguage: 'Иалышәх шәбызшәа',
        backMainMenu: 'Амениу хадахь ашьҭахьҟа',
        helpSubtitle: 'Ишԥауцхраауеи иахьа?',
        healthWellness: 'Агәабзиареи агәабзиареи ргәаҭара',
        denSurvey: 'DEN Ахәаахәҭыҩцәа Рмаҵзура Аҭҵаара',
        emergencyAccess: 'Иҭакԥхықәроу анеирҭа амаҵзурақәа',
        multilingualCalling: 'Бызшәарацәалатәи ааԥхьара',
        freeCalling: 'Хәыда-ԥсада ааԥхьара',
        denLiveVideo: 'DEN Лаив Видео Агент',
        multilingualChatRoom: 'Бызшәарацәалатәи аицәажәаратә ауада',
        denMarketplace: 'ДЕН Аҳаиртә баӷәаза Аџьармыкьа',
        qrCodeCreator: 'Адунеизегьтәи аџьармыкьа',
        ttyTtd: 'TTY/TDD зыӡырҩцәеи зыбжьы ԥсыҽу ауааи рзы авидео еиҭагаратә усбарҭақәа рзы ауп',
        airlineAgents: 'Аҳаиртә агентцәа',
        personalCall: 'Хаҭалатәи ааԥхьара',
        phoneNumber: 'аҭел аномер',
        selectAirline: 'Иалышәх шәҳаиртә еилазаара агент',
        airlineCalling: 'Аҳаиртә еимадара ааԥхьара',
        connect: 'Аиԥшьра',
    },
    kv: {
        selectLanguage: 'Пожалуйста, бӧрйы ассьыд кывтӧ',
        backMainMenu: 'Бӧр шӧр менюӧ',
        helpSubtitle: 'Кыдзи ми вермам отсавны тэныд талун?',
        healthWellness: 'Здоровье да бурлун видлалӧм',
        denSurvey: 'ДЕН клиентъясӧс могмӧдан юалӧм',
        emergencyAccess: 'Аварийнӧй йӧзлы отсӧг сетан службаяс',
        multilingualCalling: 'Уна кывъя Звӧнок',
        freeCalling: 'Вольнӧй звӧнок',
        denLiveVideo: 'ДЕН ловъя видеоагент',
        multilingualChatRoom: 'Уна кывъя ЧатРум',
        denMarketplace: 'ДЕН аэропортын рынок',
        qrCodeCreator: 'Глобальнӧй рынок',
        ttyTtd: 'TTY/TDD - тайӧ гӧльяслы да омӧля кывзысьяслы видеореле услугаяс вылӧ',
        airlineAgents: 'Авиагентъяс',
        personalCall: 'Личнӧй Звӧнок',
        phoneNumber: 'Телефон номер',
        selectAirline: 'Бӧрйы ассьыд авиакомпаниялысь агенттӧ',
        airlineCalling: 'Авиакомпаниялы звӧнит',
        connect: 'Связь',
    },
    jingpo: {
        selectLanguage: 'Chyeju hte na a ga hpe lata la u',
        backMainMenu: 'Madung Menu de bai wa na',
        helpSubtitle: 'Dai ni anhte nang hpe gara hku karum ya lu na kun?',
        healthWellness: 'Hkamja lam hte hkam kaja lam hpe jep joi ai lam',
        denSurvey: 'DEN a htingbu htingbyen ni hpe daw jau ai lam sawk sagawn ai lam',
        emergencyAccess: 'Hkyak hkyak shang na matu magam bungli ni',
        multilingualCalling: 'ga amyu law law shaga ai',
        freeCalling: 'Manu jahpu n jaw ra ai sha shaga ai',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Ga amyu myu shaga ai Chat Room',
        denMarketplace: 'DEN Nbungli Pa Gat',
        qrCodeCreator: 'Mungkan ting na gat lawk',
        ttyTtd: 'TTY/TDD gaw na n na ai ni hte na n na lu ai ni a matu video shapoi ai magam bungli ni a matu re',
        airlineAgents: 'Nbungli hte seng ai ni',
        personalCall: 'Masha langai hkrai shaga ai',
        phoneNumber: 'phone nambat',
        selectAirline: 'Na a nbungli company a dat kasa hpe lata la u',
        airlineCalling: 'Nbungli hte shaga ai',
        connect: 'Matut mahkai',
    },
    kokborok: {
        selectLanguage: 'Khapang srangwi nini kokthai chongdi',
        backMainMenu: 'Kwplaisa Menu-o kiphilwi phaidi',
        helpSubtitle: 'Tini chwng bahai khe nono chubachu khlaiwi mano?',
        healthWellness: 'Khamma tei Kahamma Naidi',
        denSurvey: 'DEN borokni samung naikolmung',
        emergencyAccess: 'Kwplaisa jorao thangmani samung',
        multilingualCalling: 'kwbangma kok bai ringjak',
        freeCalling: 'Kwthar khe ringmani',
        denLiveVideo: 'DEN Kwthang Vidio Agent',
        multilingualChatRoom: 'kwbangma kok bai kok salaimani nok',
        denMarketplace: 'DEN Hayung Bazarni Jaga',
        qrCodeCreator: 'Swngcharni Bazar',
        ttyTtd: 'TTY/TDD wngkha khwna kwrwi tei khwna kwrwirokni bagwi video relay samungni bagwi',
        airlineAgents: 'Nwngbarni Samungrok',
        personalCall: 'sakni ringmung',
        phoneNumber: 'phone nambar',
        selectAirline: 'Nini nokbar agent chongdi',
        airlineCalling: 'Nokbarni Kokmung',
        connect: 'jothadi',
    },
    bambara: {
        selectLanguage: 'Aw ye aw ka kan sugandi',
        backMainMenu: 'Ka segin Menu kunba kan',
        helpSubtitle: "An bɛ se k'aw dɛmɛ cogo di bi?",
        healthWellness: 'Kɛnɛya ni kɛnɛya sabatili sɛgɛsɛgɛli',
        denSurvey: 'DEN ka kiliyanw dɛmɛni sɛgɛsɛgɛli',
        emergencyAccess: 'Kɔrɔtɔko sɔrɔli baarakɛlaw',
        multilingualCalling: 'Kan caman weleli',
        freeCalling: 'Weleli fu',
        denLiveVideo: 'DEN ka videyo ɲɛnama ciden',
        multilingualChatRoom: 'Barokɛyɔrɔ min bɛ kan caman fɔ',
        denMarketplace: 'DEN Airport Sugu',
        qrCodeCreator: 'Diɲɛ suguba',
        ttyTtd: 'TTY/TDD ye videyo relay baarakɛminɛnw ye tulogwerenw ni mɛnnikɛbaliw ye',
        airlineAgents: 'Aviyɔn baarakɛlaw',
        personalCall: 'Mɔgɔ yɛrɛ ka weleli',
        phoneNumber: 'Telefɔni nimɔrɔ',
        selectAirline: 'I ka awiyɔn baarakɛlaw ka lasigiden sugandi',
        airlineCalling: 'Aviyɔn weleli',
        connect: 'Jɛɲɔgɔnya',
    },
    jam: {
        selectLanguage: 'Please select yuh language',
        backMainMenu: 'Back to Main Menu',
        helpSubtitle: 'O cyaah wi help yu today?',
        healthWellness: 'Health an Wellness Check',
        denSurvey: 'DEN Customer Service Survey',
        emergencyAccess: 'Emergency Access Services',
        multilingualCalling: 'Multilingual a call',
        freeCalling: 'Free a call',
        denLiveVideo: 'DEN liv Video Agent',
        multilingualChatRoom: 'Multilingual Chat Room',
        denMarketplace: 'DEN Airport Marketplace',
        qrCodeCreator: 'Global Marketplace',
        ttyTtd: 'TTYTDD de fi video relay services fi di deaf an hearing impaired',
        airlineAgents: 'Airline Agents',
        personalCall: 'Personal kaal',
        phoneNumber: 'Fone numba',
        selectAirline: 'Select yu airline agent',
        airlineCalling: 'Airline a call',
        connect: 'connect',
    },
    lim: {
        selectLanguage: 'Kies äng sprèke',
        backMainMenu: "Terug nao 't Hoofdmènu",
        helpSubtitle: 'Hoe kinne wie u hëllepe vandaog?',
        healthWellness: 'Gezondheid en Welzijnscontrole',
        denSurvey: 'DEN Klanttevredenheidsonderzoek',
        emergencyAccess: 'Noodtoegangsdiensten',
        multilingualCalling: 'Meertalige Oproepen',
        freeCalling: 'Gratis Oproepen',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Meertalige Chatruimte',
        denMarketplace: 'DEN Luchthavenmarktplaats',
        qrCodeCreator: 'Wereldwijde Marktplaats',
        ttyTtd: 'TTY/TDD is veur video-relaydiensten veur de dove en slechthorende',
        airlineAgents: 'Luchtvaartmaatschappijagenten',
        personalCall: 'Persoonlijke Oproep',
        phoneNumber: 'Telefoonnummer',
        selectAirline: 'Kies äng luchtvaartmaatschappijagent',
        airlineCalling: 'Luchtvaartmaatschappij Oproepen',
        connect: 'Verbinden',
    },
    ga: {
        selectLanguage: 'Ofainɛ halamɔ owiemɔ',
        backMainMenu: 'Sɛɛ kɛya sɛɛgbɛtsɔɔmɔ titri',
        helpSubtitle: 'Te wɔɔfee tɛŋŋ wɔye wɔbua bo ŋmɛnɛ?',
        healthWellness: 'Hewalɛ kɛ Hewalɛ Kwɛmɔ',
        denSurvey: 'DEN nihɔɔlɔi anitsumɔ niiamlipɛimɔ',
        emergencyAccess: 'Oyaiyeli Hegbɛ Nitsumɔi',
        multilingualCalling: 'Wiemɔi srɔtoi Tsɛmɔ',
        freeCalling: 'Tsɛmɔ ni aheee nɔ ko',
        denLiveVideo: 'DEN Live Vidio Najiaŋdamɔlɔ',
        multilingualChatRoom: 'wiemɔi srɔtoi sanegbaa tsu',
        denMarketplace: 'DEN kɔɔyɔŋ lɛlɛ he jarayelihe',
        qrCodeCreator: 'Jeŋ Guɔyelihe',
        ttyTtd: 'TTY/TDD ji kɛha vidio nitsumɔi kɛha mumuii kɛ mɛi ni nuɔ nii jogbaŋŋ',
        airlineAgents: 'Kɔɔyɔŋ gbɛfalɔi',
        personalCall: 'Mɔ diɛŋtsɛ Tsɛmɔ',
        phoneNumber: 'Tɛlifon Yibɔ',
        selectAirline: 'Halamɔ o kɔɔyɔŋ lɛlɛ najiaŋdamɔlɔ',
        airlineCalling: 'Kɔɔyɔŋ lɛlɛ Tsɛmɔ',
        connect: 'tsa',
    },
    gv: {
        selectLanguage: 'Reih yn Ghaelg ayd',
        backMainMenu: 'Arrey gys Pryssoonagh',
        helpSubtitle: "Cre'n aght oddys mayd cooney lhiat jiu?",
        healthWellness: 'Check Slaynt as Slaynt',
        denSurvey: 'Suashtal Sherveish Client DEN',
        EmergencyAccess: 'Shirveishyn Cooishyn Eer',
        multilingualCalling: 'Galaghyn immeeaght',
        freeCalling: 'Glaare saor',
        denLiveVideo: 'Agent Video Beo DEN',
        multilingualChatRoom: 'Seihll-chaglym immeeaght',
        denMarketplace: 'Margagh Port-Aer DEN',
        qrCodeCreator: 'Margagh Cruinney',
        ttyTtd: 'Ta TTY/TDD son shirveishyn relay-video son ny boir as ny dollagyn clashtyn',
        airlineAgents: 'Agentyn Airline',
        personalCall: 'Galm Persoonagh',
        phoneNumber: 'UimhirFelley',
        selectAirline: 'Reih dty h-agglish y chompanyn aer',
        airlineCalling: 'Galaghyn y chompanyn aer',
        connect: 'Ceaghlagh',
    },
    sicilian: {
        selectLanguage: 'Si prega di scègghiri la tò lingua',
        backMainMenu: 'Torna ô MenùPrincipali',
        helpSubtitle: 'Comu ti putemu aiutari oggi?',
        healthWellness: 'Saluti e Benessere Cuntrollu',
        denSurvey: 'Sundaggiu supra lu sirvizziu ô clienti DEN',
        emergencyAccess: "Sirvizzi di Accessu d'Imirgenza",
        multilingualCalling: 'Chiamata Multilingua',
        freeCalling: 'Chiamata lìbbira',
        denLiveVideo: "DEN Aggenti dû vìdiu 'n diretta",
        multilingualChatRoom: 'Sala di Chiacchiari Multilingui',
        denMarketplace: "Mircatu di l'Aeroportu DEN",
        qrCodeCreator: 'Mircatu Globali',
        ttyTtd: "TTY/TDD è pî sirvizzi di trasmissioni vìdiu pî surdi e cu disturbi d'audiu",
        airlineAgents: 'Agenti dî cumpagnìi aèrii',
        personalCall: 'ChiamataPirsunali',
        phoneNumber: 'NùmmaruTilèfunu',
        selectAirline: "Scegghi l'aggenti dâ cumpagnìa aèria",
        airlineCalling: 'Chiama dâ cumpagnìa aèria',
        connect: 'cunnèttiri',
    },
    divehi: {
        selectLanguage: 'ތިޔަބޭފުޅުންގެ ބަސް ހޮވުން އެދެމެވެ',
        backMainMenu: 'އެނބުރި މައި މެނޫއަށް',
        helpSubtitle: 'މިއަދު އަޅުގަނޑުމެންނަށް އެހީތެރިވެދެވޭނީ ކިހިނެއް ހެއްޔެވެ؟',
        healthWellness: 'ހެލްތު އެންޑް ވެލްނެސް ޗެކްކުރުން',
        denSurvey: 'ދަ ކަސްޓަމަރ ސާވިސް ސާރވޭ',
        emergencyAccess: 'އިމަޖެންސީ އެކްސެސް ސާވިސަސް',
        multilingualCalling: 'މަލްޓިލިންގުއަލް ކޯލިންގ',
        freeCalling: 'ހިލޭ ގުޅާލުން',
        denLiveVideo: 'ދަ ލައިވް ވީޑިއޯ އޭޖެންޓް',
        multilingualChatRoom: 'މަލްޓިލިންގުއަލް ޗެޓް ރޫމް',
        denMarketplace: 'ދަ އެއާޕޯޓް މާކެޓްޕްލޭސް އެވެ',
        qrCodeCreator: 'ގްލޯބަލް މާކެޓްޕްލޭސް އެވެ',
        ttyTtd: 'ޓީޓީއައި/ޓީޑީޑީ އަކީ ބީރު އަދި އަޑުއިވުން ދަށް މީހުންނަށް ވީޑިއޯ ރިލޭގެ ހިދުމަތް ދިނުމެވެ',
        airlineAgents: 'އެއާލައިން އޭޖެންޓުންނެވެ',
        personalCall: 'ޕާސަނަލް ކޯލް',
        phoneNumber: 'ފޯނު ނަންބަރު',
        selectAirline: 'ތިމާގެ އެއާލައިން އޭޖެންޓް ހޮވާށެވެ',
        airlineCalling: 'އެއާލައިން ކޯލިންގ',
        connect: 'ގުޅުވުން',
    },
    ewe: {
        selectLanguage: 'Taflatse tia wò gbe',
        backMainMenu: 'Trɔ yi Menu Gãtɔ gbɔ',
        helpSubtitle: 'Aleke míawɔ akpe ɖe ŋuwò egbea?',
        healthWellness: 'Lãmesẽ Kple Dzedzeme Dzesidede',
        denSurvey: 'DEN ƒe Asitsalawo ƒe Dɔwɔƒe ƒe Numekuku',
        emergencyAccess: 'Kpata ƒe Mɔɖeɖe Dɔwɔƒe',
        multilingualCalling: 'Gbe geɖewo ƒe Yɔyɔ',
        freeCalling: 'Yɔyɔ Femaxee',
        denLiveVideo: 'DEN Video ƒe Dɔwɔla si le agbe',
        multilingualChatRoom: 'Gbe geɖe me Dzeɖoɖoxɔ',
        denMarketplace: 'DEN Yameʋudzeƒe ƒe Asitsaƒe',
        qrCodeCreator: 'Xexeame Katã ƒe Asitsaƒe',
        ttyTtd: 'TTY/TDD nye video relay dɔwɔnawo na tokunɔwo kple tomesesẽtɔwo',
        airlineAgents: 'Yameʋudɔwɔƒe ƒe Dɔwɔlawo',
        personalCall: 'Ame ŋutɔ ƒe Yɔyɔ',
        phoneNumber: 'Telefon Xexlẽdzesi',
        selectAirline: 'Tia wò yameʋudɔwɔƒe ƒe amedɔdɔ',
        airlineCalling: 'Yameʋudɔwɔƒe ƒe Yɔyɔ',
        connect: 'Kadodo',
    },
    dombe: {
        selectLanguage: 'Kosala mwaambo wako',
        backMainMenu: 'Kujokela ku Main Menu',
        helpSubtitle: 'Tulakonzya kukugwasya biyeni sunu?',
        healthWellness: 'Kulanga-langa Buumi Bubotu',
        denSurvey: 'DEN Xjelb´itz te aq´untl kye xjal',
        emergencyAccess: 'Aq´untl te t-xilen t-xilen',
        multilingualCalling: 'q´olb´eb´il toj nim yol',
        freeCalling: 'q´olb´eb´il noq kukx',
        denLiveVideo: 'DEN Agente te video toj qchwinqil',
        multilingualChatRoom: 'Ja te q´olb´eb´il toj nimku yol',
        denMarketplace: 'DEN K´ayb´il te aeropuerto',
        qrCodeCreator: 'K´ayb´il twitz tx´otx´',
        ttyTtd: 'TTY/TDD njakubelesya mavidiyo kubantu batamvwi abaabo batamvwi kabotu',
        airlineAgents: 'Basikugwasilizya ndeke',
        personalCall: 'fooni yako',
        phoneNumber: 'Namba yafooni',
        selectAirline: 'Sala mubelesi wako wa ndeke',
        airlineCalling: 'q´olb´eb´il te avión',
        connect: 'Swaanganya',
    },
    guarani: {
        selectLanguage: "Eiporavo ne ñe'ẽ",
        backMainMenu: 'Ojevy Menú Principal-pe',
        helpSubtítulo: "Mba'éichapa ikatu roipytyvõ ko árape?",
        healthWellness: 'Tesãi ha Tesãi Tesãi rehegua jesareko',
        denSurvey: 'Encuesta Servicio al Cliente DEN rehegua',
        emergencyAccess: 'Servicios de Acceso emergencia rehegua',
        multilingualCalling: "Ñehenói heta ñe'ẽme",
        freeCalling: 'Ñehenói gratis',
        denLiveVideo: 'DEN Agente de vídeo en vivo',
        multilingualChatRoom: "Ñe'ẽñemi heta ñe'ẽme",
        denMarketplace: 'DEN Aeropuerto Mercado',
        qrCodeCreator: 'Mercado Mundial',
        ttyTtd: "TTY/TDD ha'e umi servicio de relé de vídeo umi ohendu'ỹva ha ndohendúivape g̃uarã",
        airlineAgents: 'Aerolínea Agente-kuéra',
        PersonalCall: 'Ñehenói personal',
        phoneNumber: 'Teléfono número',
        selectAirline: 'Eiporavo nde agente aerolínea rehegua',
        airlineCalling: 'Aerolínea Ñehenói',
        connect: 'Ombojoaju',
    },
    twi: {
        selectLanguage: 'Yɛsrɛ wo paw wo kasa',
        backMainMenu: 'San kɔ Menu Titiriw no so',
        helpSubtitle: 'Yɛbɛyɛ dɛn aboa wo nnɛ?',
        healthWellness: 'Akwahosan ne Yiyedi Nhwehwɛmu',
        denSurvey: 'DEN Adetɔfo Dwumadibea Nhwehwɛmu',
        emergencyAccess: 'Nneɛma a Ɛho Hia Ntɛmntɛm Ho Nnwuma',
        multilingualCalling: 'Kasa pii Frɛ',
        freeCalling: 'Wɔfrɛ a wontua hwee',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Kasa pii Nkɔmmɔbɔ dan',
        denMarketplace: 'DEN Wimhyɛn Gyinabea Guadibea',
        qrCodeCreator: 'Wiase Nyinaa Guadibea',
        ttyTtd: 'TTY/TDD yɛ video relay dwumadie ma asotifoɔ ne wɔn a wɔnte asɛm',
        airlineAgents: 'Wimhyɛn Adwumayɛfo',
        personalCall: 'Ankorankoro Frɛ',
        phoneNumber: 'Tɛlɛfon Nɔma',
        selectAirline: 'Paw wo wimhyɛn adwumakuw no ananmusifo',
        airlineCalling: 'Wimhyɛn mu Frɛ',
        connect: 'Nkitahodi',
    },
    mam: {
        selectLanguage: 'B´a´n tu´n t-xi t-sk´o´na t-yol',
        backMainMenu: 'Tzaj meltz´aj toj menú tnejel',
        helpSubtitle: '¿Tze´n tten jaku qo onina ti´ja jalin?',
        healthWellness: 'Xjelb´itz ti´j q´anb´il ex ti´j qchwinqil',
        denSurvey: 'DEN Xjelb´itz te aq´untl kye xjal',
        emergencyAccess: 'Aq´untl te t-xilen t-xilen',
        multilingualCalling: 'q´olb´eb´il toj nim yol',
        freeCalling: 'q´olb´eb´il noq kukx',
        denLiveVideo: 'DEN Agente te video toj qchwinqil',
        multilingualChatRoom: 'Ja te q´olb´eb´il toj nimku yol',
        denMarketplace: 'DEN K´ayb´il te aeropuerto',
        qrCodeCreator: 'K´ayb´il twitz tx´otx´',
        ttyTtd: 'TTY/TDD te t-xilen video kye xjal mya b´i´n ky-b´i´n ex kye xjal mya b´a´n ky-b´i´n',
        airlineAgents: 'Aye ajb´el te avión',
        personalCall: 'q´olb´eb´il te junjun',
        phoneNumber: 'Número de teléfono',
        selectAirline: 'Tzaj t-sk´o´na t-xnaq´tzb´ena',
        airlineCalling: 'q´olb´eb´il te avión',
        connect: 'Conectar',
    },
    baoule: {
        selectLanguage: "Yaci, fa ɔ aniɛn'n",
        backMainMenu: "Si'n Menu Principal",
        helpSubtitle: '?Wafa sɛ yɛ e kwla uka wɔ andɛ ɔ?',
        healthWellness: 'Santé et Bien-être Check',
        denSurvey: "DEN i kliɛn'm be su nianlɛ'n",
        emergencyAccess: "Services d'accès d'urgence",
        multilingualCalling: 'Aniɛn kpanngban nun flɛlɛ',
        freeCalling: 'Flɛlɛ ngbɛn',
        denLiveVideo: "DEN i video ng'ɔ o lɛ'n i su kpɛn",
        multilingualChatRoom: 'Salle de chat multilingue',
        denMarketplace: 'DEN Aeroport Gua',
        qrCodeCreator: 'Global marché',
        ttyTtd: 'TTY/TDD ti video nun ndɛ kanlɛ junman nga be timan ndɛ’n, ɔ nin be nga be timan ndɛ kpa’n be liɛ',
        airlineAgents: "Agents de l'avion",
        personalCall: 'Flɛlɛ sran',
        phoneNumber: 'Telefɔnu Nimero',
        selectAirline: "Fa ɔ aviɔn nun junman difuɛ'n",
        airlineCalling: 'Avion Appel',
        connect: 'Connecter',
    },
    hunsrik: {
        selectLanguage: 'Tuu tayn xprooch raus suuche',
        backMainMenu: 'Zurück tsum HauptMenu',
        helpSubtitle: 'Wii khëne mer tich hayt hëlfe?',
        healthWellness: 'Gesundheit un Welt Check',
        denSurvey: 'Enquête de Kunde Serviço de DEN',
        emergencyAccess: 'Nootwënichkheete Akses Tiinst',
        multilingualCalling: 'Meerxproochich Ruf',
        freeCalling: 'FreeRuf',
        denLiveVideo: 'DEN LiveVideo Agent',
        multilingualChatRoom: 'Meerxproochich xprooch',
        denMarketplace: 'DEN Flughafemarktplaats',
        qrCodeCreator: 'Weltmarkt',
        ttyTtd: 'TTY/TDD is fer wiiteo-relay tiinst fer ti toote un xwach heer',
        airlineAgents: 'Agentes de Airlinea',
        personalCall: 'Persoonlich Ruf',
        phoneNumber: 'Telefoonnummer',
        selectAirline: 'Raus suuche tayn luft xif agent',
        airlineCalling: 'LuftlinieRuf',
        connect: 'Ferklayche',
    },
    tetum: {
        selectLanguage: 'Favor ida hili ita-boot nia lian',
        backMainMenu: 'Fila fali ba Menu Prinsipal',
        helpSubtitle: 'Oinsá ami bele ajuda ita-boot ohin?',
        healthWellness: 'Saúde no Verifikasaun ba Bem-estar',
        denSurvey: 'DEN Peskiza Servisu ba Kliente',
        emergencyAccess: 'Servisu Asesu Emerjénsia nian',
        multilingualCalling: 'Xamada ho lian oioin',
        freeCalling: 'Xamada Gratuita',
        denLiveVideo: 'DEN Ajente Vídeo Diretu',
        multilingualChatRoom: 'Sala Chat Multilinge',
        denMarketplace: 'DEN Merkadu Aeroportu nian',
        qrCodeCreator: 'Merkadu Globál',
        ttyTtd: 'TTY/TDD maka ba servisu sira relay vídeo nian ba ema tilun-diuk no ho defisiénsia rona nian',
        airlineAgents: 'Ajente sira Kompañia Aérea nian',
        personalCall: 'Xamada pesoál',
        phoneNumber: 'Númeru Telefone',
        selectAirline: 'Hili Ita-boot nia ajente kompañia aérea nian',
        airlineCalling: 'Xamada ba kompañia aérea',
        connect: 'Liga',
    },
    yue: {
        selectLanguage: '「請選擇你嘅語言」',
        backMainMenu: '「返去主菜單」',
        helpSubtitle: '「我哋今日可以點幫到你？」',
        healthWellness: '「健康健康檢查」',
        denSurvey: '「 DEN 客戶服務調查」',
        emergencyAccess: '「緊急存取服務」',
        multilingualCalling: '「多語言通話」',
        freeCalling: '「免費通話」',
        denLiveVideo: '「 DEN 直播影片代理」',
        multilingualChatRoom: '「多語言聊天室」',
        denMarketplace: '「登機場市集」',
        qrCodeCreator: '「全球市場」',
        ttyTtd: '「 TTY / TDD 係為聾人同聽障人士提供視像轉播服務」',
        airlineAgents: '「航空公司特工」',
        personalCall: '「個人來電」',
        phoneNumber: '「電話號碼」',
        selectAirline: '「選擇你嘅航空公司代理」',
        airlineCalling: '「航空公司通話」',
        connect: '「連接」',
    },
    taiwanese: {
        selectLanguage: '請選擇您的語言',
        backMainMenu: '返回主選單',
        helpSubtitle: '今天我們能為您提供什麼幫助嗎？',
        healthWellness: '健康與保健檢查',
        denSurvey: 'DEN 客戶服務調查',
        emergencyAccess: '緊急存取服務',
        multilingualCalling: '多語言呼叫',
        freeCalling: '免費通話',
        denLiveVideo: 'DEN 直播視訊代理',
        multilingualChatRoom: '多語言聊天室',
        denMarketplace: 'DEN 機場市集',
        qrCodeCreator: '全球市場',
        ttyTtd: 'TTY/TDD 用於為聾啞人士和聽障人士提供視訊中繼服務',
        airlineAgents: '航空公司代理',
        personalCall: '個人呼叫',
        phoneNumber: '電話號碼',
        selectAirline: '選擇您的航空公司代理商',
        airlineCalling: '航空公司呼叫',
        connect: '連接',
    },
    north: {
        selectLanguage: 'Vállje du giela',
        backMainMenu: 'Ruovttoluotta váldomeny',
        helpSubtitle: 'Mo mii sáhttit veahkehit du odne?',
        healthWellness: 'Dearvvašvuođa- ja buresbirgendárkkisteapmi',
        denSurvey: 'DEN Geavaheaddjibálvalusiskkadeapmi',
        emergencyAccess: 'Heahteveahkkebálvalusat',
        multilingualCalling: 'Máŋggagielat riŋgen',
        freeCalling: 'Nuvttá riŋgen',
        denLiveVideo: 'DEN Njuolggosáddaga videoáššedovdi',
        multilingualChatRoom: 'Máŋggagielat ságastallanlatnja',
        denMarketplace: 'DEN Girdišillju márkanbáiki',
        qrCodeCreator: 'Globála márkanbáiki',
        ttyTtd: 'TTY/TDD lea videojohtolatbálvalusaide mat leat dearvvašmeahttumat ja gullanváttisvuođat',
        airlineAgents: 'Girdifitnodaga áššedovdit',
        personalCall: 'Persovnnalaš riŋgen',
        phoneNumber: 'Telefovdnanummir',
        selectAirline: 'Vállje iežat girdifitnodaga áššedovdi',
        airlineCalling: 'Girdifitnodaga riŋgen',
        connect: 'Oktavuohta',
    },
    kl: {
        selectLanguage: 'Oqaatsit toqqaruk',
        backMainMenu: 'Uterneq pingaarnertut menumut',
        helpSubtitle: 'Ullumikkut qanoq ikiorsinnaavagut?',
        healthWellness: 'Peqqinneq aamma Peqqinneq Misissuineq',
        denSurvey: 'DEN-imi atuisunik sullissinermik misissuineq',
        emergencyAccess: 'Ajunaarnersuaqarnermi sullissineq',
        multilingualCalling: 'Oqaatsinik arlalinnik sianerneq',
        freeCalling: 'Akitsuuteqanngitsumik sianerneq',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Oqaatsinik arlalinnik Oqaloqatigiinnermik',
        denMarketplace: 'DEN Mittarfiup Pisiniarfia',
        qrCodeCreator: 'Nunarsuarmi niuerfik',
        ttyTtd: 'TTY/TDD tassaavoq tusaasinnaanngitsunut tusaasinnaanngitsunullu videomik stafetimik sullissinermut',
        airlineAgents: 'TimmisartuutileqatigiiffiupAgentiat',
        personalCall: 'PersonligSanning',
        phoneNumber: 'Telefonnummeri',
        selectAirline: 'Timmisartuutileqatigiiffimmi agentit toqqaruk',
        airlineCalling: 'Timmisartuutileqatigiiffimmut sianerneq',
        connect: 'Attaveqarneq',
    },
    awadhi: {
        selectLanguage: 'कृपया आपन भाषा चुनौ',
        backMainMenu: 'मुख्य मेनू मा वापस',
        helpSubtitle: 'आज हम आपक कैसे मदद कर सकत हैं?',
        healthWellness: 'स्वास्थ्य अऊर कल्याण जांच',
        denSurvey: 'डीईएन ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपातकालीन पहुँच सेवा',
        multilingualCalling: 'बहुभाषी कॉलिंग',
        freeCalling: 'फ्री कॉलिंग',
        denLiveVideo: 'डेन लाइव वीडियो एजेंट',
        multilingualChatRoom: 'बहुभाषी चैट रूम',
        denMarketplace: 'डेन हवाई अड्डा मार्केटप्लेस',
        qrCodeCreator: 'वैश्विक बाजार',
        ttyTtd: 'TTY/TDD बहरे अऊर श्रवण बाधित लोगन के लिए वीडियो रिले सेवाओं के लिए है',
        airlineAgents: 'एयरलाइन एजेंट',
        personalCall: 'व्यक्तिगत कॉल',
        phoneNumber: 'फोन नंबर',
        selectAirline: 'अपना एयरलाइन एजेंट चुनौ',
        airlineCalling: 'एयरलाइन कॉलिंग',
        connect: 'कनेक्ट',
    },
    bikol: {
        selectLanguage: 'Pakipili kan saimong tataramon',
        backMainMenu: 'Balik sa I-play an Menu',
        helpSubtitle: 'Paano kami makakatabang saimo ngunyan?',
        healthWellness: 'Pagsiyasat sa Salud asin Karahayan',
        denSurvey: 'DEN Surbey sa Serbisyo sa Kostumer',
        emergencyAccess: 'Mga Serbisyo sa Pag-access sa Emerhensya',
        multilingualCalling: 'Pag-apod sa Dakul na Tataramon',
        freeCalling: 'Libreng Pag-apod',
        denLiveVideo: 'DEN Buhay Bidyo Ahente',
        multilingualChatRoom: 'Dakul na tataramon mensahe Kwarto',
        denMarketplace: 'DEN Merkado sa Aeroporto',
        qrCodeCreator: 'Pankinaban na Merkado',
        ttyTtd: 'An TTY/TDD para sa mga serbisyo nin video relay para sa mga bungog asin may diperensya sa pagdangog',
        airlineAgents: 'Mga Ahente nin Eroplano',
        personalCall: 'Personal na Pag-apod',
        phoneNumber: 'Numero nin Telepono',
        selectAirline: 'Pilion an saimong ahente sa eroplano',
        airlineCalling: 'Pag-apod sa eroplano',
        connect: 'Magkonektar',
    },
    pakistan: {
        selectLanguage: 'براہ کرم اپنی زبان منتخب کریں',
        backMainMenu: 'واپس مین مینو پر',
        helpSubtitle: 'آج ہم آپ کی مدد کیسے کر سکتے ہیں؟',
        healthWellness: 'صحت اور تندرستی کی جانچ',
        denSurvey: 'DEN کسٹمر سروس سروے',
        emergencyAccess: 'ہنگامی رسائی کی خدمات',
        multilingualCalling: 'کثیر لسانی کالنگ',
        freeCalling: 'مفت کالنگ',
        denLiveVideo: 'DEN لائیو ویڈیو ایجنٹ',
        multilingualChatRoom: 'کثیر لسانی چیٹ روم',
        denMarketplace: 'DEN ائیرپورٹ مارکیٹ پلیس',
        qrCodeCreator: 'عالمی بازار،',
        ttyTtd: 'TTY/TDD بہرے اور سماعت سے محروم افراد کے لیے ویڈیو ریلے خدمات کے لیے ہے',
        airlineAgents: 'ایئر لائن ایجنٹس',
        personalCall: 'ذاتی کال',
        phoneNumber: 'فون نمبر',
        selectAirline: 'اپنے ایئر لائن ایجنٹ کو منتخب کریں',
        airlineCalling: 'ایئر لائن کالنگ',
        connect: 'متصل',
    },
    aymara: {
        selectLanguage: 'Aruma ajlliñamawa',
        backMainMenu: "Jach'a Menú ukar kutt'aña",
        helpSubtitle: "¿Kunjamsa jichhürunakan yanapt'apxsma?",
        healthWellness: "K'umaräñapataki ukhamarak suma jakañ uñakipaña",
        denSurvey: 'DEN ukan Servicio de Cliente ukan yatxatäwipa',
        emergencyAccess: "Jan walt'awinakaru mantañataki yanapt'awinaka",
        multilingualCalling: 'Walja aru jawsaña',
        freeCalling: 'Jan qullqini jawsaña',
        denLiveVideo: 'DEN ukax mä agente de vídeo en vivo',
        multilingualChatRoom: "Walja arunak aruskipt'añ uta",
        denMarketplace: 'DEN Aeropuerto Markachirinaka',
        qrCodeCreator: 'Uraqpachan qhathupa',
        ttyTtd: "TTY/TDD ukax servicios de relé de vídeo ukatakiw oqaranakataki ukhamarak jan ist'irinakataki",
        airlineAgents: 'Avión ukan irnaqirinaka',
        personalCall: 'Jaqix jawsaña',
        phoneNumber: 'Telefono jakhu',
        selectAirline: 'Aerolínea ukan agentepar ajlliñamawa',
        airlineCalling: 'Avión ukan jawsaña',
        connect: "ukat juk'ampinaka",
    },
    nah: {
        selectLanguage: 'Xijtlapejpeni motlajtol',
        backMainMenu: 'Ximokuepa ipan Menú Principal',
        helpSubtitle: '¿Kenijkatsa uelis timitspaleuisej ama?',
        healthWellness: 'Tlayejyekoli tlen tlapajtilistli uan kuali nemilistli',
        denSurvey: 'DEN Encuesta de Servicio al Cliente',
        emergencyAccess: 'Tlapaleuilistli tlen tlaijiyouilistli',
        multilingualCalling: 'Multilingual Calling',
        freeCalling: 'Tlanotzali ika miak tlajtoli',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Sala de Chat Multilingüe',
        denMarketplace: 'DEN Aeropuerto Mercado',
        qrCodeCreator: 'Tlanamakalistli tlen nochi tlaltipaktli',
        ttyTtd: 'TTY/TDD elij para tlapaleuilistli tlen video tlen amo tlakakij uan tlen amo kuali tlakakij',
        airlineAgents: 'Agentes de aerolíneas',
        personalCall: 'Tlajtoli tlen moaxka',
        phoneNumber: 'Número de teléfono',
        selectAirline: 'Xijtlapejpeni mo tlapaleuijketl tlen aerolínea',
        airlineCalling: 'Tlajtlanili tlen aerolínea',
        connect: 'Ximonechiko',
    },
    romani: {
        selectLanguage: 'Mangav tumen te alosaren tumari ćhib',
        backMainMenu: 'Iriv ko Main Menu',
        helpSubtitle: 'Sar śaj te aźutisaras tumen avdive?',
        healthWellness: 'Sastipasko thaj Sastipasko Kontrolo',
        denSurvey: 'DEN Anketa pal-o serviso e kliènturenqo',
        emergencyAccess: 'Servisura vaś o Akceso e Emergèncaqo',
        multilingualCalling: 'But ćhibăqo akharipe',
        freeCalling: 'Bipokinipesko akharipe',
        denLiveVideo: 'DEN Video Agento źivdo',
        multilingualChatRoom: 'But-ćhibăqo ćàso vaś o labăripen',
        denMarketplace: 'DEN Aeroportosko bazari',
        qrCodeCreator: 'Globalno bazari',
        ttyTtd: 'TTY/TDD si vaś video relay servisura vaś e śukar thaj e śukar aśunde',
        airlineAgents: 'Personalo akharipe',
        personalCall: 'Personalo akharipe',
        phoneNumber: 'telefonosko gin',
        selectAirline: 'Alosar tiro aviako agento',
        airlineCalling: 'Akharipe e avionosqo',
        connect: 'Phen',
    },
    bts: {
        selectLanguage: 'Tolong pilih bahasa nassiam',
        backMainMenu: 'Mulak hu Menu Utama',
        helpSubtitle: 'Sonaha hanami boi mangurupi nassiam sadari on?',
        healthWellness: 'Pemeriksaan Kesehatan pakon Kesejahteraan',
        denSurvey: 'DEN Survei Layanan Pelanggan',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Pandiloon Marbagei Bahasa',
        freeCalling: 'Martelepon Gratis',
        denLiveVideo: 'DEN Agen Video Langsung',
        multilingualChatRoom: 'Ruang Obrolan Multibahasa',
        denMarketplace: 'DEN Pasar Bandara',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD aima untuk layanan relay video hubani na nengel pakon na cacat pendengaran',
        airlineAgents: 'Agen penerbangan',
        personalCall: 'Panggilan Pribadi',
        phoneNumber: 'Nomor Telepon',
        selectAirline: 'Pilih agen maskapai penerbangan nassiam',
        airlineCalling: 'Martelepon Maskapai Penerbangan',
        connect: 'Mardomu',
    },
    quechua: {
        selectLanguage: 'Ama hina kaspa, simiykita akllay',
        backMainMenu: 'Kutiy hatun menú nisqaman',
        helpSubtitle: "¿Imaynatataq kunan p'unchaw yanapaykuman?",
        healthWellness: 'Qhali kay, allin kawsay qhaway',
        denSurvey: 'DEN Serviciomanta Encuesta',
        emergencyAccess: 'Emergencia Yaykuna Serviciokuna',
        multilingualCalling: 'Achka simipi Llamada',
        freeCalling: 'Mana qullqiyuq Llamada',
        denLiveVideo: 'DEN Kawsaq Video Agente',
        multilingualChatRoom: 'Achka simipi rimanapaq wasi',
        denMarketplace: 'DEN Aeropuerto qhatu wasi',
        qrCodeCreator: 'Tiqsimuyuntinpi qhatu wasi',
        ttyTtd: 'TTY/TDD mana uyariqkunapaq, mana uyariqkunapaq video relé serviciokunapaqmi',
        airlineAgents: 'Avión nisqapi llamkaqkuna',
        personalCall: 'Llamada personal',
        phoneNumber: 'Telefono yupay',
        selectAirline: 'Avionpa agentenkita akllay',
        airlineCalling: 'Avión Llamada',
        connect: "T'inkinakuy",
    },
    swati: {
        selectLanguage: 'Sicela ukhetse lulwimi lwakho',
        backMainMenu: 'Buyela kuMenyu Leyinhloko',
        helpSubtitle: 'Singakusita njani namuhla?',
        healthWellness: 'Hlola Imphilo Nekuphila Kahle',
        denSurvey: 'DEN Inhlolovo Yetinsita Temakhasimende',
        emergencyAccess: 'Tinsizakalo tekufinyelela letiphutfumako',
        multilingualCalling: 'Kubita ngetilwimi letinyenti',
        freeCalling: 'Kushaya mahhala',
        denLiveVideo: 'I-Ejenti Yevidiyo Lephilako ye-DEN',
        multilingualChatRoom: 'Igumbi Lekucoca Ngetilwimi Letinyenti',
        denMarketplace: 'DEN Imakethe Yesikhumulo Setindiza',
        qrCodeCreator: 'Imakethe Yemhlaba Wonkhe',
        ttyTtd: 'i-TTY/TDD yetinsita tekudlulisela emavidiyo ebantfu labangevako nalabangava kahle',
        airlineAgents: 'Ema-Ejenti etindiza',
        personalCall: 'Lucingo Lwemuntfu',
        phoneNumber: 'InomboloYelucingo',
        selectAirline: 'Khetsa i-ejenti yakho yendiza',
        airlineCalling: 'Kushayela kwendiza',
        connect: 'xhuma',
    },
    silesian: {
        selectLanguage: 'Proszōm wybierz swōj jynzyk',
        backMainMenu: 'Zwrōt do Menu Głōwnego',
        helpSubtitle: 'Jak mogymy ci dzisiej pōmōc?',
        healthWellness: 'Kōntrola Zdrowio i Dobrego Zdrowio',
        denSurvey: 'Anketa ô ôbsłudze kliyntōw DEN',
        emergencyAccess: 'Usługi dostympu do nogłych wypadkōw',
        multilingualCalling: 'Wielojynzyczne Połōnczynie',
        freeCalling: 'Warmowe Połōnczynie',
        denLiveVideo: 'Agynt wideo na żywo DEN',
        multilingualChatRoom: 'Wielojynzyczny Chat Room',
        denMarketplace: 'Rynek lotniskowy DEN',
        qrCodeCreator: 'Rynek Globalny',
        ttyTtd: 'TTY/TDD je przeznaczōne do usług przekaźniczych wideo dlo głuchych i ôsōb ze słabym sluchym',
        airlineAgents: 'Agynty Linijōw Lotniczych',
        personalCall: 'Połōnczynie ôsobiste',
        phoneNumber: 'NumerTelefōnu',
        selectAirline: 'Wybierz swojigo agynta lotniczyj',
        airlineCalling: 'Połōnczynie lotnicze',
        connect: 'Pōłōnczyć',
    },
    fa: {
        selectLanguage: 'لطفا زبان خود را انتخاب کنید',
        backMainMenu: 'بازگشت به منوی اصلی',
        helpSubtitle: 'چگونه می توان به شما کمک کند امروز؟',
        healthWellness: 'بررسی سلامت و سلامتی',
        denSurvey: 'سروی خدمات مشتریان دن',
        emergencyAccess: 'خدمات دسترسی اضطراری',
        multilingualCalling: 'تماس چند زبانه',
        freeCalling: 'تماس رایگان',
        denLiveVideo: 'دن زنده عامل ویدیو',
        multilingualChatRoom: 'اتاق چت چند زبانه',
        denMarketplace: 'بازار فرودگاه دن',
        qrCodeCreator: 'بازار جهانی',
        ttyTtd: 'TTY/TDD برای خدمات پخش ویدیو برای ناشنوایان و ناشنوایان است',
        airlineAgents: 'عوامل هواپیمایی',
        personalCall: 'تماس شخصی',
        phoneNumber: 'شماره تلفن',
        selectAirline: 'عامل هواپیمایی خود را انتخاب کنید',
        airlineCalling: 'تماس هواپیمایی',
        connect: 'اتصال',
    },
    mh: {
        selectLanguage: 'Jouj im kālet kajin eo am',
        backMainMenu: 'Bar jeblak ñan Menu in ikkure',
        helpSubtitle: 'Ewi wawein ad maroñ jibañ yuk rainin?',
        healthWellness: 'Lale ejmour im ejmour',
        denSurvey: 'DEN Etale in jerbal ñan ri wia ro',
        emergencyAccess: 'Jerbal in Deloñ ilo ien ko rebin',
        multilingualCalling: 'Call ilo elōn kajin ko',
        freeCalling: 'Call eo ejjelok wōnāān',
        denLiveVideo: 'DEN Emour Ri-kōmman pija',
        multilingualChatRoom: 'Ruum in bwebwenato ilo elōn kajin ko',
        denMarketplace: 'DEN Jikin wia ilo jikin baļuun',
        qrCodeCreator: 'Jikin wia eo ibelakin lal',
        ttyTtd: 'TTY/TDD ej ñan jerbal in video relay ñan ro rejaroñroñ im ro rejjab maroñ roñjake',
        airlineAgents: 'Ri jerbal ro ilo wa ko',
        personalCall: 'Call eo an juon make',
        phoneNumber: 'Nōmba in Taļboon',
        selectAirline: 'Kālet rijerbal in baļuun eo am',
        airlineCalling: 'Calling in Airline',
        connect: 'Kōppeļļo̧k',
    },
    bemba: {
        selectLanguage: 'Mukwai saleni ululimi lwenu',
        backMainMenu: 'Ukubwelela ku Menyu Iikalamba',
        helpSubtitle: 'Bushe kuti twamwafwa shani ilelo?',
        healthWellness: 'Ubumi busuma no kuceeceeta',
        denSurvey: 'Ukufwailisha kwa DEN ukwa Mibombele ya Bashita',
        emergencyAccess: 'Imilimo yakufikako mukwangufyanya',
        multilingualCalling: 'Ukuita mu ndimi ishingi',
        freeCalling: 'Ukubilisha ukwabula ukulipilisha',
        denLiveVideo: 'Umubomfi wa Vidio iya DEN',
        multilingualChatRoom: 'Icipinda ca Kulanshanyapo ica ndimi ishingi',
        denMarketplace: 'Icifulo ca Makwebo ica DEN',
        qrCodeCreator: 'Icifulo ca Makwebo ica Calo conse',
        TtyTtd: 'TTY/TDD ni mibombele ya kutambisha amavidyo ku bampundu na bafilwa ukumfwa bwino',
        airlineAgents: 'Ababomfi ba ndeke',
        personalCall: 'Ipepo lya pa lwakwe',
        phoneNumber: 'Nambala ya foni',
        selectAirline: 'Salenipo umubomfi wenu uwa ndeke',
        airlineCalling: 'Ukubilisha kwa ndeke',
        connect: 'Kuminkanya',
    },
    wolof: {
        selectLanguage: 'Tannal sa làkk',
        backMainMenu: 'Dellu ci mënu bu mag bi',
        helpSubtitle: 'Noo lay mëna jàppale tay?',
        healthWellness: 'Càmbaru Wérgi-yaram ak Wérgi-yaram',
        denSurvey: 'DEN Serwiisu Kiliyaan',
        emergencyAccess: 'Serwiisu jëfandikoo ci jamonoy jafe-jafe',
        multilingualCalling: 'Woo ci làkk yu bari',
        freeCalling: 'Woo bu amul fayda',
        denLiveVideo: 'Agent Wideo en Live',
        multilingualChatRoom: 'Saalu waxtaan ci làkk yu bari',
        denMarketplace: 'DEN Aéroport marché',
        QrCodeCreator: 'Màrse globaal',
        ttyTtd: 'TTY/TDD ngir joxe wideo ngir tëx yi ak ñiy dégg bu baax',
        airlineAgents: 'Agents de compagnie aérienne',
        personalCall: 'Wootu bopp',
        phoneNumber: 'Nimerotelefoon',
        selectAirline: 'Tànn sa ndawu liggéeyu aérien',
        airlineCalling: 'Wootu kompañi aérien',
        connect: 'Lëkkaloo',
    },
    kek: {
        selectLanguage: "B'aanu usilal, sik' ru laa waatinob'aal",
        backMainMenu: "Sutq'iik sa' li Menú Principal",
        helpSubtitle: "Chan ru naru nakaqatenq'a anajwan?",
        healthWellness: "Xtz'ilb'al rix li kawilal ut li chaab'ilal",
        denSurvey: "Xpatz'b'al li k'anjelak chiruheb' li k'ay re li DEN",
        EmergencyAccess: "K'anjel re ok sa' junpaat",
        multilingualCalling: "B'oqok sa' k'iila aatinob'aal",
        freeCalling: "B'oqok chi maak'a' xtz'aq",
        denLiveVideo: 'Agente re li Video re li DEN',
        multilingualChatRoom: "ChatRoom k'iila aatinob'aal",
        denMarketplace: "K'ayib'aal re li Aeropuerto DEN",
        qrCodeCreator: "K'ayib'aal re li Ruchich'och'",
        ttyTtd: "TTY/TDD a'an cho'q re li k'anjel re li relay re li video cho'q reheb' li tz'ap xik ut li ink'a' neke'ab'ink",
        airlineAgents: 'Agentes re li aerolínea',
        personalCall: "B'oqok re junesal",
        phoneNumber: 'Numero de teléfono',
        selectAirline: "Sik' ru laa k'anjelob'aal re li aerolínea",
        airlineCalling: "B'oqok sa' li Aerolínea",
        connect: "Xk'amb'al rib'",
    },
    breton: {
        selectLanguage: 'Dibabit ho yezh mar plij',
        backMainMenu: "Distreiñ d'al lañser pennañ",
        helpSubtitle: "Penaos e c'hellomp sikour ac'hanoc'h hiziv ?",
        healthWellness: "Gwiriañ ar yec'hed hag ar yec'hed",
        denSurvey: "Enklask servij ar c'hliented DEN",
        emergencyAccess: 'Servijoù mont e-barzh en arvar',
        multilingualCalling: 'Galv liesyezhek',
        freeCalling: 'Pellgomz digoust',
        denLiveVideo: 'DEN Ajañs video bev',
        multilingualChatRoom: 'Sal flapañ liesyezhek',
        denMarketplace: "DEN Marc'had an aerborzh",
        qrCodeCreator: "Marc'had hollek",
        ttyTtd: "TTY/TDD zo evit ar servijoù treuzkas video evit ar re vouzar hag ar re n'o deus ket klevet mat",
        airlineAgents: "Ajañsoù ar c'hompagnunezhioù aerborzhiañ",
        personalCall: 'Galv personel',
        phoneNumber: 'Niverenn bellgomz',
        selectAirline: "Dibabit hoc'h ajañs aerlinennoù",
        airlineCalling: "Pellgomz gant ar c'hompagnunezh aerborzhiañ",
        connect: 'Kennaskañ',
    },
    friulian: {
        selectLanguage: 'Par plasê selezionâ la tô lenghe',
        backMainMenu: 'Tornâ al MenuPrincipâl',
        helpSubtitle: 'Come podìn judâti vuê?',
        healthWellness: 'Control de salût e dal benstâ',
        denSurvey: 'Sondaç sul servizi al client DEN',
        emergencyAccess: 'Servizis di acès di emergjence',
        multilingualCalling: 'Cjamade multilenghe',
        freeCalling: 'Cjamade libare',
        denLiveVideo: 'Agjent video in direte DEN',
        multilingualChatRoom: 'Sala di chat multilingue',
        denMarketplace: 'Marcjât dal Aeropuart DEN',
        qrCodeCreator: 'Marcjât Globâl',
        ttyTtd: 'TTY/TDD al è pai servizis di trasmission video pai sorts e i dificoltâts dal udit',
        airlineAgents: 'Agjents de compagnie aeree',
        personalCall: 'Cjamade personâl',
        phoneNumber: 'NumarTelefon',
        selectAirline: 'Selezionâ il to agjent de compagnie aeree',
        airlineCalling: 'Chiamade compagnie aeree',
        connect: 'Conetî',
    },
    ligurian: {
        selectLanguage: 'Pe favô, seleçionâ a vòstra lengua',
        backMainMenu: 'Torna a-o MenuPrincipâ',
        helpSubtitle: 'Come pòscimo aggiuttâte ancheu?',
        healthWellness: 'Controllo da sanitæ e do benessere',
        denSurvey: 'Sondaggio in sciô serviçio a-i clienti DEN',
        emergencyAccess: "Serviçi d'Accesso d'Emergensa",
        multilingualCalling: 'Chiamata multilingue',
        freeCalling: 'Chiamata gratuita',
        denLiveVideo: 'Agente de video in diretta DEN',
        multilingualChatRoom: 'Sala de chat multilingue',
        denMarketplace: "Mercato de l'Aeropòrto de DEN",
        qrCodeCreator: 'Mercato globale',
        ttyTtd: "TTY/TDD o l'é pe-i serviçi de relay video pe sordi e con di problemi d'udito",
        airlineAgents: 'Agenti de compagnie aeree',
        personalCall: 'Chiamata Personâ',
        phoneNumber: 'NumeroTelefòno',
        selectAirline: 'Seleçionâ o seu agente de compagnia aerea',
        airlineCalling: 'Chiamata de compagnia aerea',
        connect: 'Connette',
    },
    marwadi: {
        selectLanguage: 'कृपया आपरी भाषा चुणो',
        backMainMenu: 'मुख्य मेनू माथै पाछो जावो',
        helpSubtitle: 'आज आपां थारी मदद कियां कर सकां?',
        healthWellness: 'स्वास्थ्य अर कल्याण री जांच',
        denSurvey: 'डीईएन ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपातकालीन पहुंच सेवावां',
        multilingualCalling: 'बहुभाषी कॉलिंग',
        freeCalling: 'फ्री कॉलिंग',
        denLiveVideo: 'डेन लाइव वीडियो एजेंट',
        multilingualChatRoom: 'बहुभाषी चैटरूम',
        denMarketplace: 'डेन एयरपोर्ट मार्केटप्लेस',
        qrCodeCreator: 'वैश्विक बाजार',
        ttyTtd: 'TTY/TDD बधिर अर श्रवण बाधित लोगां रै वास्तै वीडियो रिले सेवावां रै वास्तै है',
        airlineAgents: 'एयरलाइन एजेंट',
        personalCall: 'व्यक्तिगत कॉल',
        phoneNumber: 'फोन नंबर',
        selectAirline: 'आपणी एयरलाइन एजेंट नै चुणो',
        airlineCalling: 'एयरलाइन कॉलिंग',
        connect: 'कनेक्ट करो',
    },
    nus: {
        selectLanguage: 'Kuɛn thokdu',
        backMainMenu: 'Luny jɔk kä Main Menu',
        helpSubtitle: 'De nɛy ji̱ luäk i̱di ɛn walɛ?',
        healthWellness: 'Puɔ̱l Pua̱a̱ny kɛnɛ Puɔlä Pua̱a̱ny Check',
        denSurvey: 'DEN Kathtɔmär Tha̱rbɛy Thɔ̱rbɛy',
        emergencyAccess: 'Emerjɛnci̱Aktheth Thärbi̱thni̱',
        multilingualCalling: 'Cɔal kɛ thuk ti ŋuan',
        freeCalling: 'Cɔal mi̱ lɔr',
        denLiveVideo: 'DEN Laib Bidiö Ɛjɛnt',
        multilingualChatRoom: 'Thok ti̱ ŋuan kä ChatRum',
        denMarketplace: 'DEN Ɛri̱pɔrt Ma̱rkɛtpi̱lɛth',
        qrCodeCreator: 'Glöbal Ma̱rkɛtpi̱lɛth',
        ttyTtd: 'TTY/TDD ɛ lät tin la̱tkɛ kɛ kui̱ nɛɛni̱ tin /thiɛl ji̱th kɛnɛ nɛy tin /thiɛl ji̱th',
        airlineAgents: 'Ɛrlai̱n Ɛjɛni̱',
        personalCall: 'PerthɔnalKɔl',
        phoneNumber: 'Nämbär Puɔɔn',
        selectAirline: 'Kuɛn ram min lät kä ji̱ ria̱th nhiali̱',
        airlineCalling: 'Cɔal ria̱th nhial',
        connect: 'mat',
    },
    zgh: {
        selectLanguage: 'ⴼⵔⴻⵏ ⵜⵓⵜⵍⴰⵢⵜⵉⴽ',
        backMainMenu: 'ⵓⵖⴰⵍ ⵖⴻⵔ ⵓⵎⴻⵏⵏⵓⵖ ⴰⴳⴻⵊⴷⴰⵏ',
        helpSubtitle: 'Amek ⴰⵔⴰ ⴽⵏⵄⵉⵡⴻⵏ ⴰⵙⵙⴰ?',
        healthWellness: 'Askasi ⵏ Tezmert ⴷ Tezmert',
        denSurvey: 'Askasi ⵏ Useqdec ⵏ Yimectariyen ⵏ DEN',
        emergencyAccess: 'Imeẓla ⵏ ⵓⵏⴻⴽⵛⵓⵎ ⵏ ⵜⵖⴰⵡⵙⵉⵡⵉⵏ ⵏ ⵜⵖⴰⵡⵙⵉⵡⵉⵏ',
        multilingualCalling: 'Asiwel ⵙ ⵡⴰⵟⴰⵙ ⵏ ⵜⵓⵜⵍⴰⵢⵉⵏ',
        freeCalling: 'Asiwel ⵉⵍⴻⵍⵍⵉ',
        denLiveVideo: 'Agent ⵏ Vidyu ⵏ DEN',
        multilingualChatRoom: 'Tazeqqa ⵏ ⵜⵎⴻⵙⵍⵉⵡⵜ ⵙ ⵡⴰⵟⴰⵙ ⵏ ⵜⵓⵜⵍⴰⵢⵉⵏ',
        denMarketplace: 'Suq ⵏ ⵓⵏⴰⴼⴰⴳ ⵏ DEN',
        qrCodeCreator: 'Suq ⴰⵎⴰⴹⵍⴰⵏ',
        ttyTtd: 'TTY/TDD ⵉ ⵢⵉⵡⴻⵥⵍⴰ ⵏ ⵓⵙⵉⵡⴻⴹ ⵏ tvidyut ⵉ ⵢⵉⵎⴻⵥⵥⵢⴰⵏⴻⵏ ⴷ ⵢⵉⵎⴻⵥⵥⵢⴰⵏⴻⵏ ⵏ ⵜⵎⴻⵙⵍⵉⵡⵜ',
        airlineAgents: 'Igensasen ⵏ ⵜⴽⴻⴱⴱⴰⵏⵉⵜ ⵏ ⵢⵉⵙⴰⴼⴰⴳⴻⵏ',
        personalCall: 'Asiwel ⵏ ⵓⵎⴷⴰⵏ',
        phoneNumber: 'ⵓⵟⵓⵏ ⵏ ⵜⵉⵍⵉⴼⵓⵏ',
        selectAirline: 'ⴼⵔⴻⵏ ⴰⵎⴻⴳⴳⵉⵉⵏⴻⴽ ⵏ ⵜⴽⴻⴱⴱⴰⵏⵉⵜ ⵏ ⵜⵎⴻⵙⵔⴰⴼⴻⴳⵜ',
        airlineCalling: 'Asiwel ⵏ ⵜⴽⴻⴱⴱⴰⵏⵉⵜ ⵏ ⵢⵉⵙⴰⴼⴰⴳⴻⵏ',
        connect: 'Aqqen',
    },
    santali: {
        selectLanguage: 'Daya kate̱ amak’ pạrsi bachao me',
        backMainMenu: 'Pạhil Menu re',
        helpSubtitle: 'Tihiń cet́ lekabon goṛo daṛeaḱa?',
        healthWellness: 'Nifuṭ ar Nifuṭ Biḍạo',
        denSurvey: 'DEN gạhki seba biḍạo',
        emergencyAccess: 'Acṛak’ seṭerọk’',
        multilingualCalling: 'aema pạrsi te hoho',
        freeCalling: 'Pạhil Hoho',
        denLiveVideo: 'den jivạt viḍio agent',
        multilingualChatRoom: 'aema pạrsi reak’ chat rum',
        denMarketplace: 'DEN uḍạk’ jahaj ḍạr bajar ṭhão',
        qrCodeCreator: 'jhạtiạr bajar',
        ttyTtd: 'TTY/TDD do̠ bạdhir ar ańjo̠m kạmi lạgit́ kạmikanko lạgit́ vidio rile seba lạgit́ kana',
        airlineAgents: "ēẏārala'ina ējēnṭa",
        personalCall: 'nijạt hoho',
        phoneNumber: 'phone nombor',
        selectAirline: 'amak’ uḍạk’ jahaj ejenṭ bachao me̱',
        airlineCalling: "ēẏārala'ina kālanga",
        connect: 'joṛao',
    },
    betawi: {
        selectLanguage: 'Tolong pilih bahasa lu',
        backMainMenu: 'Balik ke Menu Utama',
        helpSubtitle: 'Gimane kite bisa bantuin lu hari ini?',
        healthWellness: 'Pemeriksaan Kesehatan dan Kesejahteraan',
        denSurvey: 'DEN Survei Layanan Pelanggan',
        emergencyAccess: 'ency Access Services',
        multilingualCalling: 'Panggilan Multibahasa',
        freeCalling: 'Panggilan Gratis',
        denLiveVideo: 'DEN Agen Video Langsung',
        multilingualChatRoom: 'Ruang Obrolan Multibahasa',
        denMarketplace: 'DEN Pasar Bandara',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD adalah untuk layanan relay video untuk orang tuli dan cacat pendengaran',
        airlineAgents: 'Agen Maskapai',
        personalCall: 'Panggilan Pribadi',
        phoneNumber: 'Nomor Telepon',
        selectAirline: 'Pilih agen maskapai lu',
        airlineCalling: 'Panggilan Maskapai',
        connect: 'Sambung',
    },
    yakut: {
        selectLanguage: 'Тылгытын талыҥ',
        backMainMenu: 'Сүрүн менюҕа төннүү',
        helpSubtitle: 'Бүгүн эйиэхэ хайдах көмөлөһүөхпүтүн сөбүй?',
        healthWellness: 'Доруобуйаны уонна чэгиэн буолууну бэрэбиэркэлээһин',
        denSurvey: 'DEN клиеннэри өҥөлөрүн ыйытыыта',
        emergencyAccess: 'энсийэҕэ киирии өҥөлөрө',
        multilingualCalling: 'Элбэх тылынан ыҥырыы',
        freeCalling: 'Босхо ыҥырыы',
        denLiveVideo: 'DEN Быһа эфиргэ видео агеннара',
        multilingualChatRoom: 'Элбэх тыллаах чат',
        denMarketplace: 'ДЕН Аэропорт Маркетплейс',
        qrCodeCreator: 'Аан дойдутааҕы ырыынак',
        ttyTtd: 'TTY/TDD диэн дьүлэйдэргэ уонна мөлтөхтүк истэр дьоҥҥо аналлаах видеореле өҥөлөрө',
        airlineAgents: 'Авиакомпания агеннара',
        personalCall: 'Личный Звонок',
        phoneNumber: 'Телефон нүөмэрэ',
        selectAirline: 'Авиахампаанньаҕыт агенын талыҥ',
        airlineCalling: 'Авиахампаанньа ыҥырыыта',
        connect: 'Холбуу',
    },
    dinka: {
        selectLanguage: 'Thoŋdu kuany',
        backMainMenu: 'Dhuk ciëën në kë dït yic',
        helpSubtitle: 'Yeŋö bukku yïn kuɔny akölë?',
        healthWellness: 'Pialguɔ̈p ku Pialguɔ̈p Tïŋ',
        denSurvey: 'Cɛɛth de Luɔɔi de Kɔc Ɣöc de DEN',
        emergencyAccess: 'Kë cë rɔt looi',
        multilingualCalling: 'Cɔl de thook juëc',
        freeCalling: 'Cɔl ë nhom',
        denLiveVideo: 'Aciëŋ de Bidiö de DEN',
        multilingualChatRoom: 'Thön de Jam de thook juëc',
        denMarketplace: 'Tënëɣɔɔc de Riäth nhial de DEN',
        qrCodeCreator: 'Tënë Ɣɔc de Pinynhom',
        ttyTtd: 'TTY/TDD ee kë de bidiöö de luɔɔi de tuɔɔc de luɔɔi tënë kɔc cï piŋ ku cï aa piŋ apath',
        airlineAgents: 'Kɔc lui në riäth nhial',
        personalCall: 'Cɔl de raan',
        phoneNumber: 'Namba de Telepun',
        selectAirline: 'Kuany raan duɔ̈ɔ̈n de riäth nhial',
        airlineCalling: 'Cɔl de riän nhial',
        connect: 'cök',
    },
    lingala: {
        selectLanguage: 'Svp pona monoko na yo',
        backMainMenu: 'Kozonga na Menu ya monene',
        helpSubtitle: 'Ndenge nini tokoki kosalisa yo lelo?',
        healthWellness: 'Vérification ya santé mpe ya bien-être',
        denSurvey: 'Enquête ya service ya ba clients ya DEN',
        emergencyAccess: 'Misala ya bokɔti na mbalakaka',
        multilingualCalling: 'Kobenga na minoko mingi',
        freeCalling: 'Kobenga ya ofele',
        denLiveVideo: 'Agent ya vidéo en direct ya DEN',
        multilingualChatRoom: 'Salle ya kosolola na minoko mingi',
        denMarketplace: 'Zando ya libándá ya mpɛpɔ ya DEN',
        qrCodeCreator: 'Esika ya zando ya mokili mobimba',
        ttyTtd: 'TTY/TDD ezali mpo na ba services ya relais vidéo mpo na ba baba mpe baye bayokaka malamu te',
        airlineAgents: 'Ba agents ya mpepo',
        personalCall: 'Kobenga ya moto ye moko',
        phoneNumber: 'Numéro ya telefone',
        selectAirline: 'Pona agent ya compagnie na yo ya mpepo',
        airlineCalling: 'Kobenga na compagnie ya mpepo',
        connect: 'Kokangisa',
    },
    acehnese: {
        selectLanguage: 'Neupiléh bahsa droeneuh',
        backMainMenu: 'Kembali ke Menu Utama',
        helpSubtitle: 'Pakriban cara kamoe jeuet meubantu droeneuh uroe nyoe?',
        healthWellness: 'Pemeriksaan Kesehatan dan Kesejahteraan',
        denSurvey: 'Survei Layanan Pelanggan DEN',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Panggilan Multibahasa',
        freeCalling: 'Panggilan Bebas',
        denLiveVideo: 'Agen Video Langsong DEN',
        multilingualChatRoom: 'Ruang Chat Multibahasa',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD nakeuh keu jasa relay video keu ureueng tulo ngon ureueng nyang kureung deungoe',
        airlineAgents: 'AgenMaskapaiPenerbangan',
        personalCall: 'Panggilan Pribadi',
        phoneNumber: 'NomorTelepon',
        selectAirline: 'Pileh agen maskapai penerbangan droeneuh',
        airlineCalling: 'Panggilan maskapai penerbangan',
        connect: 'Meusambong',
    },
    hil: {
        selectLanguage: 'Palihog pilia ang imo lenguahe',
        backMainMenu: 'Balik sa Main Menu',
        helpSubtitle: 'Paano kami makabulig sa imo subong nga adlaw?',
        healthWellness: 'Pag-usisa sa Panglawas kag Kaayuhan',
        denSurvey: 'DEN Survey sa Serbisyo sa Kustomer',
        emergencyAccess: 'Mga Serbisyo sa Pag-access sa Emerhensya',
        multilingualCalling: 'Multilingual nga Pagtawag',
        freeCalling: 'Libre nga Pagtawag',
        denLiveVideo: 'DEN Live Video Ahente',
        multilingualChatRoom: 'Multilingual Mag-istoryahanay Kwarto',
        denMarketplace: 'DEN Merkado sa Airport',
        qrCodeCreator: 'Global nga Merkado',
        ttyTtd: 'Ang TTY/TDD para sa mga serbisyo sang video relay para sa mga bungol kag may diperensya sa palamatin-an',
        airlineAgents: 'Mga Ahente sang eroplano',
        personalCall: 'Personal nga Tawag',
        phoneNumber: 'Numero sang Telepono',
        selectAirline: 'Pilia ang imo ahente sang eroplano',
        airlineCalling: 'Airline Pagtawag',
        connect: 'Magkonektar',
    },
    boy: {
        selectLanguage: "अन्नानै नोंथांनि रावखौ सायख'",
        backMainMenu: 'Main menu (मेन मेनु) आव थांफिन।',
        helpSubtitle: 'दिनै जों नोंथांखौ माबोरै हेफाजाब होनो हागोन?',
        healthWellness: 'सावस्रि आरो वेलनेस चेक',
        denSurvey: "डेन कास्ट'मार सारभिस सार्भे",
        emergencyAccess: 'इमारजेन्सि एक्सेस सिबिथायफोर',
        multilingualCalling: 'गोबांरावारि कल खालामनाय।',
        freeCalling: 'बेसेन नाङि कल',
        denLiveVideo: 'DEN लाइभ भिदिअ एजेन्ट।',
        multilingualChatRoom: 'गोबां रावारि चैट रुम',
        denMarketplace: 'डेन बिरखं गाथोननि हाथाइ-बाजार',
        qrCodeCreator: 'मुलुगनां हाथाइजायगा',
        ttyTtd: "टीटीवाई/टीडीडीआ खोमा गैयै आरो खोनासंनो हायै सुबुंफोरनि थाखाय भिडिअ' रिले सिबिथायनि थाखाय।",
        airlineAgents: 'एयारलाइन एजेन्टफोर',
        personalCall: 'गावारि कल',
        phoneNumber: 'जानबुं नम्बर',
        selectAirline: "नोंथांनि एयारलाइन एजेन्टखौ सायख'",
        airlineCalling: 'एयारलाइन कलिं',
        connect: 'फोनांजाब।',
    },
    tpi: {
        selectLanguage: 'Plis makim tok ples bilong yu',
        backMainMenu: 'Go bek long Main Menu',
        helpSubtitle: 'Hau bai mipela i ken helpim yu tude?',
        healthWellness: 'Helt na Welnes Sekim',
        denSurvey: 'DEN Kastoma Sevis Sevei',
        emergencyAccess: 'Imejensi Akses Sevis',
        multilingualCalling: 'Koling long planti tok ples',
        freeCalling: 'Fri Koling',
        denLiveVideo: 'DEN Laip Vidio Ejen',
        multilingualChatRoom: 'Multilingwal Chat Rum',
        denMarketplace: 'DEN Maketples bilong ples balus',
        qrCodeCreator: 'Global Maketples',
        ttyTtd: 'TTY/TDD em bilong ol vidio rilei sevis bilong ol yaupas na ol lain i no inap harim gut',
        airlineAgents: 'Ol ejen bilong balus',
        personalCall: 'Pesonel Kol',
        phoneNumber: 'Telefon Namba',
        selectAirline: 'Makim ejen bilong balus bilong yu',
        airlineCalling: 'Koling bilong balus',
        connect: 'Konektim',
    },
    tsonga: {
        selectLanguage: 'Ndzi kombela u hlawula ririmi ra wena',
        backMainMenu: 'Ku tlhelela eka Menyu leyikulu',
        helpSubtitle: 'Hi nga ku pfuna njhani namuntlha?',
        healthWellness: 'Ku Kamberiwa ka Rihanyo na Vuhlayiseki',
        denSurvey: 'Ndzavisiso wa Vukorhokeri bya Vaxavi wa DEN',
        emergencyAccess: 'Vukorhokeri bya Mfikelelo wa Xihatla',
        multilingualCalling: 'Ku vitana hi tindzimi to tala',
        freeCalling: 'Ku Fonela ka Mahala',
        denLiveVideo: 'Muyimeri wa Vhidiyo leyi hanyaka ya DEN',
        multilingualChatRoom: 'Kamara ro burisana ra tindzimi to tala',
        denMarketplace: 'Ndhawu ya Makete ya Rivala ra Swihahampfhuka ra DEN',
        qrCodeCreator: 'Ndhawu ya Makete ya Misava Hinkwayo',
        ttyTtd: 'TTY/TDD i ya vukorhokeri bya video relay eka lava feke tindleve na lava nga twiki kahle',
        airlineAgents: 'Vayimeri va swihahampfhuka',
        personalCall: 'Ku vitaniwa ka munhu hi xiyexe',
        phoneNumber: 'Nomboro ya riqingho',
        selectAirline: 'Hlawula muyimeri wa wena wa xihahampfhuka',
        airlineCalling: 'Ku Fonela ka Xihahampfhuka',
        connect: 'Ku hlanganisa',
    },
    dzongkha: {
        selectLanguage: 'ཁྱོད་རའི་སྐད་ཡིག་སེལ་འཐུ་འབད་གནང་',
        backMainMenu: 'དཀར་ཆག་གཙོ་བོ་ལུ་ལོག་འགྱོ།',
        helpSubtitle: 'ད་རེས་ང་བཅས་ཀྱིས་ཁྱོད་ལུ་ག་དེ་སྦེ་གྲོགས་རམ་འབད་ནི་སྨོ?',
        healthWellness: 'གསོ་བ་དང་བདེ་ཐང་ཞིབ་དཔྱད།',
        denSurvey: 'DEN ཚོང་མགྲོན་པའི་ཞབས་ཏོག་བརྟག་དཔྱད།',
        emergencyAccess: 'གློ་བུར་འཛུལ་སྤྱོད་ཀྱི་ཞབས་ཏོག',
        multilingualCalling: 'སྣ་མང་སྐད་ཡིག་འབོད་བརྡ་གཏང་ནི།',
        freeCalling: 'རིན་མེད་འབོད་བརྡ།',
        denLiveVideo: 'ཌེན་ལའིབ་བརྙན་འཕྲིན་ལས་ཚབ་',
        multilingualChatRoom: 'སྣ་མང་སྐད་ཡིག་ཁ་སླབ་ཁང་།',
        denMarketplace: 'ཌེན་གནམ་ཐང་གི་ཁྲོམ་ཁར།',
        qrCodeCreator: 'རྒྱལ་སྤྱི་ཚོང་རྭ།',
        ttyTtd: 'ཊི་ཊི་ཝའི་/ཊི་ཌི་ཌི་འདི་ རྣ་བ་མ་གོ་མི་དང་ གོ་ཐོས་སྐྱོན་ཅན་ཚུ་གི་དོན་ལུ་ བརྙན་འཕྲིན་བརྒྱུད་སྤྲོད་ཀྱི་ཞབས་ཏོག་ཚུ་གི་དོན་ལུ་ཨིན།',
        airlineAgents: 'གནམ་གྲུའི་ལས་ཚབ་ཚུ།',
        personalCall: 'སྒེར་དོན་འབོད་བརྡ།',
        phoneNumber: 'ཁ་པར་ཨང་གྲངས།',
        selectAirline: 'ཁྱོད་རའི་མཁའ་འགྲུལ་ལས་ཚབ་སེལ་འཐུ་འབད།',
        airlineCalling: 'གནམ་གྲུའི་འབོད་བརྡ།',
        connect: 'མཐུད།',
    },
    sanskrit: {
        selectLanguage: 'कृपया स्वभाषां चिनोतु',
        backMainMenu: 'मुख्यमेनू प्रति पुनः गच्छन्तु',
        helpSubtitle: 'अद्य वयं भवतः कथं साहाय्यं कर्तुं शक्नुमः?',
        healthWellness: 'स्वास्थ्य-कल्याण-परीक्षा',
        denSurvey: 'DEN ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपातकालीन अभिगम सेवा',
        multilingualCalling: 'बहुभाषिक आह्वान',
        freeCalling: 'निःशुल्कं आह्वानं',
        denLiveVideo: 'DEN लाइव विडियो एजेण्ट्',
        multilingualChatRoom: 'बहुभाषी गपशप कक्ष',
        denMarketplace: 'DEN विमानस्थानक मार्केटप्लेस',
        qrCodeCreator: 'वैश्विक बाजार',
        ttyTtd: 'TTY/TDD बधिराणां श्रवणशक्तिहीनानां च कृते विडियो रिले सेवानां कृते अस्ति',
        airlineAgents: 'विमानसेवा एजेण्ट्',
        personalCall: 'व्यक्तिगत आह्वान',
        phoneNumber: 'फोनसङ्ख्या',
        selectAirline: 'भवतः विमानसेवा एजेण्टं चिनोतु',
        airlineCalling: 'विमानसेवा आह्वानम्',
        connect: 'संयोजनम्',
    },
    madurese: {
        selectLanguage: 'Ngereng pilih bhâsa panèka',
        backMainMenu: 'abali ka menu otama',
        helpSubtitle: "Bâramma carana kaulâh bisa abhânto be'na arèya?",
        healthWellness: 'Parèksa Kasehadan bân Kasejahteraan',
        denSurvey: 'DEN Survei Layanan Pelanggan',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Panggilan Multibahasa',
        freeCalling: 'Telpon Gratis',
        denLiveVideo: 'DEN Agen Video Langsung',
        multilingualChatRoom: 'Ruang Obrolan Multibahasa',
        denMarketplace: 'DEN Pasar Bandara',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD panèka ghâbây layanan relay video ghâbây orèng buwi bân cacat ngèdingaghi',
        airlineAgents: 'Agen Maskapai',
        personalCall: 'Panggilan Pribadi',
        phoneNumber: 'Nomor Telepon',
        selectAirline: 'Pèlè agen maskapai',
        airlineCalling: 'Nelpon Maskapai Penerbangan',
        connect: 'Sambungan',
    },
    kri: {
        selectLanguage: 'Duya pik yu langwej',
        backMainMenu: 'Bak to Men Mɛnyu',
        helpSubtitle: 'Aw wi go ɛp yu tide?',
        healthWellness: 'Hɛlth ɛn Wɛlbɔdi Chɛk',
        denSurvey: 'DEN Kastamɔ Savis Sɔv',
        emergencyAccess: 'Imejɛnsi Akses Savis',
        multilingualCalling: 'Kɔl we gɛt bɔku langwej dɛn',
        freeCalling: 'Fri Kɔlin',
        denLiveVideo: 'DEN Layf Vidio Ejen',
        multilingualChatRoom: 'Mɔltilingual Chat Rum',
        denMarketplace: 'DEN Aypɔt Maketples',
        qrCodeCreator: 'Global Maketples',
        ttyTtd: 'TTY/TDD na fɔ vidio rilay savis fɔ dɛf ɛn pipul dɛn we nɔ de yɛri fayn',
        airlineAgents: 'Aylayn Ejen dɛn',
        personalCall: 'Pɔsin in Kɔl',
        phoneNumber: 'Fon Nɔmba',
        selectAirline: 'Sɛlekt yu aylayn ɛjɛn',
        airlineCalling: 'Aylayn Kɔl',
        connect: 'Kɔnɛkt',
    },
    sango: {
        selectLanguage: 'Pardon, soro yanga ti kodoro ti mo',
        backMainMenu: 'Kiri na MenuPrincipal',
        helpSubtitle: 'Tongana nyen la e lingbi ti mû maboko na mo laso?',
        healthWellness: 'Vérification de la santé et de bien-être',
        denSurvey: 'Enquête ti kusala ti aclient ti DEN',
        emergencyAccess: 'Akusala ti mungo maboko na azo',
        multilingualCalling: 'A iringo azo na ayanga ti kodoro mingi',
        freeCalling: 'A iri azo senge senge',
        denLiveVideo: 'Agent ti vidéo ti DEN',
        multilingualChatRoom: 'Salle ti sarango lisoro na ayanga ti kodoro mingi',
        denMarketplace: 'Marché ti Aéroport ti DEN',
        qrCodeCreator: 'Ndo ti dengo buze ti dunia kue',
        ttyTtd: 'TTY/TDD ayeke teti kusala ti relais ti vidéo teti azo so mê ti ala akanga nga na azo so ayeke mä ndo nzoni pëpe',
        airlineAgents: 'Azo ti kua ti avion',
        personalCall: 'Iri ti zo mveni',
        phoneNumber: 'Numéro ti téléphone',
        selectAirline: 'Soro zo ti kua ti mo ti avion',
        airlineCalling: 'AvionAppel',
        connect: 'Ti tingbi',
    },
    nbl: {
        selectLanguage: 'Sibawa ukhethe ilimi lakho',
        backMainMenu: 'Buyela ku-Main Menu',
        helpSubtitle: 'Singakusiza njani namhlanjesi?',
        healthWellness: 'Hlola ipilo nokuphila kuhle',
        denSurvey: 'DEN Customer Service Survey',
        EmergencyAccess: 'Iinkonzo zokuFinyelela eziphuthumako',
        multilingualCalling: 'Ukubiza amalimi amanengi',
        freeCalling: 'Ukubiza kwamahhala',
        denLiveVideo: 'I-DEN Live Video Agent',
        multilingualChatRoom: 'Igumbi lokucoca ngeenlimi ezinengi',
        denMarketplace: 'DEN Airport Marketplace',
        qrCodeCreator: 'Global Marketplace',
        ttyTtd: 'TTY/TDD yenzelwe imisebenzi yokudlulisa ividiyo ebantwini abangezwako nabangazwa kuhle',
        airlineAgents: 'Ama-ejenti weenqhema zeenqhema',
        personalCall: 'Ikholi yomuntu mathupha',
        phoneNumber: 'Inomboro yefoni',
        selectAirline: 'Khetha i-ejenti yakho yendiza',
        airlineCalling: 'Ukubiza kwendizamtshina',
        connect: 'Xhuma',
    },
    kashmiri: {
        selectLanguage: 'مہربأنی کْرتھ ژأریو پنْنئ زبان',
        backMainMenu: 'بیک ٹو مین مینو',
        helpSubtitle: 'أسۍ کِتھ کٔنۍ ہٮ۪کَو تۄہہِ آز مدد کٔرِتھ؟',
        healthWellness: 'ہیلتھ اینڈ ویلنیس چیک',
        denSurvey: 'DEN کسٹمر سروس سروے',
        emergencyAccess: 'ایمرجنسی ایسیس سروسز',
        multilingualCalling: 'ملٹی لنگوئل کالنگ',
        freeCalling: 'فری کالنگ',
        denLiveVideo: 'DEN لایِو ویڈیو ایجنٹ',
        multilingualChatRoom: 'ملٹی لنگوئل چیٹ روم',
        denMarketplace: 'DEN ایئرپورٹ مارکیٹس',
        qrCodeCreator: 'گلوبل مارکیٹ پلیس',
        ttyTtd: 'TTY/TDD چُھ ویڈیو ریلے سْروسن باپت زْرن باپت تْہ بوزنْہ واجنین باپت',
        airlineAgents: 'ایئر لائن ایجنٹس',
        personalCall: 'ذأتی کال',
        phoneNumber: 'فون نمبر',
        selectAirline: 'پنُن ایئر لائن ایجنٹ ژأریو ',
        airlineCalling: 'ایئر لائن کالنگ',
        connect: 'کنیکٹْہ',
    },
    pag: {
        selectLanguage: 'Ipangasin pilien so lenguahem',
        backMainMenu: 'Pawil ed Manunan Menu',
        helpSubtitle: 'Panon mi kan natulongan natan?',
        healthWellness: 'Tsek ed Salud tan Pankaabigan',
        denSurvey: 'DEN Survey ed Serbisyo ed Kostumer',
        emergencyAccess: 'Serbisyo ed Emergency Access',
        multilingualCalling: 'Panawag ed Dakel a Lenguahe',
        freeCalling: 'Libren Panawag',
        denLiveVideo: 'DEN bilay Video Ahente',
        multilingualChatRoom: 'Dakel so lenguahe miistoryaan Kuarto',
        denMarketplace: 'DEN Tindaan na Airport',
        qrCodeCreator: 'Sankamundoan a Merkado',
        ttyTtd: 'Say TTY/TDD et parad serbisyo na video relay parad saray telek tan walay deperensya ed pakadngel',
        airlineAgents: 'Saray Ahente na eroplano',
        personalCall: 'Personal a Tawag',
        phoneNumber: 'Numero na Telepono',
        selectAirline: 'Pilien so ahente na eroplanom',
        airlineCalling: 'Panawag ed eroplano',
        connect: 'Ikonekta',
    },
    mhr: {
        selectLanguage: 'Пожалуйста, шке йылмым ойырен налза',
        backMainMenu: 'Тӱҥ меню деке пӧртылташ',
        helpSubtitle: 'Кузе ме тыланда таче полшен кертына?',
        healthWellness: 'Тазалыкым да тазалыкым тергымаш',
        denSurvey: 'DEN клиент-влакым обслуживатлыме шотышто шымлымаш',
        emergencyAccess: 'Вашкеполыш службо-влак',
        multilingualCalling: 'Шуко йылме дене йыҥгыр',
        freeCalling: 'Яра йыҥгыр',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Шуко йылме дене тототлымвер',
        denMarketplace: 'ДЕН Аэропорт Маркетплейс',
        qrCodeCreator: 'Тӱнямбал пазарыште',
        ttyTtd: 'TTY/TDD — колыштдымо да начарын колыштшо-влаклан видеореле сервис-влаклан',
        airlineAgents: 'Авиакомпанийын агентше-влак',
        personalCall: 'Личный йыҥгыр',
        phoneNumber: 'Телефон номер',
        selectAirline: 'Авиакомпанийын агентшым ойырен налза',
        airlineCalling: 'Авиакомпаний йыҥгырта',
        connect: 'Ушымаш',
    },
    chuvash: {
        selectLanguage: 'Хӑвӑрӑн чӗлхене суйласа илӗр',
        backMainMenu: 'Тӗп меню патне таврӑн',
        helpSubtitle: 'Паян эпир сире мӗнле пулӑшма пултаратпӑр?',
        сывлӑхСывлӑх: 'Сывлӑхпа сывлӑх тӗрӗслевӗ',
        denSurvey: 'DEN клиентсене пулӑшакан ыйтӑм',
        emergencyAccess: 'Васкавлӑ пулӑшу службисем',
        multilingualCalling: 'Нумай чӗлхеллӗ чӗнӳ',
        freeCalling: 'Ирӗклӗ шӑнкӑрав',
        denLiveVideo: 'DEN Live Video Agent',
        multilingualChatRoom: 'Нумай чӗлхеллӗ чат',
        denMarketplace: 'ДЕН Аэропорт Маркетплейс',
        qrCodeCreator: 'Пӗтӗм тӗнчери рынок',
        ttyTtd: 'TTY/TDD — хӑлхасӑр тата илтмен ҫынсем валли видеореле службисем валли',
        airlineAgents: 'Авиагентсем',
        personalCall: 'Личный Звонок',
        phoneNumber: 'Телефон номерӗ',
        selectAirline: 'Хӑвӑрӑн авиакомпани агентне суйласа илӗр',
        airlineCalling: 'Авиакомпани шӑнкӑравӗ',
        çыхăнтар: 'Çыхăнтар',
    },
    luo: {
        selectLanguage: 'Yier dhok mari',
        backMainMenu: "Dok Menyu Maduong'",
        helpSubtitle: 'Wanyalo konyi nade kawuono?',
        healthWellness: 'Riyo Rieko kod Bedo Maber',
        denSurvey: 'Penjo mar Tich mar Jonyiewo mag DEN',
        emergencyAccess: 'Tije mag Dhopo mapiyo',
        multilingualCalling: "Goyo dhok mang'eny",
        freeCalling: 'Goyo simu ma nono',
        denLiveVideo: "Jatich mar Vidio Maduong' mar DEN",
        multilingualChatRoom: 'Od mar mbaka mar dhok mathoth',
        denMarketplace: 'Soro mar ndege mar DEN',
        qrCodeCreator: 'Chiro mar piny mangima',
        TtyTtd: "TTY/TDD en mar oro vidio ne joma itgi otho kod joma nigi ng'ol e winjo",
        airlineAgents: 'Jotich mag ndege',
        personalCall: "Luong mar ng'ato",
        phoneNumber: 'Namba mar simu',
        selectAirline: 'Yier agent mari mar ndege',
        airlineCalling: 'Goyo simu mar ndege',
        connect: 'tudruok',
    },
    tcy: {
        selectLanguage: 'ದಯಮಲ್ತ್ ನಿಕ್ಲೆನ ಬಾಸೆನ್ ಸೆಲೆಕ್ಟ್ ಮಲ್ಪುಲೆ',
        backMainMenu: 'ಮುಖ್ಯ ಮೆನುಗು ಪಿರ ಬಲೆ',
        helpSubtitle: 'ಇನಿ ಎಂಕುಲು ನಿಕ್ಲೆಗ್ ಎಂಚ ಸಹಾಯ ಮಲ್ಪೊಲಿ?',
        healthWellness: 'ಆರೋಗ್ಯ ಬೊಕ್ಕ ಸುಖ ಪರೀಕ್ಷೆ',
        denSurvey: 'ಡೆನ್ ಗ್ರಾಹಕ ಸೇವೆ ಸಮೀಕ್ಷೆ',
        emergencyAccess: 'ತುರ್ತು ಪ್ರವೇಶ ಸೇವೆಲು',
        multilingualCalling: 'ಮಲ್ಟಿಲಿಂಗುಯಲ್ ಕಾಲಿಂಗ್',
        freeCalling: 'ಫ್ರೀ ಕಾಲಿಂಗ್',
        denLiveVideo: 'ಡೆನ್ ಲೈವ್ ವಿಡಿಯೋ ಏಜೆಂಟ್',
        multilingualChatRoom: 'ಬಹುಭಾಷಾ ಚಾಟ್ ರೂಮ್',
        denMarketplace: 'ಡೆನ್ ವಿಮಾನ ನಿಲ್ದಾಣ ಮಾರುಕಟ್ಟೆ',
        qrCodeCreator: 'ಜಾಗತಿಕ ಮಾರುಕಟ್ಟೆ',
        ttyTtd: 'ಟಿಟಿವೈ/ಟಿಡಿಡಿ ಪಂಡ ಕಿವುಡೆರೆಗ್ ಬೊಕ್ಕ ಕೇನ್ಪಿನಕ್ಲೆಗ್ ವಿಡಿಯೋ ರಿಲೇ ಸೇವೆಲೆಗ್',
        airlineAgents: 'ಏರ್ಲೈನ್ ​​ಏಜೆಂಟ್ಸ್',
        personalCall: 'ಪರ್ಸನಲ್ ಕಾಲ್',
        phoneNumber: 'ಫೋನ್ ನಂಬರ್',
        selectAirline: 'ನಿಕ್ಲೆನ ಏರ್ಲೈನ್ ​​ಏಜೆಂಟ್ ನ್ ಆಯ್ಕೆ ಮಲ್ಪುಲೆ',
        airlineCalling: 'ಏರ್ಲೈನ್ ​​ಕಾಲಿಂಗ್',
        connect: 'ಸಂಪರ್ಕ',
    },
    balinese: {
        selectLanguage: 'Pilih basa Sametoné',
        backMainMenu: 'Mawali ka Menu Utama',
        helpSubtitle: 'Sapunapi carane tiang prasida nulungin ragane ring rahinane mangkin?',
        healthWellness: 'Cek Kesehatan lan Kesejahteraan',
        denSurvey: 'Survei Layanan Pelanggan DEN',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Panggilan multibahasa',
        freeCalling: 'Panggilan gratis',
        denLiveVideo: 'Agen Video Langsung DEN',
        multilingualChatRoom: 'Ruang Obrolan multibahasa',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD puniki kaanggen layanan estafet video majeng ring anak sane pekak lan cacat pendengaran',
        airlineAgents: 'Agen maskapai penerbangan',
        personalCall: 'PanggilanPribadi',
        phoneNumber: 'NomorTelepon',
        selectAirline: 'Pilih agen maskapai penerbangan Sametoné',
        airlineCalling: 'Panggilan maskapai penerbangan',
        connect: 'Nyambunga',
    },
    occitan: {
        selectLanguage: 'Seleccionatz vòstra lenga',
        backMainMenu: 'Retorn al menú principal',
        helpSubtitle: 'Cossí vos podèm ajudar uèi ?',
        healthWellness: 'Control de Salut e Benèsser',
        denSurvey: 'DEN Enquèsta de servici al client',
        emergencyAccess: "Servicis d'accès d'emergéncia",
        multilingualCalling: 'Apelacion multilingüa',
        freeCalling: 'Trucada gratuita',
        denLiveVideo: 'DEN Agent de vidèo en dirècte',
        multilingualChatRoom: 'Sala de chat multilingüe',
        denMarketplace: "DEN Mercat de l'aeropòrt",
        qrCodeCreator: 'Mercat global',
        ttyTtd: 'TTY/TDD es pels servicis de relais vidèo pels sords e amb de deficiéncias auditivas',
        airlineAgents: 'Agents de companhiás aerianas',
        personalCall: 'Apelada personala',
        phoneNumber: 'Numèro de telefòn',
        selectAirline: 'Seleccionatz vòstre agent de companhiá aeriana',
        airlineCalling: 'Trucada de companhiá aeriana',
        connect: 'Connectar',
    },
    ganda: {
        selectLanguage: 'Nsaba olonde olulimi lwo',
        backMainMenu: 'Okudda mu Menyu Enkulu',
        helpSubtitle: 'Tuyinza tutya okukuyamba leero?',
        healthWellness: "Okukebera obulamu n'obulamu obulungi",
        denSurvey: 'Okunoonyereza kwa DEN ku mpeereza ya bakasitoma',
        emergencyAccess: "Empeereza y'okuyingira mu mbeera ez'amangu",
        multilingualCalling: 'Okuyita mu nnimi nnyingi',
        freeCalling: 'Okuyita ku bwereere',
        denLiveVideo: 'DEN Omukungu wa Vidiyo Obutereevu',
        multilingualChatRoom: "Ekisenge ky'okukubaganya ebirowoozo mu nnimi nnyingi",
        denMarketplace: "Akatale k'ekisaawe ky'ennyonyi e DEN",
        qrCodeCreator: "Akatale k'ensi yonna",
        ttyTtd: "TTY/TDD ya mpeereza ya video relay eri bakiggala n'abatawulira bulungi",
        airlineAgents: "Ba agenti b'ennyonyi",
        personalCall: 'Okuyita omuntu ku bubwe',
        phoneNumber: "Ennamba y'essimu",
        selectAirline: "Londa agenti wo ow'ennyonyi",
        airlineCalling: "Essimu y'ennyonyi",
        connect: 'Okuyunga',
    },
    minang: {
        selectLanguage: 'Silakan piliah bahaso Sanak',
        backMainMenu: 'Baliak ka MenuUtamo',
        helpSubtitle: 'Baa caronyo kami bisa mambantu sanak hari ko?',
        healthWellness: 'Pareso Kasehatan jo Kasejahteraan',
        denSurvey: 'Survei Layanan Palanggan DEN',
        emergencyAccess: 'Layanan Akses Darurat',
        multilingualCalling: 'Panggilan Banyak Bahaso',
        freeCalling: 'Panggilan Gratis',
        denLiveVideo: 'Agen Video Langsuang DEN',
        multilingualChatRoom: 'Ruang Obrolan multibahaso',
        denMarketplace: 'Pasar Bandara DEN',
        qrCodeCreator: 'Pasar Dunia',
        ttyTtd: 'TTY/TDD adolah untuak layanan relay video untuak urang pakak jo gangguan pandangaran',
        airlineAgents: 'Agen Maskapai',
        personalCall: 'Panggilan Pribadi',
        phoneNumber: 'NomorTelepon',
        selectAirline: 'Piliah agen maskapai Sanak',
        airlineCalling: 'Maskapai Panggilan',
        connect: 'Mambuang',
    },
    lombard: {
        selectLanguage: 'Per piasé seleziona la tua lengua',
        backMainMenu: 'Torna al menù principal',
        helpSubtitle: 'Cuma podemo vutar-t incœu?',
        healthWellness: 'Control de la salud e del benesser',
        denSurvey: 'Sondagg sul servizi al client DEN',
        emergencyAccess: "Serviz de Access d'Emergenza",
        multilingualCalling: 'Chiamada multilengue',
        freeCalling: 'Chiamada gratuita',
        denLiveVideo: 'Agent Video DEN Live',
        multilingualChatRoom: 'Sala de chat multilingue',
        denMarketplace: "Mercato dell'aeroporto DEN",
        qrCodeCreator: 'Mercad Global',
        ttyTtd: 'TTY/TDD l’è per i servizi de relè video per i sord e i gent cun dificultà d’udito',
        airlineAgents: 'Agenti de la compagnia aerea',
        personalCall: 'Chiamada Persunal',
        phoneNumber: 'NumerTelefon',
        selectAirline: "Seleziona l'agent de la compagnia aerea",
        airlineCalling: 'Chiamada in compagnia aerea',
        connect: 'Culegà',
    },
    maithili: {
        selectLanguage: 'कृपया अपन भाषा चुनू',
        backMainMenu: 'मुख्य मेनू पर वापस जाएँ',
        helpSubtitle: "आइ हम अहाँक कोना मदद क' सकैत छी?",
        healthWellness: 'स्वास्थ्य एवं कल्याण जांच',
        denSurvey: 'DEN ग्राहक सेवा सर्वेक्षण',
        emergencyAccess: 'आपातकालीन पहुँच सेवा',
        multilingualCalling: 'बहुभाषी कॉलिंग',
        freeCalling: 'मुफ्त कॉलिंग',
        denLiveVideo: 'डेन लाइव वीडियो एजेंट',
        multilingualChatRoom: 'बहुभाषी चैट रूम',
        denMarketplace: 'डेन हवाई अड्डा बाजार',
        qrCodeCreator: 'वैश्विक बाजार',
        ttyTtd: 'TTY/TDD बहरा आ श्रवण विकलांगक लेल वीडियो रिले सेवाक लेल अछि',
        airlineAgents: 'एयरलाइन एजेंट',
        personalCall: 'व्यक्तिगत कॉल',
        phoneNumber: 'फोन नंबर',
        selectAirline: 'अपन एयरलाइन एजेंट चुनू',
        airlineCalling: 'एयरलाइन कॉलिंग',
        connect: 'कनेक्ट',
    },
    waray: {
        selectLanguage: 'Alayon pilia an imo yinaknan',
        backMainMenu: 'Balik sa Panguna nga Menu',
        helpSubtitle: 'Paonan-o kami makakabulig ha imo yana nga adlaw?',
        healthWellness: 'Panglawas ug Kaupayan nga Pagsusi',
        denSurvey: 'DEN Customer Serbisyo Survey',
        emergencyAccess: 'Mga Serbisyo sa Pag-agi sa Emerhensya',
        multilingualCalling: 'Damo nga Pinulongan nga Pagtawag',
        freeCalling: 'Libre nga Pagtawag',
        denLiveVideo: 'DEN Buhi nga Ahente han Video',
        multilingualChatRoom: 'Damo nga Pinulongan nga Chat Room',
        denMarketplace: 'DEN Merkado han Paluparan',
        qrCodeCreator: 'Global nga Merkado',
        ttyTtd: 'An TTY/TDD in para han video relay services para han mga bungol ngan may depekto ha pamati',
        airlineAgents: 'Mga Ahente sa Eroplano',
        personalCall: 'Personal nga Tawag',
        phoneNumber: 'Numero han Telepono',
        selectAirline: 'Pilia an imo ahente han eroplano',
        airlineCalling: 'Pagtawag sa eroplano',
        connect: 'Pagsumpay',
    },
    cgg: {
        selectLanguage: 'Nyabura toorana orurimi rwawe',
        backMainMenu: 'Garuka aha Menyu Enkuru',
        helpSubtitle: 'Nitubaasa kukuhwera tuta erizooba?',
        healthWellness: "Okukyebera amagara n'obugwagye",
        denSurvey: 'Okucondooza aha kuheereza abashuubuzi ba DEN',
        emergencyAccess: "Obuheereza bw'okutunga eby'ahonaaho",
        multilingualCalling: 'Okweta omu ndimi nyingi',
        freeCalling: 'okuteera esimu ahabwa busha',
        denLiveVideo: "DEN Vidiyo y'Omujwekyerwa",
        multilingualChatRoom: "Ekishengye ky'okugaaniiraho eky'endimi nyingi",
        denMarketplace: "Akatare k'ekishaayi ky'enyonyi ekya DEN",
        qrCodeCreator: "Akatare k'ensi yoona",
        ttyTtd: "TTY/TDD n'ey'okujanjaaza vidiyo ahabw'abafiire amatu n'abatarikuhurira gye",
        airlineAgents: "Abajwekyerwa b'enyonyi",
        personalCall: "Esimu y'omuntu",
        phoneNumber: "Enamba y'esimu",
        selectAirline: "Toorana omujwekyerwa waawe w'enyonyi",
        airlineCalling: "Okuteera esimu y'enyonyi",
        connect: 'kwaata',
    },
    tigrinya: {
        selectLanguage: 'በጃኻ ቋንቋኻ ምረጽ',
        backMainMenu: 'ናብ ቀንዲ ዝርዝር ምምላስ',
        helpSubtitle: 'ሎሚ ብኸመይ ክንሕግዘካ ንኽእል?',
        healthWellness: 'ምፍታሽ ጥዕናን ጽቡቕ ምህላውን',
        denSurvey: 'ዳህሳስ ኣገልግሎት ዓማዊል DEN',
        emergencyAccess: 'ኣገልግሎት ህጹጽ ምብጻሕ',
        multilingualCalling: 'ብብዙሕ ቋንቋታት ምጽዋዕ',
        freeCalling: 'ናጻ ምድዋል',
        denLiveVideo: 'ወኪል ቀጥታዊ ቪድዮ ደን',
        multilingualChatRoom: 'ብዙሕ ቋንቋታት ዝዛረብ ቻት ሩም',
        denMarketplace: 'ዕዳጋ መዓርፎ ነፈርቲ ደን',
        qrCodeCreator: 'ዓለማዊ ዕዳጋ',
        ttyTtd: 'TTY/TDD ንኣገልግሎት ቪድዮ ሪለይ ንጽሙማንን ናይ ምስማዕ ጸገም ዘለዎምን እዩ',
        airlineAgents: 'ወከልቲ መንገዲ ኣየር',
        personalCall: 'ውልቃዊ ጻውዒት',
        phoneNumber: 'ቁጽሪ ተሌፎን',
        selectAirline: 'ወኪል መንገዲ ኣየርካ ምረጽ',
        airlineCalling: 'ምድዋል መንገዲ ኣየር',
        connect: 'ምትእስሳር',
    },
    tibetan: {
        selectLanguage: 'ཁྱེད་རང་གི་སྐད་ཡིག་འདེམས་རོགས།',
        backMainMenu: 'དཀར་ཆག་གཙོ་བོ་ལ་ལོག་དགོས།',
        helpSubtitle: 'དེ་རིང་ང་ཚོས་ཁྱེད་ལ་རོགས་རམ་གང་འདྲ་བྱེད་ཐུབ་བམ།',
        healthWellness: 'འཕྲོད་བསྟེན་དང་བདེ་ཐང་ཞིབ་བཤེར',
        denSurvey: 'DEN ཚོང་ཤག་ཞབས་ཞུའི་བརྟག་དཔྱད།',
        emergencyAccess: 'ཛ་དྲག་འགྲོ་འོང་ཞབས་ཞུ།',
        multilingualCalling: 'སྐད་རིགས་མང་པོའི་འབོད་སྐུལ།',
        freeCalling: 'རིན་མེད་ཁ་པར་གཏོང་བ།',
        denLiveVideo: 'DEN ཐད་གཏོང་བརྙན་འཕྲིན་ལས་བྱེད།',
        multilingualChatRoom: 'སྐད་རིགས་མང་པོའི་ཁ་བརྡ་ཁང་།',
        denMarketplace: 'ཌེན་གནམ་ཐང་ཁྲོམ་ར།',
        qrCodeCreator: 'རྒྱལ་སྤྱིའི་ཁྲོམ་ར།',
        ttyTtd: 'TTY/TDDནི་རྣ་བ་དང་ཐོས་ཤུགས་ཉམས་པའི་བརྙན་འཕྲིན་བརྒྱུད་གཏོང་ཞབས་ཞུའི་ཆེད་དུ་ཡིན།',
        airlineAgents: 'མཁའ་འགྲུལ་ལས་བྱེད་',
        personalCall: 'སྒེར་གྱི་ཁ་པར་',
        phoneNumber: 'ཁ་པར་ཨང་གྲངས།',
        selectAirline: 'ཁྱེད་རང་གི་མཁའ་འགྲུལ་ལས་ཁུངས་ཀྱི་ལས་ཚབ་འདེམས།',
        airlineCalling: 'མཁའ་འགྲུལ་ཁ་པར་བཏང་བ།',
        connect: 'མཐུད།',
    },
    ckb: {
        selectLanguage: 'تکایە زمانەکەت هەڵبژێرە',
        backMainMenu: 'گەڕانەوە بۆ مێنۆی سەرەکی',
        helpSubtitle: 'ئەمڕۆ چۆن دەتوانین یارمەتیت بدەین؟',
        healthWellness: 'پشکنینی تەندروستی و تەندروستی',
        denSurvey: 'ڕاپرسی خزمەتگوزاری کڕیارانی DEN',
        emergencyAccess: 'خزمەتگوزاری دەستڕاگەیشتن بە فریاگوزاری',
        multilingualCalling: 'پەیوەندیکردن بە فرە زمانە',
        freeCalling: 'پەیوەندیکردن بەخۆڕایی',
        denLiveVideo: 'بریکاری ڤیدیۆی ڕاستەوخۆی DEN',
        multilingualChatRoom: 'ژووری گفتوگۆی فرە زمانی',
        denMarketplace: 'بازاڕی فڕۆکەخانەی DEN',
        qrCodeCreator: 'بازاڕی جیهانی',
        ttyTtd: 'TTY/TDD بۆ خزمەتگوزارییەکانی ڤیدیۆ ڕێلێیە بۆ کەڕ و کەم بیستن',
        airlineAgents: 'بریکارەکانی هێڵی ئاسمانی',
        personalCall: 'پەیوەندی کەسی',
        phoneNumber: 'ژمارەی تەلەفۆن',
        selectAirline: 'بریکاری هێڵی ئاسمانی خۆت هەڵبژێرە',
        airlineCalling: 'پەیوەندیکردنی هێڵی ئاسمانی',
        connect: 'پەیوەندی',
    },
    iba: {
        selectLanguage: 'Sila pilih jaku nuan',
        backMainMenu: 'Pulai ngagai MenuUtama',
        helpSubtitle: 'Baka ni kami ulih nulung nuan saritu?',
        healthWellness: 'Peresa Pengerai enggau Pengelantang',
        denSurvey: 'Survey Servis Pelanggan DEN',
        emergencyAccess: 'Servis Akses Darurat',
        multilingualCalling: 'Panggil Mayuh Bahasa',
        freeCalling: 'Panggil Percuma',
        denLiveVideo: 'Ejen Video Langsung DEN',
        multilingualChatRoom: 'Bilik Randau mayuh jaku',
        denMarketplace: 'Pasar Padang Bilun DEN',
        qrCodeCreator: 'Pasar Global',
        ttyTtd: 'TTY/TDD tu ungkup servis relay video ungkup orang bengal enggau penanggul ninga',
        airlineAgents: 'Ejen penerebai bilun',
        personalCall: 'Panggilan Peribadi',
        phoneNumber: 'LumurTelefon',
        selectAirline: 'Pilih ejen kompeni penerebai bilun nuan',
        airlineCalling: 'Panggil penerebai bilun',
        connect: 'Nyambung',
    },
    tiv: {
        selectLanguage: 'Tsua zwa wou',
        backMainMenu: 'Hide hen kwagh u vesen',
        helpSubtitle: 'Se wase u nyian nena?',
        healthWellness: 'Mlu u ken iyol man mkpeyol u ken iyol',
        denSurvey: 'Mbatomov mba DEN mba eren tom a mbayaav',
        EmergencyAccess: 'Mbatomov mba sha kwagh u mlu u ken hemen',
        multilingualCalling: 'Ijô kpishi',
        freeCalling: 'I yila gbilin',
        denLiveVideo: 'Or u nengen sha vidio u DEN',
        multilingualChatRoom: 'Ijiir i lamen a ior ken ijô kpishi',
        denMarketplace: 'Ijiir i teen kwagh hen agirigi u purugh u DEN',
        qrCodeCreator: 'Ijiir i kasua i sha tar cii',
        ttyTtd: 'TTY/TDD ka sha ci u eren tom u tesen vidio sha ci u mbaakondoato man mba ve lu a ato ga la',
        airlineAgents: 'Mbatomov mba sha ahumbe',
        personalCall: 'I yila or',
        phoneNumber: 'Namba u telefon',
        selectAirline: 'Tsua or u nan lu eren tom a we sha ahumbe la',
        airlineCalling: 'U yilan mbatomov mba ken igirgi',
        connect: 'Kohol',
    },
    venda: {
        selectLanguage: 'Kha vha khethe luambo lwavho',
        backMainMenu: 'U vhuyelela kha Menyu Khulwane',
        helpSubtitle: 'Ri nga ni thusa hani ṋamusi?',
        healthWellness: 'Ṱhoḓisiso ya Mutakalo na Vhuḓifari',
        denSurvey: 'Tsenguluso ya Tshumelo ya Vharengi ya DEN',
        emergencyAccess: 'Tshumelo dza Tswikelelo ya Tshihaḓu',
        multilingualCalling: 'U vhidza nga nyambo nnzhi',
        freeCalling: 'U founa mahala',
        denLiveVideo: 'Muimeleli wa Vidio i Tshilaho ya DEN',
        multilingualChatRoom: 'ChatRoom ya nyambo nnzhi',
        denMarketplace: 'Makete wa Vhuimazwikepe ha DEN',
        qrCodeCreator: 'Makete wa Ḽifhasi',
        ttyTtd: 'TTY/TDD ndi ya tshumelo dza u fhirisela vidio dza dzinganḓevhe na vha sa pfi zwavhuḓi',
        airlineAgents: 'Vhaimeleli vha zwidimela',
        personalCall: 'Luṱingo lwa muthu nga eṱhe',
        phoneNumber: 'Nomboro ya Luṱingo',
        selectAirline: 'Khethani muimeleli waṋu wa tshiendedzi tsha zwidimela',
        airlineCalling: 'U founa ha tshiendedzi',
        connect: 'U ṱanganya',
    },
};
export default messages;
