import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const ButtonPhone = styled(Button)(() => ({
    minWidth: 50,
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: '#E7E7E7',
    color: '#505050',
    fontSize: 18,
    '&:hover': {
        backgroundColor: '#ccc',
        border: 'none',
    },
}));

export default ButtonPhone;
