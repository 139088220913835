import logo from '../logo.svg';
import { Button } from '@mui/material';
function Header() {
    return (
        <header className="App-header">
            <div className="Header-logo">
                <Button href="/">
                    <img src={logo} className="App-logo" alt="logo" />
                </Button>
            </div>
        </header>
    );
}

export default Header;
