import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import AirplaneTicket from '@mui/icons-material/AirplaneTicket';
import CallRounded from '@mui/icons-material/CallRounded';

import KioskButton from '../theme/Button';
import LanguageSelectionDialog from '../components/LanguageSelection';
import Footer from '../components/Footer';
import { useStateValue } from '../store';

import logo from '../logo.svg';
import './MultilingualCalling.css';

function MultilingualCalling() {
    const [openDialog, setOpenDialog] = useState(false);
    const [{ languages }] = useStateValue();

    return (
        <div className="App">
            <header className="App-header">
                <div className="Header-logo">
                    <Button href="/">
                        <img src={logo} className="App-logo" alt="logo" />
                    </Button>
                </div>
            </header>
            <main className="App-main">
                <h1>
                    <FormattedMessage
                        id="helpSubtitle"
                        defaultMessage="How can we help you today?"
                    />
                </h1>

                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#1fb729"
                        size="large"
                        startIcon={<AirplaneTicket />}
                        component={RouterLink}
                        to="/multilingual-airlines"
                    >
                        <FormattedMessage id="airlineAgents" defaultMessage="Airline agents" />
                    </KioskButton>
                </div>
                <div className="Button-wrapper">
                    <KioskButton
                        variant="outlined"
                        iconbgcolor="#1ECBF6"
                        size="large"
                        startIcon={<CallRounded />}
                        href={`http://airportcall.worldwidetechconnections.com?local=${languages.defaultLanguage}&showKeypad=true`}
                    >
                        <FormattedMessage id="personalCall" defaultMessage="Personal call" />
                    </KioskButton>
                </div>
                <Paper
                    elevation={3}
                    sx={{ padding: '1em 3em' }}
                    style={{ maxWidth: 400, margin: '3em auto' }}
                >
                    <Box>
                        <Button
                            variant="outlined"
                            sx={{ width: '100%' }}
                            component={RouterLink}
                            to="/"
                        >
                            <FormattedMessage
                                id="backMainMenu"
                                defaultMessage="Back to Main Menu"
                            />
                        </Button>
                    </Box>
                </Paper>
            </main>
            <Footer />
            <LanguageSelectionDialog open={openDialog} handleOpen={setOpenDialog} />
        </div>
    );
}

export default MultilingualCalling;
